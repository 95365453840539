<template>
    <div v-if="isLoading">
        <LoadingScreen :isLoading="isLoading" />
    </div>
    <div class="info-tab info-tab-point" v-else-if="feature && selected_feature.type == featureType">
        <h3 class="info-title">{{ getFeatureName(feature) }}</h3>

        <div>
            <gcamins-categories-show :categories="feature.categories"></gcamins-categories-show>
        </div>

        <!-- <div v-if="$checkConnection() && feature.images && feature.images.length > 0"
            class="media">
            <img :src="feature.images[0].original_url">
        </div> -->
        
        <div>
            <gcamins-multimedia-info v-if="feature.images" :model_data="feature">
            </gcamins-multimedia-info>
        </div>

        <div class="description" v-if="feature.description" v-html="feature.description[$getLocale()]"></div>

        <profile-fields
            :profile_fields="profile_fields"
            :profile_values="feature.profile_values"
        ></profile-fields>
    </div>
</template>

<script>
import ApiFilesystem from '@/api/filesystem';
import GenericInfo from './GenericInfo.vue';

export default {
    name: 'IncidentInfo',
    extends: GenericInfo,
    watch: {
        feature(val) {
            if(val) {
                this.setFeature();
            }
        },
        selected_feature() {
            if(!this.selected_feature || this.selected_feature.type != this.featureType) {
                this.unsetFeature();
            }
        }
    },
    data() {
        return {
            resource: 'incidents',
            featureType: 'Incident',
            model: 'Modules\\Incidents\\Entities\\Incident',

            marker: null,
            base_url: process.env.VUE_APP_WEBAPP_URL,
            icon_base_url: process.env.BASE_URL,

            shadow: null,
        }
    },
    methods: {
        async setFeature() {
            this.unsetFeature();
            let size = [25, 25];
            let cat = this.feature.categories[0];

            let icon_url = '';
            if(!this.$checkConnection() && !this.$isBrowser()) {
                icon_url = await ApiFilesystem.localCategoryIcon(cat)
            } else {
                icon_url = cat && cat.icon_url ? cat.icon_url : this.icon_base_url+'assets/img/icon-fallback.png';
            }

            const icon = new window.L.Icon({
                iconUrl: icon_url,
                iconSize: size
            });
            const location = new window.L.LatLng(
                this.feature.point.coordinates[1],
                this.feature.point.coordinates[0]
            );
            this.marker = new window.L.Marker(location, {
                    icon: icon,
                });

            if(!this.prevent_focus &&
                (this.leafletObject.getZoom() <= 15
                || !this.leafletObject.getBounds().contains(location))
                ) {
                this.leafletObject.flyTo(location, 15, {animate: false, noMoveStart: true});
            }
            
            if(!this.shadow) {
                var div_circle = window.L.divIcon({
                    className: 'circle-marker-shadow',
                    iconSize: [40, 40],
                    iconAnchor: [20, 20],
                    zIndexOffset: 0,
                    zIndex: 0,
                    });
                this.shadow = window.L.marker(location, {
                    icon: div_circle,
                    zIndexOffset: 0,
                } );
            } else {
                this.shadow.setLatLng(location);
            }

            this.marker.addTo(this.leafletObject);
            
            this.shadow.setZIndexOffset(435);
            this.shadow.addTo(this.leafletObject);

            this.marker.on('click', () => {
                this.$emit('featureListClick', this.selected_feature);
            });
        },
        unsetFeature() {
            if(this.marker) {
                this.marker.remove();
                this.marker = null;
            }
            if(this.shadow) {
                this.shadow.remove();
            }
        }
    },
};
</script>

<style>
.circle-marker-shadow {
    background: #E99B00; /* color of the circle */
    border-radius: 50%; /* make the div a circular shape */
    box-shadow: 4px 4px 3px grey; /* see http://www.w3schools.com/css/css3_shadows.asp */
    -moz-box-shadow: 4px 4px 3px grey;
    -webkit-box-shadow: 4px 4px 3px grey;
    z-index: 0 !important;
    width: 40px;
    height: 40px;
    filter: blur(10px);
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
}
</style>