<template>
    <div v-if="isLoading" class="incident-list-container">
        <LoadingScreen :isLoading="isLoading" />
    </div>
    <div v-else class="incident-list-container">
        <!-- keep alive is important -->
        <keep-alive>
            <div v-if="reported" :key="'incidents-list-0'">
                <h3 class="info-title">
                    {{ $t("map.incidents.thanks.title") }}
                </h3>
                <ion-item class="text-wrap thanks-description">
                    {{ $t("map.incidents.thanks.description") }}
                </ion-item>
                <!-- <ion-item>
                    <ion-button
                        color="secondary"
                        slot="start"
                        @click="_clear">
                        <ion-icon slot="icon-only" :icon="icon_close"></ion-icon>
                    </ion-button>
                    {{ $t('general.back') }}
                </ion-item> -->
                <ion-item button @click="_clear">
                    <ion-icon :icon="icon_close" slot="start"></ion-icon>
                    <ion-label>
                        {{ $t("general.back") }}
                    </ion-label>
                </ion-item>
            </div>
            <div v-else-if="reporting_form" :key="'incidents-list-1'">
                <ion-item>
                    <ion-button @click="_clear" color="danger">
                        <ion-icon slot="start" :icon="icon_close"></ion-icon>
                        {{ $t("map.incidents.add.close") }}
                    </ion-button>
                </ion-item>
                <ion-list>
                    <ion-list-header>{{
                        $t("map.incidents.add.header")
                    }}</ion-list-header>
                    <ion-item>
                        {{ formdata.latlng.lat + ", " + formdata.latlng.lng }}
                        <ion-avatar slot="start">
                            <img
                                :src="
                                    base_url +
                                    'assets/modules/incidents/images/INC-generic.png'
                                "
                            />
                        </ion-avatar>
                    </ion-item>
                    <ion-item>
                        <ion-label position="floating">{{
                            $t("map.incidents.add.category.label")
                        }}</ion-label>
                        <ion-select
                            :clear-input="false"
                            v-model="formdata.category"
                        >
                            <template
                                v-for="category in categories"
                                :key="category.id"
                            >
                                <ion-select-option :value="category.id">{{
                                    getItemName(category.name)
                                }}</ion-select-option>
                            </template>
                        </ion-select>
                    </ion-item>
                    <ion-item>
                        <ion-label position="floating">{{
                            $t("map.incidents.add.description.label")
                        }}</ion-label>
                        <ion-input
                            :clear-input="true"
                            v-model="formdata.description"
                        ></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-label position="floating">{{
                            $t("map.incidents.add.email.label")
                        }}</ion-label>
                        <ion-input
                            :clear-input="true"
                            v-model="formdata.email"
                        ></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-label position="stacked">{{
                            $t("map.incidents.add.files.label")
                        }}</ion-label>
                        <ion-input
                            id="incidents_file_input"
                            type="file"
                            accept="image/*"
                            multiple
                        ></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-button @click="confirmIncident" color="success">
                            <ion-icon
                                slot="start"
                                :icon="icon_check"
                            ></ion-icon>
                            {{ $t("map.incidents.add.submit") }}
                        </ion-button>
                    </ion-item>
                </ion-list>
            </div>
            <div v-else-if="$checkConnection()" :key="'incidents-list-2'">
                <!-- <ion-item>
                    <ion-button
                        color="secondary"
                        slot="start"
                        @click="startIncidentAdd"
                    >
                        <ion-icon
                            slot="icon-only"
                            :icon="icon_warning"
                        ></ion-icon>
                    </ion-button>
                    {{ $t("map.incidents.add.button") }}
                </ion-item> -->
                <ion-item button @click="startIncidentAdd">
                    <ion-icon :icon="icon_warning" slot="start"></ion-icon>
                    <ion-label>
                        {{ $t("map.incidents.add.button") }}
                    </ion-label>
                </ion-item>
            </div>
            <div v-else :key="'incidents-list-3'">
                {{ $t("init.errors.no_offline_option") }}
            </div>
        </keep-alive>
    </div>
</template>

<script>
// Storage
// import ApiStorage from "@/api/storage";

// Client
import ApiClient from "@/api/client";

// import GenericList from './GenericList.vue';
import {
    IonButton,
    IonIcon,
    IonItem,
    IonList,
    IonListHeader,
    IonSelect,
    IonSelectOption,
    IonAvatar,
    IonLabel,
    IonInput,
} from "@ionic/vue";
import {
    warningOutline,
    closeCircleOutline,
    checkmarkOutline,
} from "ionicons/icons";
import { Toast } from "@capacitor/toast";

// Other components
import LoadingScreen from "@/components/LoadingScreen.vue";
import * as $ from "jquery";

export default {
    name: "IncidentsList",
    components: {
        LoadingScreen,
        IonButton,
        IonIcon,
        IonItem,
        IonList,
        IonListHeader,
        IonSelect,
        IonSelectOption,
        IonAvatar,
        IonLabel,
        IonInput,
    },
    props: {
        all_categories: {
            type: [Array],
            custom: true,
            default: () => {
                return [];
            },
        },
        leafletObject: Object,
        current_tab: String,
    },
    data() {
        return {
            base_url: process.env.BASE_URL,
            resource: "incidents",
            featureType: "Incident",
            model: "Modules\\Incidents\\Entities\\Incident",
            isLoading: true,
            icon_warning: warningOutline,
            icon_close: closeCircleOutline,
            icon_check: checkmarkOutline,

            categories: [],
            icon: null,
            marker: null,
            reporting_form: false,
            reported: false,
            formdata: {
                latlng: null,
                description: null,
                email: null,
                category: null,
            },
        };
    },
    watch: {
        current_tab(val) {
            if (val == "sidebar_incidents") {
                this.setPreviousMarkers();
            } else if (val == "") {
                return;
            } else {
                this.removeMarkers();
            }
        },
    },
    mounted() {
        // this.categories = this.all_categories.then(categories => {
        //         return categories.filter(c => {
        //             return c.type == this.model && c.parent_id != 0 && c.parent_id != null;
        //         });
        //     });
        this.categories = this.all_categories.filter((c) => {
            return (
                c.type == this.model && c.parent_id != 0 && c.parent_id != null
            );
        });
        if (!this.icon) {
            this.icon = new window.L.Icon({
                iconUrl:
                    process.env.BASE_URL +
                    "assets/modules/incidents/images/INC-generic.png",
                iconSize: [25, 25],
                iconAnchor: [12, 12],
            });
        }
        this.isLoading = false;
    },
    methods: {
        startIncidentAdd() {
            this._clear();
            this.leafletObject.on("click", this._mapClickIncidentStart);
            if (["xs", "sm"].includes(this.$root.$getWindowSize())) {
                this.$emit("close-sidebar");
            }
            Toast.show({
                text: this.$t("map.incidents.add.indications"),
            });
        },
        _mapClickIncidentStart(event) {
            this.leafletObject.off("click", this._mapClickIncidentStart);
            if (this.marker) {
                this.removeMarker();
            }
            this.setMarker(event.latlng);
            this.$emit("open-sidebar", "sidebar_incidents");
            this.formdata.latlng = event.latlng;
            this.reporting_form = true;
        },
        setMarker(latlng) {
            if (!this.marker) {
                this.marker = new window.L.Marker(latlng, {
                    icon: this.icon,
                });
                this.marker.on("click", this._clear);
            }
            this.marker.addTo(this.leafletObject);
            this.marker.setLatLng(latlng);
        },
        removeMarker() {
            if (this.marker) {
                this.marker.remove();
                this.marker = null;
            }
        },
        _clear() {
            this.reported = false;
            this.formdata = {
                latlng: null,
                description: null,
                email: null,
                category: null,
            };
            this.reporting_form = false;
            this.removeMarker();
        },
        confirmIncident() {
            this.isLoading = true;

            let sendData = new FormData();
            sendData.append("description", this.formdata.description);
            sendData.append("lat", this.formdata.latlng.lat);
            sendData.append("lng", this.formdata.latlng.lng);
            sendData.append("category", this.formdata.category);
            if (this.formdata.email && this.formdata.email.length > 0) {
                sendData.append("email", this.formdata.email);
            }

            let count = 0;
            for (let filedata of $("#incidents_file_input input")[0].files) {
                sendData.append("images[" + count + "]", filedata);
                count++;
            }

            ApiClient.reportIncident(sendData)
                .then((response) => {
                    if (response.status != "success") {
                        Toast.show({
                            text: response.message,
                        });
                    } else {
                        this.finishReporting();
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    Toast.show({
                        text: error,
                    });
                    this.isLoading = false;
                });
        },
        finishReporting() {
            this.reporting_form = false;
            this.reported = true;
        },
        removeMarkers() {
            if (this.marker) {
                this.marker.remove();
            }
        },
        setPreviousMarkers() {
            if (this.marker) {
                this.marker.addTo(this.leafletObject);
            }
        },
        getItemName(names) {
            if (!names[this.$getLocale()]) {
                return names[Object.keys(names)[0]];
            }
            return names[this.$getLocale()];
        },
    },
};
</script>

<style>
.incident-list-container {
    flex: 99;
}
.info-title {
    margin-left: 15px;
}
.thanks-description {
    margin-bottom: 10px;
}
</style>