<template>
    <span>
        <template v-for="layer in vector_layer_list">
            <v-protobuf
                v-if="active[layer.id]"
                :key="layer.id"
                :ref="setRefLayer"
                :url="layer.url"
                :pane="layer.options.pane"
                :options="layer.options"
                :min-zoom="layer.options.minZoom"
                :max-zoom="layer.options.maxZoom"
                :tile-size="layer.options.tileSize"
                ></v-protobuf>
        </template>
    </span>
</template>

<script>
// Storage
import ApiStorage from "@/api/storage";
import ApiFilesystem from "@/api/filesystem";
import * as $ from "jquery";

export default {
    name: 'PointLayer',
    data() {
        return {
            vector_layer_list: [],
            vector_icons: {0: new window.L.Icon({
                iconSize: [25, 25],
                iconUrl: process.env.BASE_URL+'assets/img/icon-fallback.png',
            })},
            features: [],
            profile_field_values: {},
            cluster_style_counts: {},
            layerRefs: [],
            points_layer_id: null,
            cluster_layer_id: null,
            category_icons: {},
            tooltip: null,
            select_tooltip: null,
        }
    },
    props: {
        active: Object,
        categories_list: {
            type: [Object],
            custom: true,
            default: () => {}
        },
        mapObject: {
            type: [Object],
            custom: true,
            default: () => {}
        },
        filter_functions: {
            type: [Array],
            custom: true,
            default: () => []
        },
    },
    async mounted() {
        this.mapObject.on('layeradd', (e) => {
            if(e.layer instanceof window.L.VectorGrid
                && (
                    e.layer.options.id == this.points_layer_id ||
                    e.layer.options.id == this.cluster_layer_id
                    )
                ) {
                e.layer.on('click', this.layerClick);
                e.layer.on('mouseover', this.layerMouseover);
                e.layer.on('mouseout', this.layerMouseout);
            }

            // Toggle between cluster and regular layers
            if(e.layer instanceof window.L.VectorGrid
                && e.layer.options.id == this.points_layer_id
                && this.cluster_layer_id) {
                this.$emit('vectorlayer', this.cluster_layer_id, false);
            }
            if(e.layer instanceof window.L.VectorGrid
                && e.layer.options.id == this.cluster_layer_id
                && this.points_layer_id) {
                this.$emit('vectorlayer', this.points_layer_id, false);
            }
        });
        
        const layers = [
            // await ApiStorage.get('vector_layer_list.points'),
            await ApiStorage.get('vector_layer_list.points_cluster'),
        ];
        this.initClusterIcons();
        
        this.features = await ApiStorage.getAll('points').then(list => {
            return list.filter(e => e!=null).map(element => {
                return {
                    id: element.id,
                    name: element.name,
                    category_id: element.categories[0] ? element.categories[0].id : null,
                }
            });
        });

        await this.getCategoryIcons();
        await this.setVectorLayerList(layers);
        
        await this._loadProfileFieldValues();
    },
    watch: {
        filter_functions: {
            handler: function() {
                this.applyFilters();
            },
        }
    },
    methods: {
        async getCategoryIcons() {
            const size = [25, 25];
            if(this.$checkConnection() || this.$isBrowser()) {
                for(let cat of Object.values(this.categories_list)) {
                    if(!this.category_icons[cat.id]) {
                        this.category_icons[cat.id] = cat.icon_url;

                        this.vector_icons[ cat.id ] = new window.L.Icon({
                            iconUrl: this.category_icons[ cat.id ],
                            iconSize: size
                        });
                    }
                }
            } else {
                for(let cat of Object.values(this.categories_list)) {
                    if(!this.category_icons[cat.id]) {
                        this.category_icons[cat.id] = await ApiFilesystem.localCategoryIcon(cat);

                        this.vector_icons[ cat.id ] = new window.L.Icon({
                            iconUrl: this.category_icons[ cat.id ],
                            iconSize: size
                        });
                    }
                }
            }
        },
        async _loadProfileFieldValues() {
            this.profile_field_values = await ApiStorage.getAll('profile_values.points').then(result => {
                let values = {};
                for(let list of result.filter(e => e!=null)) {
                    values[list.id] = list;
                }
                return values;
            });
        },
        getLayerList() {
            return this.vector_layer_list;
        },
        async setVectorLayerList(list) {
            for(var info of list) {
                this.vector_layer_list.push(await this.makeVectorLayer(info));
            }
            this.$emit('vector_layers_loaded', this.getLayerList());
        },
        layerClick(e) {
            if(e.target.getDataLayerNames().includes('points')) {
                this.$emit('featureClick', {
                    type: 'Point',
                    id: e.layer.properties.id,
                    name: '',
                    info: e.layer.properties,
                });
            } else if(e.target.getDataLayerNames().includes('clusters')) {
                let count = e.layer.properties.count;
                let ids = JSON.parse(e.layer.properties.ids);
                if(e.layer.properties.clst_id in this.cluster_style_counts) {
                    // Retrieve the count made during filtering
                    count = this.cluster_style_counts[e.layer.properties.clst_id].count;
                    ids = this.cluster_style_counts[e.layer.properties.clst_id].ids;
                }
                if(count == 1) {
                    this.$emit('featureClick', {
                        type: 'Point',
                        id: ids[0],
                        name: '',
                        info: e.layer.properties,
                    });
                } else if (this.mapObject.getZoom() > 16) {
                    this.selectTooltip(
                        ids,
                        this.cluster_style_counts[e.layer.properties.clst_id].categories,
                        e);
                } else {
                    let extent = JSON.parse(e.layer.properties.extent);
                    this.mapObject.fitBounds(extent, {
                        maxZoom: 19,
                        padding: [5, 5]
                    });
                }
            }
        },
        layerMouseover(e) {
            if(!e.target.getDataLayerNames().includes('points')
                && !e.target.getDataLayerNames().includes('clusters')) {
                return;
            }
            if(!this.tooltip){
                this.tooltip = new window.L.Tooltip({
                    permanent: false,
                    offset: window.L.point(10,0),
                });
            }

            let ids;
            if(e.layer.properties.clst_id in this.cluster_style_counts) {
                ids = this.cluster_style_counts[e.layer.properties.clst_id].ids;
            } else if (e.layer.properties.ids) {
                ids = JSON.parse(e.layer.properties.ids);
            } else {
                ids = [e.layer.properties.id];
            }

            let content = this.tooltipContents(ids);

            this.tooltip
                .setContent(content)
                .setLatLng(e.latlng)
                .addTo(this.mapObject);
            // this.$emit('layer_mouseover', {
            //     event: e,
            //     type: 'Point',
            //     ids: ids,
            //     properties: e.layer.properties,
            // });
        },
        tooltipContents(ids) {
            let content = "";
            for(let i in ids) {
                let id = ids[i];
                let feature = this.features.find((i) => i.id == id);
                if(!feature) {
                    continue;
                }
                content += this.getFeatureName(feature) + '<br />';
                if(i > 5) {
                    content += "...";
                    break;
                }
            }
            return content;
        },
        selectTooltip(ids, categories, event) {
            if(!this.select_tooltip){
                this.select_tooltip = new window.L.Tooltip({
                    className: 'select-tooltip',
                    permanent: true,
                    offset: window.L.point(10,0),
                    interactive: true,
                });
                this.select_tooltip.on('contentupdate', () => {
                    $('.select-tooltip-item').on('click', e => {
                        // console.log($(e.currentTarget).data('id'));
                        this.$emit('featureClick', {
                            type: 'Point',
                            id: $(e.currentTarget).data('id'),
                            name: '',
                            info: event.layer.properties,
                        });
                    });
                });
                this.select_tooltip.on('tooltipclose', () => {
                    $('.select-tooltip-item').off('click');
                });
            }
            let content = this.selectTooltipContents(ids, categories);
            this.select_tooltip
                .setLatLng(event.latlng)
                .addTo(this.mapObject)
                .setContent(content);
            
            window.L.DomEvent.stopPropagation(event);

            this.$nextTick(() => {
                this.mapObject.once('click', () => {
                    if(this.select_tooltip) {
                        this.select_tooltip.close();
                    }
                });
            })
        },
        selectTooltipContents(ids, categories) {
            let content = "";
            for(let i in ids) {
                let id = ids[i];
                let feature = this.features.find((i) => i.id == id);
                if(!feature) {
                    continue;
                }
                let icon = this.category_icons[ categories[i] || feature.category_id ];
                content += "<div class='select-tooltip-item' data-id='"+id+"'>";
                if(icon) {
                    content += "<img src='"+icon+"' />";
                }
                content += "<span class='select-tooltip-label'>";
                content += this.getFeatureName(feature);
                content += "</span>";
                content += '</div>';
            }
            return content;
        },
        layerMouseout() {
            if(this.tooltip){
                this.tooltip.removeFrom(this.mapObject)
            }
            // This emit gave some recursion problems
            // TODO: Debug
            // this.$emit('layer_mouseout', {
            //     event: e,
            //     type: 'Point',
            // });
        },
        async makeVectorLayer(info){
            const default_options = {
                id: info.id,
                rendererFactory: window.L.canvas.tile,
                // attribution: "Attribution",
                // subdomains: info.subdomains,	// 01234 for openmaptiles, abcd for mapbox
                interactive: true,	// Make sure that this VectorGrid fires mouse/pointer events
                maxZoom: 21,
                indexMaxZoom: 5,       // max zoom in the initial tile index
                zIndex: 500,
                pane: 'vector-overlays',
                name: info.name,
                _type: info.type,
                locale: () => {
                    return this.$getLocale()
                },
                updateWhenZooming: false,
                keepBuffer: 10,
            };

            if(!this.$checkConnection() && !this.$isBrowser()) {
                /**
                 * Offline tiles if saved previouly.
                 * @see @/views/OfflineMode.vue
                 */
                const firstTile = await ApiFilesystem.stat('cartography/'+info.id+'/0/0/0.mvt');
                if(firstTile) {
                    info.url = ApiFilesystem.convertFileSrc(firstTile.uri.replace('0/0/0', '{z}/{x}/{y}'));
                }
                info.options.maxZoom = 15;
            // Force HTTPs on app to avoid CORS problems
            } else if(!this.$isBrowser() && !info.url.startsWith('https://')) {
                info.url = 'https:'+info.url;
            }

            let options = Object.assign(default_options, info.options);
            
            options.getFeatureId = (f) => {
                return f.properties.id;
            };

            if(info.type == 'Point') {
                // Save ID for toggling
                this.points_layer_id = info.id;

                options.vectorTileLayerStyles = {
                    points: this.vector_points_style
                };
                options.filter = (properties) => {
                    const filterable = {
                        categories: [ properties.category_id ],
                        profile_field_values: this.profile_field_values[properties.id] // TODO: Obtain values
                    };
                    for(let filter of this.filter_functions) {
                        if(!filter.run(filterable)) {
                            return false;
                        }
                    }
                    return true;
                }
            } else if (info.type == 'Cluster') {
                // Save ID for toggling
                this.cluster_layer_id = info.id;

                options.vectorTileLayerStyles = {
                    clusters: this.vector_clusters_style
                };
                /**
                 * WARNING: Cluster filter has to work counting.
                 * In regular single points, if a filter is not passed
                 * then false is returned. Meanwhile in clustered points
                 * if a single filter is not passed it will count down.
                 * 
                 * Thus if the count is not zero, the filter will pass.
                 * 
                 * WARNING: The filter will set the style count and set
                 * the last standing categories. This will help in style,
                 * meaning that if only one element is in the cluster the
                 * category will define its icon.
                 */
                options.filter = (properties) => {
                    const ids = JSON.parse(properties.ids);
                    const categories = JSON.parse(properties.categories);
                    let count = properties.count;
                    let last_categories = [];
                    let accepted = [];
                    for(let index in ids) {
                        const id = ids[index];
                        const filterable = {
                            categories: [ categories[ index ] ],
                            profile_field_values: this.profile_field_values[id]
                        };
                        let broke = false;
                        for(let filter of this.filter_functions) {
                            if(!filter.run(filterable)) {
                                count--;
                                broke = true;
                                break;
                            }
                        }
                        if(!broke) {
                            last_categories.push(categories[ index ]);
                            accepted.push(id);
                        }
                    }
                    this.cluster_style_counts[properties.clst_id] = {
                        count,
                        categories:last_categories,
                        ids: accepted
                    };
                    return count > 0;
                }
                options.getFeatureId = (f) => {
                    return f.properties.ids;
                };
            }

            const layer = {
                id: info.id,
                name: info.name,
                active: info.active,
                type: info.type,
                url: info.url,
                options: options
            };

            return layer;
        },
        vector_points_style (properties) {
            let icon;
            if(properties.category_id) {
                // if (!this.vector_icons[ properties.category_id ]) {
                //     let size = [25, 25];
                //     this.vector_icons[ properties.category_id ] = new window.L.Icon({
                //         iconUrl: this.category_icons[ properties.category_id ],
                //         iconSize: size
                //     });
                // }
                icon = this.vector_icons[ properties.category_id ];
            } else {
                icon = this.vector_icons[ 0 ];
            }

            return {icon: icon};
        },
        vector_clusters_style (properties, zoom) {
            let count = properties.count;
            let categories = JSON.parse(properties.categories);
            if(properties.clst_id in this.cluster_style_counts) {
                // Retrieve the count made during filtering
                count = this.cluster_style_counts[properties.clst_id].count;
                categories = this.cluster_style_counts[properties.clst_id].categories;
            }
            if(count == 1) {
                properties.category_id = categories[0];
                return this.vector_points_style(properties, zoom);
            }

            let icon = this.cluster_icons[2];
            if (count == 3) {
                icon = this.cluster_icons[3];
            } else if (count == 4) {
                icon = this.cluster_icons[4];
            } else if (count > 4 && count < 10) {
                icon = this.cluster_icons['5-10'];
            } else if (count >= 10 && count < 20) {
                icon = this.cluster_icons['10-20'];
            } else if (count > 20) {
                icon = this.cluster_icons['20+'];
            }

            return {icon: icon};
        },
        initClusterIcons() {
            this.cluster_icons = {};
            this.cluster_icons[2] = new window.L.Icon({
                iconUrl: process.env.BASE_URL+'assets/modules/points/images/cluster2.png',
                iconSize: [53, 52],
                iconAnchor: [26, 26]
            });
            this.cluster_icons[3] = new window.L.Icon({
                iconUrl: process.env.BASE_URL+'assets/modules/points/images/cluster3.png',
                iconSize: [53, 52],
                iconAnchor: [26, 26]
            });
            this.cluster_icons[4] = new window.L.Icon({
                iconUrl: process.env.BASE_URL+'assets/modules/points/images/cluster4.png',
                iconSize: [53, 52],
                iconAnchor: [26, 26]
            });
            this.cluster_icons['5-10'] = new window.L.Icon({
                iconUrl: process.env.BASE_URL+'assets/modules/points/images/cluster5-10.png',
                iconSize: [53, 52],
                iconAnchor: [26, 26]
            });
            this.cluster_icons['10-20'] = new window.L.Icon({
                iconUrl: process.env.BASE_URL+'assets/modules/points/images/cluster10-20.png',
                iconSize: [53, 52],
                iconAnchor: [26, 26]
            });
            this.cluster_icons['20+'] = new window.L.Icon({
                iconUrl: process.env.BASE_URL+'assets/modules/points/images/cluster20.png',
                iconSize: [53, 52],
                iconAnchor: [26, 26]
            });
        },
        applyFilters() {
            if(!this.layerRefs) {
                // Still not initialized
                return;
            }
            for(let layer of this.layerRefs) {
                layer.leafletObject.redraw();
            }
        },
        setRefLayer(el) {
            if(el) {
                this.layerRefs.push(el);
            }
        },
        getFeatureName(info) {
            if (info.name[this.$getLocale()]) {
                return info.name[this.$getLocale()];
            }
            return info.name[Object.keys(info.name)[0]];
        },
    },
    beforeUpdate() {
        this.layerRefs = [];
    },
}
</script>

<style>
.select-tooltip {
    width: 250px;
}
.select-tooltip .select-tooltip-item {
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
}
.select-tooltip .select-tooltip-item:hover {
    background-color: #DEDEDE;
}
.select-tooltip .select-tooltip-item img {
    margin: 2px 5px;
    display: inline-block;
    vertical-align: middle;
    height: 25px;
    width: 25px;
}
.select-tooltip .select-tooltip-label {
    height: 25px;
    white-space: nowrap;
}
</style>