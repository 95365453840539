<template>
    <div v-if="isLoading">
        <LoadingScreen :isLoading="isLoading" />
    </div>
    <div
        class="info-tab info-tab-polyline"
        v-else-if="feature && selected_feature.type == featureType"
    >
        <h3 class="info-title">{{ getFeatureName(feature) }}</h3>

        <div>
            <gcamins-categories-show
                :categories="feature.categories"
            ></gcamins-categories-show>
        </div>

        <ion-button
            color="secondary"
            class="noprint print-view-hide"
            @click="applyFocus"
            :title="$t('utils_info.focus')"
        >
            <ion-icon slot="icon-only" :icon="icon_map"></ion-icon>
        </ion-button>
        <ion-button
            color="secondary"
            class="noprint print-view-hide"
            @click="applyShare"
            :title="$t('utils_info.share')"
        >
            <ion-icon slot="icon-only" :icon="icon_share"></ion-icon>
        </ion-button>
        <ion-button
            color="secondary"
            class="noprint print-view-hide"
            @click="applyPrint"
            :disabled="!$checkConnection()"
            :title="$t('utils_info.print')"
        >
            <ion-icon slot="icon-only" :icon="icon_print"></ion-icon>
        </ion-button>
        <ion-button
            color="secondary"
            class="noprint print-view-hide"
            @click="applyExport"
            :disabled="!$checkConnection()"
            :title="$t('utils_info.export')"
        >
            <ion-icon slot="icon-only" :icon="icon_export"></ion-icon>
        </ion-button>
        <ion-button
            color="secondary"
            class="noprint print-view-hide"
            @click="applyDirections"
            :title="$t('utils_info.directions')"
        >
            <ion-icon slot="icon-only" :icon="icon_directions"></ion-icon>
        </ion-button>

        <div>
            <gcamins-multimedia-info
                v-if="feature.images"
                :model_data="feature"
            >
            </gcamins-multimedia-info>
        </div>

        <div
            class="description"
            v-if="feature.description"
            v-html="feature.description[$getLocale()]"
        ></div>

        <div v-if="isLoadingElevation" style="text-align: center">
            <ion-spinner style="margin: auto" name="crescent"></ion-spinner>
        </div>
        <keep-alive>
            <elevation
                v-if="!isLoadingElevation && feature.polyline && leafletObject"
                :key="'polyline-info-elevation'"
                :polyline="feature"
                :map="leafletObject"
                :autofitbounds="!focus_prevention"
                :waypoints="waypoints"
                @polylineClick="polylineClick"
            ></elevation>
        </keep-alive>

        <div
            v-if="feature.geo_info && Object.keys(feature.geo_info).length > 0"
        >
            <ion-list>
                <template v-for="(value, key) in feature.geo_info" :key="key">
                    <ion-item>
                        <ion-label position="stacked">
                            {{ $t("map.info." + key + ".label") }}
                        </ion-label>
                        {{ value }}
                    </ion-item>
                </template>
            </ion-list>
        </div>

        <profile-fields
            :profile_fields="profile_fields"
            :profile_values="feature.profile_values"
        ></profile-fields>

        <export-select
            :active="export_active"
            :resource="resource"
            :export_functions="export_functions"
            :resource_params="{ ids: [this.feature.id] }"
            @on-finish="onExportFinish"
        ></export-select>
    </div>
</template>

<script>
// Storage
import ApiStorage from "@/api/storage";

import GenericInfo from "./GenericInfo.vue";
import GCaminsElevation from "@/components/GCaminsElevation.vue";
import ExportSelect from "@/components/ExportSelect.vue";
import { IonList, IonItem, IonLabel, IonSpinner } from "@ionic/vue";

export default {
    name: "PolylineInfo",
    extends: GenericInfo,
    components: {
        IonList,
        IonItem,
        IonLabel,
        IonSpinner,
        elevation: GCaminsElevation,
        "export-select": ExportSelect,
    },
    data() {
        return {
            model: "Modules\\Polylines\\Entities\\Polyline",
            featureType: "Polyline",
            resource: "polylines",
            elevationRef: null,
            waypoints: [],
            waypoints_distance: 0,
            isLoadingElevation: true,
            focus_prevention: false,
        };
    },
    watch: {
        feature() {
            this.focus_prevention = false;
            this.isLoadingElevation = true;
            this.$nextTick(() => {
                this.getWaypoints();
            });
        },
        prevent_focus(val) {
            this.focus_prevention = val;
        },
    },
    methods: {
        applyDirections() {
            const coords =
                this.feature.polyline.coordinates[0][0][1] +
                "," +
                this.feature.polyline.coordinates[0][0][0];
            window.open(
                "https://www.google.com/maps/dir/?api=1&destination=" + coords,
                "_blank"
            );
        },
        async getWaypoints() {
            this.isLoadingElevation = false;
            this.waypoints = [];
            if (!this.waypoints_distance) {
                this.waypoints_distance = await ApiStorage.get(
                    "config.polylines.waypoints_distance"
                );
                if (!this.waypoints_distance) {
                    this.waypoints_distance = 0;
                } else {
                    this.waypoints_distance = parseInt(this.waypoints_distance);
                }
            }
            if (
                !this.$parent ||
                !this.$parent.filter_functions ||
                !this.feature ||
                !this.feature.polyline
            ) {
                this.isLoadingElevation = false;
                return;
            }
            if (!this.$parent.point_list.filtered_items.length) {
                this.isLoadingElevation = false;
                return;
            }

            return new Promise((resolve) => {
                setTimeout(async () => {
                    this.waypoints = await this.filterPoints();
                    // Unmount the elevation chart
                    this.isLoadingElevation = true;
                    // Prevent focusing a second time
                    this.focus_prevention = true;
                    resolve();
                });
            }).then(() => {
                this.$nextTick(() => {
                    // Mount the elevation chart with waypoint data
                    this.isLoadingElevation = false;
                });
            });
        },
        /**
         * Paginated filter of points to avoid UI hangs.
         *
         * @return {Array<Object>}
         */
        async filterPoints() {
            let page = 0;
            let limit = 100;
            let result = [];
            await new Promise((resolve) => {
                const process = async () => {
                    let chunk = this.$parent.point_list.filtered_items.slice(
                        page * limit,
                        (page + 1) * limit
                    );
                    if (chunk.length == 0) {
                        resolve();
                        return;
                    }
                    result = result.concat(
                        chunk
                            .filter((item) => {
                                return this._pointsFilter(item);
                            })
                            .map((item) => {
                                return {
                                    type: "Feature",
                                    geometry: item.point,
                                    properties: {
                                        id: item.id,
                                        name: this.getFeatureName(item),
                                    },
                                };
                            })
                    );
                    page++;
                    setTimeout(() => process(), 0);
                };
                process();
            });
            return result;
        },
        _pointsFilter(item) {
            let d0 = Number.MAX_SAFE_INTEGER;
            for (let subcoords of this.feature.polyline.coordinates) {
                const divider = subcoords.length > 100 ? 20 : 5;
                for (let c = 0; c < subcoords.length; c++) {
                    if (!c || c % divider === 0) {
                        d0 = Math.min(
                            d0,
                            window.L.CRS.Earth.distance(
                                window.L.latLng([
                                    item.point.coordinates[1],
                                    item.point.coordinates[0],
                                ]),
                                window.L.latLng([
                                    subcoords[c][1],
                                    subcoords[c][0],
                                ])
                            )
                        );
                    }
                }
            }
            if (d0 > this.waypoints_distance) {
                return false;
            }
            return true;
        },
        polylineClick() {
            this.$emit('featureListClick', this.selected_feature);
        },
        applyFocus() {
            const coords = this.feature.polyline.coordinates;
            let bounds = new window.L.LatLngBounds(
                window.L.latLng(coords[0][0][1], coords[0][0][0]),
                window.L.latLng(coords[0][0][1], coords[0][0][0]),
            );
            for(let subcoords of coords) {
                for(let c of subcoords) {
                    bounds.extend(window.L.latLng(c[1], c[0]));
                }
            }
            this.$parent.sidebar.close();
            this.leafletObject.fitBounds(bounds);
        },
    },
};
</script>