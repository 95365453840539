<template>
    <div v-if="isLoading" class="generic-list">
        <LoadingScreen :isLoading="isLoading" />
    </div>
    <div v-else class="generic-list">
        <keep-alive>
            <ion-item :key="'geocoding-0'">
                <ion-searchbar
                    animated
                    inputmode="search"
                    :value="search_string"
                    @ion-change="applySearch"
                    :placeholder="$t('map.sidebar.search.placeholder')"
                    ></ion-searchbar>
            </ion-item>
        </keep-alive>
        <!-- keep alive is important -->
        <keep-alive>
            <div class="list-container" v-if="searching">
                <ion-list>
                    <ion-item>
                        <ion-spinner name="crescent"></ion-spinner>
                    </ion-item>
                </ion-list>
            </div>
            <div v-else class="list-container" :key="'geocoding-1'">
                <ion-list v-if="items && items.length > 0">
                    <template v-for="item in items"  v-bind:key="item.id">
                        <ion-item button @click="featureSelected(item)" detail="false">
                            <ion-label>{{ item.display_name }}</ion-label>
                        </ion-item>
                    </template>
                </ion-list>
            </div>
        </keep-alive>
    </div>
</template>

<script>
// Ionic components
import { IonList, IonItem, IonLabel, IonSearchbar, IonSpinner } from '@ionic/vue';

// Other components
import LoadingScreen from '@/components/LoadingScreen.vue'
import axios from 'axios';
import { Toast } from '@capacitor/toast';

export default {
    name: 'GenericList',
    components: {
        IonList,
        IonItem,
        IonLabel,
        IonSearchbar,
        LoadingScreen,
        IonSpinner,
    },
    data() {
        return {
            isLoading: true,
            items: [],
            base_url: process.env.BASE_URL,

            search_string: '',
            searching: false,
        }
    },
    props: {
        leafletObject: Object,
        bounds: Object,
    },
    async mounted() {
        this.isLoading = false;
    },
    methods: {
        featureSelected(item) {
            var corner1 = window.L.latLng(item.boundingbox[0], item.boundingbox[2]),
                corner2 = window.L.latLng(item.boundingbox[1], item.boundingbox[3]),
                bounds = window.L.latLngBounds(corner1, corner2);
            this.leafletObject.flyToBounds(bounds, {
                maxZoom: 19,
                animate: false,
            });
            this.$emit('close-sidebar');
        },
        applySearch($event) {
            let search_string = $event.detail.value;
            if(!search_string || search_string == '') {
                this.cancelSearch();
                return;
            }
            this.searching = true;
            // Using Nominatim:
            // @see https://nominatim.org/release-docs/develop/api/Search/
            // TODO: Use workbounds to set the preferred bbox to search in. See 'viewbox' param
            const search_url = 'https://nominatim.openstreetmap.org/search?q=' + encodeURIComponent(search_string)
                                + '&accept-language=' + this.$root.$i18n.locale.replace('_', '-')
                                + '&limit=5'
                                + '&format=json'
                                + '&viewbox='+this.bounds[0][1]+','+this.bounds[0][0]+','+this.bounds[1][1]+','+this.bounds[1][0]
                                + '&bounded=1'
                                + '&addressdetails=1';
            axios.get(search_url)
                .then(response => {
                    this.items = [];
                    // if(response.data.length == 0) {
                    //     Toast.show({
                    //         text: this.$t('search.no_results'),
                    //     });
                    // }
                    for(let result of response.data) {
                        this.items.push(result);
                    }
                    this.searching = false;
                })
                .catch(error => {
                    Toast.show({
                        text: "Error: " + error,
                    });
                    this.searching = false;
                });
        },
        cancelSearch() {
            this.items = [];
        }
    }
}
</script>