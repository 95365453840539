<template>
  <ion-app>
    <ion-menu side="start" content-id="main-content" class="app-menu">
      <ion-header>
        <ion-toolbar translucent>
          <ion-buttons slot="end">
            <ion-menu-button
              ><ion-icon :icon="icon_close"></ion-icon
            ></ion-menu-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-list-header class="menu-header">
            <ion-label>
              {{ $t("general.menu.sections.info") }}
            </ion-label>
          </ion-list-header>
          <ion-item
            router-link="/pages/home"
            @click="closeMenu"
            :class="{ 'active-link': isActiveLink('/pages/home') }"
          >
            <ion-icon :icon="icon_home" slot="start"></ion-icon>
            <ion-label>{{ $t("home.title") }}</ion-label>
          </ion-item>
          <ion-item
            router-link="/pages/about/what_is_it"
            @click="closeMenu"
            :class="{ 'active-link': isActiveLink('/pages/about/what_is_it') }"
          >
            <ion-icon :icon="icon_information" slot="start"></ion-icon>
            <ion-label>{{ $t("abouts.what_is_it.title") }}</ion-label>
          </ion-item>
          <ion-item
            router-link="/pages/about/what_to_do"
            @click="closeMenu"
            :class="{ 'active-link': isActiveLink('/pages/about/what_to_do') }"
          >
            <ion-icon :icon="icon_list" slot="start"></ion-icon>
            <ion-label>{{ $t("abouts.what_to_do.title") }}</ion-label>
          </ion-item>
          <ion-item
            router-link="/pages/about/who_we_are"
            @click="closeMenu"
            :class="{ 'active-link': isActiveLink('/pages/about/who_we_are') }"
          >
            <ion-icon :icon="icon_about" slot="start"></ion-icon>
            <ion-label>{{ $t("abouts.who_we_are.title") }}</ion-label>
          </ion-item>
          <ion-list-header class="menu-header">
            <ion-label>
              {{ $t("general.menu.sections.navigate") }}
            </ion-label>
          </ion-list-header>
          <ion-item
            router-link="/pages/map#sidebar_polylines"
            @click="closeMenu"
            :class="{
              'active-link': isActiveLink('/pages/map#sidebar_polylines'),
            }"
          >
            <ion-icon :icon="icon_route" slot="start"></ion-icon>
            <ion-label>{{ $t("general.menu.options.routes") }}</ion-label>
          </ion-item>
          <ion-item
            router-link="/pages/map#sidebar_lattice"
            @click="closeMenu"
            :class="{
              'active-link': isActiveLink('/pages/map#sidebar_lattice'),
            }"
          >
            <ion-icon :icon="icon_add" slot="start"></ion-icon>
            <ion-label>{{ $t("general.menu.options.lattice") }}</ion-label>
          </ion-item>
          <ion-item
            router-link="/pages/map"
            @click="closeMenu"
            :class="{ 'active-link': isActiveLink('/pages/map') }"
          >
            <ion-icon :icon="icon_map" slot="start"></ion-icon>
            <ion-label>{{ $t("general.menu.options.map") }}</ion-label>
          </ion-item>
          <ion-list-header class="menu-header" v-if="!$isBrowser()">
            <ion-label>
              {{ $t("general.menu.sections.offline_mode") }}
            </ion-label>
          </ion-list-header>
          <ion-item
            v-if="!$isBrowser()"
            router-link="/pages/offline_mode"
            @click="closeMenu"
            :class="{ 'active-link': isActiveLink('/pages/offline_mode') }"
          >
            <ion-icon :icon="icon_offline" slot="start"></ion-icon>
            <ion-label>{{ $t("general.menu.options.offline_mode") }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content" />
  </ion-app>
</template>

<script>
import { defineComponent } from 'vue';
import { IonApp, IonHeader, IonToolbar, IonRouterOutlet, IonIcon, IonLabel, IonItem, IonList, IonListHeader, IonContent, IonMenu, IonMenuButton, IonButtons, useIonRouter, menuController } from '@ionic/vue';
import { home, informationCircleOutline, mapSharp, closeSharp, listSharp, colorFilterOutline, trailSignOutline, addCircleOutline, cloudOffline } from 'ionicons/icons';
import ApiClient from "@/api/client";
import { useRoute } from 'vue-router';
import { App } from '@capacitor/app';
import { alertController } from '@ionic/vue';

export default defineComponent({
    name: 'Gcamins-app',
    components: {
        IonApp,
        IonHeader,
        IonToolbar,
        IonRouterOutlet,
        IonIcon,
        IonLabel,
        IonItem,
        IonList,
        IonListHeader,
        IonContent,
        IonMenu,
        IonMenuButton,
        IonButtons,
    },
    data() {
        return {
            icon_home: home,
            icon_information: informationCircleOutline,
            icon_close: closeSharp,
            icon_list: listSharp,
            icon_about: colorFilterOutline,
            icon_route: trailSignOutline,
            icon_add: addCircleOutline,
            icon_map: mapSharp,
            icon_offline: cloudOffline
        };
    },
    async mounted() {
        this.$setState({
            initialized: false,
            isLoading: true,
            isLoadingPartial: true,
            loading_percent: 0,
            buffer_percent: 0,
            loading_error: null,
        });
        this._client = ApiClient;

        this.initialize();

        // Set router
        const router = useIonRouter();

        // Set back button behaviour
        document.addEventListener('ionBackButton', (ev) => {
            ev.detail.register(-1, () => {
                if (!router.canGoBack()) {
                    App.exitApp();
                }
            });
        });

        this.$setState({
            initialized: true,
        });
    },
    methods: {
        async initialize() {
            const cached = await this._client.init(this.$isBrowser());

            // Check last sync for offline data fallback
            let lastSync = cached && '_keys' in cached ? await this._client.lastSync() : null;
            // let updatedRecently = !lastSync ? false : moment().diff(moment(lastSync), 'hours') < /*24*/2;

            // Set updating status
            this.$setState({
                updating: null,
            });

            // GA
            //this.ga.startTrackerWithId('G-586MHQ2S7M')
            //    .then(() => {
            //        console.log('Google analytics is ready now');
            //    })
            //    .catch(e => console.log('Error starting GoogleAnalytics', e));

            // Start the app
            // Check if there's connection.
            // We could check if data was syncronized more
            // than 24h ago.
            const loginSuccess = await this._client.executeLogin();
            if(this.$checkConnection()
                && !lastSync
                && loginSuccess
            ) {
                this.$setState({
                    updating: false,
                });
                this.loadOnlineData(loginSuccess, lastSync);
            } else {
                if(!lastSync) {
                    this.fail(this.$t("init.errors.no_offline_data"));
                } else {
                    this.$setState({
                        loading_percent: 5,
                        buffer_percent: 5,
                    });
                    if(this.$checkConnection()) {
                        await this._client.fetchConfig();
                        await this.setI18n();
                    }
                    await this.configLocale();

                    // Some pages should not ask for reload: print and embed.
                    let reload = false;
                    if(!['/print', '/embed'].includes(this.$route.path)) {
                        reload = await this.askForUpdate();
                    }

                    if(reload) {
                        // Set updating status
                        this.$setState({
                            updating: true,
                        });
                        this.loadOnlineData(loginSuccess, lastSync);
                    } else {
                        await this.configLocale();
                        this.$setState({
                            isLoading: true,
                            isLoadingPartial: true,
                        });
                        this.loadOfflineData().then(async () => {
                            this.$setState({
                                isLoading: false,
                                isLoadingPartial: false,
                            });
                            this.postLoadExecutions();
                            return true;
                        });
                    }
                }
            }
        },
        async askForUpdate() {
            const alert = await alertController
                .create({
                    header: this.$t('init.askupdate.header'),
                    subHeader: this.$t('init.askupdate.subtitle'),
                    message: this.$t('init.askupdate.description'),
                    buttons: [
                        {
                            text: this.$t('init.askupdate.button_ok'),
                            role: 'confirm',
                            id: 'confirm-button',
                        },
                        {
                            text: this.$t('init.askupdate.button_cancel'),
                            cssClass: 'danger',
                            role: 'cancel',
                            id: 'cancel-button',
                        },
                    ],
                });
            await alert.present();

            const { role } = await alert.onDidDismiss();

            return role != 'cancel' && role != 'backdrop';
        },
        async loadOnlineData(loginSuccess, lastSync) {
            this.fetchData(loginSuccess).then(async () => {
                this.$setState({
                    isLoadingPartial: false,
                    buffer_percent: 100,
                });
                this.postLoadExecutions();
                return true;
                // return this.saveData();
            }, (error) => {
                this.failWithFallback(error, lastSync);
            })
            .then(() => {
                return this.saveData();
                // this.$setState({
                    // isLoadingPartial: false,
                // });
            })
            .then(() => {
                this.$setState({
                    isLoading: false,
                    loading_percent: 100,
                });
                return this.finishSync();
            })
            .catch(error => {
                this.failWithFallback(error, lastSync);
            });
        },
        async loadOfflineData() {
            this.$setState({
                isLoading: true,
                isLoadingPartial: true,
                loading_percent: 20,
                buffer_percent: 20,
            });
            await this._client.getLastUpdatedResource('polylines');
            await this._client.getLastUpdatedResource('profile_values.polylines');
            this.$setState({
                loading_percent: 60,
                buffer_percent: 60,
            });
            // await this._client.getLastUpdatedResource('points');
            // No await, just get all in background
            this._client.getLastUpdatedResource('points');
            this._client.getLastUpdatedResource('profile_values.points');
            this.$setState({
                loading_percent: 100,
                buffer_percent: 100,
            });
            // await this._client.getLastUpdatedResource('points');
            // this.$setState({
            //     loading_percent: 60,
            //     buffer_percent: 60,
            // });
        },
        async fetchData(loginSuccess) {
            await this.configLocale();
            if(!loginSuccess) {
                throw new Error(this.$t("init.errors.server_unavailable"));
            }
            var result = Promise.resolve();
            const processes = [
                () => { return this._client.fetchConfig().then(this.setI18n) },
                () => { return this._client.fetchLanguages() },
                () => { return this._client.fetchResource('categories') },
                () => { return this._client.fetchResource('profile_fields') },
                () => { return this._client.fetchResource('tilelayers') },
                () => { return this._client.fetchResource('points') },
                () => { return this._client.fetchResource('polylines') },
                () => { return this._client.fetchResource('lattice_groups') },
                () => { return this._client.fetchResource('incidents') },
                // this._client.fetchResource('arclines'),
                () => { return this._client.fetchVectorLayerList() },
                () => { return this._client.fetchProfileValues({
                    id_source: 'points',
                    model: 'Modules\\Points\\Entities\\Point',
                }) },
                () => { return this._client.fetchProfileValues({
                    id_source: 'polylines',
                    model: 'Modules\\Polylines\\Entities\\Polyline',
                }) },
                () => { return this._client.fetchProfileValues({
                    id_source: 'incidents',
                    model: 'Modules\\Incidents\\Entities\\Incident',
                }) },
                // this._client.fetchProfileValues({
                //     id_source: 'arclines',
                //     model: 'Modules\\Lattice\\Entities\\Arcline',
                // }),
            ];
            let count = 0;
            let realCount = 0;
            this._client.setProgressListener((subtotal, subcount) => {
                this.onProgress(count, realCount, processes.length, subtotal, subcount);
            });
            this._client.setFinishProgressListener(() => {
                realCount++;
                this.onProgress(count, realCount, processes.length);
            });
            for(let process of processes) {
                await process();
                count++;
                this.onProgress(count, realCount, processes.length);
            }
            return result;
        },
        saveData() {
            return this._client.processUnsaved();
        },
        onProgress(count, realCount, total, subtotal, subcount) {
            if(total < 1) {
                this.$setState({
                    loading_percent: 100,
                    buffer_percent: 100,
                });
            } else if(subtotal && subtotal > 0) {
                const subpercent = Math.floor(subcount / subtotal * 100);
                const subpercentBuffer = Math.floor(subcount / subtotal * 100);

                const step = Math.ceil(1 / total * 100);
                const stepPercent = step * subpercent / 100;
                const stepPercentBuffer = step * subpercentBuffer / 100;

                const loading_percent = (realCount / total * 100) + stepPercent;
                const buffer_percent = (count / total * 100) + stepPercentBuffer;

                this.$setState({
                    loading_percent: Math.floor(loading_percent),
                    buffer_percent: Math.floor(buffer_percent)
                });
            } else {
                const loading_percent = Math.ceil(realCount / total * 100);
                const buffer_percent = Math.ceil(count / total * 100);
                this.$setState({
                    loading_percent,
                    buffer_percent
                });
            }
        },
        fail(error) {
            this.$setState({
                loading_error: error,
            });
        },
        failWithFallback(error, withFallback) {
            if(withFallback) {
                console.warn(error);
                console.warn("Falling back to offline data");
                this.$setState({
                    isLoading: false,
                    isLoadingPartial: false,
                });
            } else {
                this.fail(error);
            }
        },
        async finishSync() {
            await this._client.markSync();
            this.$setState({
                isLoading: false,
            });
        },
        closeMenu() {
            menuController.close();
        },
        isActiveLink(path) {
            const route = useRoute();
            return path === route.fullPath;
        },
        async configLocale() {
            let locale = await this._client.getLastLocale();
            if(!locale) {
                locale = this.$getLocale();
            }
            this._client.locale = locale;
            this.$root.$i18n.locale = locale;
            this.setI18n();
        },
        postLoadExecutions() {
            this.$nextTick(() => {
                // Process locations if any
                this._client.uploadUserLocations();
            })
        },
        setI18n() {
            return this._client.getI18n().then(i18n => {
                if(!i18n || i18n.length == 0) {
                    return false;
                }
                for(let code in i18n) {
                    this.$root.$i18n.setLocaleMessage(code, i18n[code]);
                }
                return true;
            });
        }
    }
});
</script>

<style>
.app-menu .item {
  cursor: pointer;
}
.alert-button.danger {
  color: red;
}
</style>