<template>
    <div ref="container" class="inruting_elevation" :id="elevation_uid"></div>
</template>

<script>
import { uniqueId } from "lodash";

import "@raruto/leaflet-elevation";
import "@raruto/leaflet-elevation/dist/leaflet-elevation.css";
window.L.Control.Elevation.include({
    /**
     * Fix a bug in L.Control.Elevation when unloading, original code
     * is flawed for now. Overriding this method is the best solution.
     */
    _clearChart: function () {
        if (this._events && this._events.elechart_updated) {
            this._events.elechart_updated.forEach((e) =>
                this.off("elechart_updated", e.fn, e.ctx)
            );
        }
        if (this._chart && this._chart._container) {
            this._chart._container.selectAll("g.point .point").remove();
        }
    },
});

export default {
    name: "GCaminsElevation",
    data() {
        return {
            elevation_uid: uniqueId("elevation_"),
            elevation_control: null,
            chart_data: {},
            selection_overlay: null,
            loading: false,
            deactivated: false,
            shadow_polyline: null,
            map_polyline: null,
            options: {
                theme: "inruting-theme",
                collapsed: false,
                detached: true,
                elevationDiv: ".inruting_elevation",
                summary: false,
                legend: false,
                // reverseCoords: true,
                marker: "position-marker",
                followMarker: false,
                autofitBounds: true,
                polyline: {
                    // className: '',
                    // color: '#000',
                    // opacity: 0.75,
                    // weight: 5,
                    color: "transparent",
                    opacity: 0,
                    weight: 0,
                    lineCap: "round",
                },
                waypoints: true,
                // Custom waypoint icons.
                // Actually hidden
                wptIcons: {
                    "": window.L.divIcon({
                        className: "",
                        html: "<span></span>",
                        iconSize: [0, 0],
                        iconAnchor: [0, 0],
                    }),
                },
                // polylineSegments: {
                //     color: 'black',
                // },
                // slope: "summary",
                // speed: "summary",
                // time: "summary",
            },
        };
    },
    props: {
        polyline: {
            type: [Object, Array],
            custom: true,
            default: () => {},
        },
        waypoints: {
            type: [Object, Array],
            custom: true,
            default: () => {},
        },
        map: {
            type: [Object],
            custom: true,
            default: () => null,
        },
        color: {
            type: [String],
            custom: true,
            default: () => "#3388FF",
        },
        autofitbounds: {
            type: [Boolean],
            custom: true,
            default: () => true,
        },
        use_shadow: {
            type: [Boolean],
            custom: true,
            default: () => true,
        },
    },
    watch: {
        polyline(val) {
            this.setChartData(val);
        },
        waypoints() {
            this.setChartData(this.polyline);
        },
        autofitbounds(val) {
            this.options.autofitBounds = val;
            if (this.elevation_control) {
                this.elevation_control.options.autofitBounds = val;
            }
        },
    },
    mounted() {
        // Set unique ID to allow multiple elevation components
        // in the same page.
        this.options.elevationDiv = "#" + this.elevation_uid;

        // this.options.polyline.color   = this.polyline.style_color;
        // this.options.polyline.opacity = this.polyline.style_opacity;
        // this.options.polyline.weight  = this.polyline.style_width;
        this.options.autofitBounds = this.autofitbounds;
        this.elevation_control = window.L.control.elevation(this.options);

        this.$nextTick(() => {
            this._onActivate();
        });
    },
    beforeUnmount() {
        this._onDeactivate(true);
    },
    /**
     * In case keep-alive is being used
     */
    deactivated() {
        this.deactivated = true;
        this._onDeactivate();
    },
    activated() {
        this._onActivate();
        this.deactivated = false;
    },
    methods: {
        setChartData(polyline) {
            let points = [];
            if (polyline.polyline.type == "MultiLineString") {
                for (let p of polyline.polyline.coordinates) {
                    points = points.concat(p);
                }
            } else {
                points = polyline.polyline.coordinates;
            }
            this.chart_data = {
                name: polyline.name,
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        geometry: {
                            type: "LineString",
                            coordinates: points,
                        },
                    },
                ],
            };
            if (this.waypoints && Object.keys(this.waypoints).length > 0) {
                this.chart_data.features = this.chart_data.features.concat(
                    this.waypoints
                );
            }
            this.$nextTick(() => {
                // Shadow first sets really the needed z-index
                this.setShadowPolyline(this.polyline);
                this.setMapPolyline(polyline);
            });
        },
        _onDeactivate(destroy) {
            if (this.elevation_control) {
                try {
                this.elevation_control.clear();
                } catch(e) {
                    // ignore exception
                }
                this.elevation_control.remove();
                if (destroy) {
                    this.elevation_control = null;
                }
            }
            this.$nextTick(() => {
                this.unsetShadowPolyline();
                this.unsetMapPolyline();
            });
            // this.$refs.container.empty();
        },
        _onActivate() {
            // If the map is zooming, which ususally does when
            // just initialized, better to wait a bit or the pane
            // renderer will have a conflict.
            // It doesn't happen on the first load though...
            if (this.loading) {
                return;
            }
            this.loading = true;
            // setTimeout(() => {
            this.$nextTick(() => {
                if (this.deactivated || !this.elevation_control) {
                    // it could be deactivated while loading, abort.
                    this.loading = false;
                    return;
                }
                this.elevation_control.addTo(this.map);

                this.$nextTick(() => {
                    if (this.deactivated) {
                        this.loading = false;
                        return;
                    }
                    this.setChartData(this.polyline);
                    this.elevation_control.on('eledata_loaded', () => {
                        // Not in print path and only if chart is loaded
                        if(this.elevation_control._chart && !(['/print'].includes(this.$route.path))) {
                            // Find if the mouse is near a point in the chart in order to make it visible.
                            this.elevation_control._chart.on('mouse_move', (event) => {
                                for(let dot of this.elevation_control._chart._point._groups[0][0].childNodes) {
                                    if(Math.abs(dot.__data__.x - event.xCoord) < 10) {
                                        dot.childNodes[2].style.visibility = 'visible';
                                    } else {
                                        dot.childNodes[2].style.visibility = 'hidden';
                                    }
                                }
                            });
                        }

                        this.loading = false;
                    });
                    this.elevation_control.load(this.chart_data);
                });
            });
            // }, 200);
        },
        setMapPolyline(polyline) {
            if (!this.map_polyline) {
                // let subcoords = [];
                // for(let p of polyline.polyline.coordinates) {
                //     subcoords.push(p.map((c) => {
                //         return window.L.latLng(c[1], c[0]);
                //     }));
                // }
                let subcoords = [];
                if (polyline.polyline.type == "MultiLineString") {
                    for (let p of polyline.polyline.coordinates) {
                        subcoords.push(
                            p.map((c) => {
                                return window.L.latLng(c[1], c[0]);
                            })
                        );
                    }
                } else {
                    subcoords.push(
                        polyline.polyline.coordinates.map((c) => {
                            return window.L.latLng(c[1], c[0]);
                        })
                    );
                }
                this.map_polyline = [];
                // const renderer = window.L.canvas({ tolerance: 15 });
                for (let subset of subcoords) {
                    this.map_polyline.push(
                        window.L.polyline(subset, {
                            color: this.polyline.style_color,
                            weight: this.polyline.style_width,
                            opacity: 1,
                            zIndexOffset: 100,
                            zIndex: 100,
                            // renderer: renderer,
                        })
                    );
                }
            }
            for (let p of this.map_polyline) {
                p.addTo(this.map);
                p.on('click', e => {
                    this.$emit('polylineClick', e);
                })
            }
        },
        unsetMapPolyline() {
            if (!this.map_polyline) {
                return;
            }
            for (let p of this.map_polyline) {
                p.remove();
            }
        },
        setShadowPolyline(polyline) {
            if (!this.use_shadow) {
                return;
            }
            if (!this.shadow_polyline) {
                let subcoords = [];
                // for(let p of polyline.polyline.coordinates) {
                //     subcoords.push(p.map((c) => {
                //         return window.L.latLng(c[1], c[0]);
                //     }));
                // }
                if (polyline.polyline.type == "MultiLineString") {
                    for (let p of polyline.polyline.coordinates) {
                        subcoords.push(
                            p.map((c) => {
                                return window.L.latLng(c[1], c[0]);
                            })
                        );
                    }
                } else {
                    subcoords.push(
                        polyline.polyline.coordinates.map((c) => {
                            return window.L.latLng(c[1], c[0]);
                        })
                    );
                }
                this.shadow_polyline = [];
                // const renderer = window.L.canvas();
                for (let subset of subcoords) {
                    this.shadow_polyline.push(
                        window.L.polyline(subset, {
                            className: 'shadow_polyline',
                            color: "#E99B00",
                            weight: parseInt(this.polyline.style_width) + 8,
                            opacity: 0.5,
                            smoothFactor: 1,
                            zIndexOffset: 0,
                            zIndex: 0,
                            // pane: 'shadowPane',
                            // renderer: renderer,
                        })
                    );
                }
            }
            // this.shadow_polyline.addTo(this.map);
            for (let p of this.shadow_polyline) {
                p.addTo(this.map);
                p.on('click', e => {
                    this.$emit('polylineClick', e);
                })
            }
        },
        unsetShadowPolyline() {
            if (!this.use_shadow || !this.shadow_polyline) {
                return;
            }
            for (let p of this.shadow_polyline) {
                p.remove();
            }
            // this.shadow_polyline.remove();
            // this.shadow_polyline = null;
        },
    },
};
</script>
<style>
/* Resolve conflict with Vuesax Tooltip */
.tooltip {
    opacity: inherit;
}
/* .shadow_polyline {
  stroke: yellow;
  fill: none;
  stroke-dasharray: 10,10; 
  stroke-width: 20;
} */

/* .elevation-control.inruting-theme g.point {
    pointer-events: all;
}
.elevation-control.inruting-theme g.point .point {
    pointer-events: all;
}
.elevation-control.inruting-theme g.point .point > text {
    visibility: hidden;
}
.elevation-control.inruting-theme g.point .point:hover > text {
    visibility: visible;
    cursor: crosshair;
} */
</style>