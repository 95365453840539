<template>
    <div v-if="profile_values">
        <ion-list class="profile_fields_list">
            <template v-for="(value, slug) in sorted_values">
                <ion-item
                    :key="slug"
                    v-if="isValidValue(slug, value)"
                    class="keep_line_breaks"
                    text-wrap
                >
                    <ion-label position="stacked">
                        {{ getProfileFieldNameBySlug(slug) }}
                    </ion-label>
                    <span v-if="getProfileFieldFunctionBySlug(slug) == 'url'">
                        <a target="_blank" :href="value">{{ value }}</a>
                    </span>
                    <span
                        v-else-if="
                            getProfileFieldFunctionBySlug(slug) == 'select'
                        "
                        class="tags-item"
                    >
                        <!-- {{ getMultipleValues(value) }} -->
                        <template
                            v-for="item in getMultipleValues(value)"
                            :key="item"
                        >
                            <ion-chip>
                                {{ item }}
                            </ion-chip>
                        </template>
                    </span>
                    <span
                        v-else-if="
                            getProfileFieldFunctionBySlug(slug) == 'multiple'
                        "
                        class="tags-item"
                    >
                        <template
                            v-for="item in getMultipleValues(value)"
                            :key="item"
                        >
                            <ion-chip>
                                {{ item }}
                            </ion-chip>
                        </template>
                    </span>
                    <span
                        v-else-if="
                            getProfileFieldFunctionBySlug(slug) == 'tags'
                        "
                        class="tags-item"
                    >
                        <template v-for="tag in getTagValues(value)" :key="tag">
                            <ion-chip>
                                {{ tag }}
                            </ion-chip>
                        </template>
                    </span>
                    <span
                        v-else-if="
                            getProfileFieldFunctionBySlug(slug) == 'script'
                        "
                    >
                        {{ getScriptValue(slug, value) }}
                    </span>
                    <span
                        v-else-if="
                            getProfileFieldFunctionBySlug(slug) == 'date'
                        "
                    >
                        {{ getValue(value) }}
                    </span>
                    <span v-else>
                        {{ getValue(value) }}
                    </span>
                </ion-item>
            </template>
        </ion-list>
    </div>
</template>

<script>
// Ionic components
import { IonList, IonItem, IonLabel, IonChip } from "@ionic/vue";

import { isArray } from "lodash";

export default {
    name: "GCaminsShowProfile",
    components: {
        IonList,
        IonItem,
        IonLabel,
        IonChip,
    },
    props: {
        profile_values: Object,
        profile_fields: Object,
    },
    watch: {
        profile_values() {
            this.sortValues()
        },
        profile_fields() {
            this.sortValues()
        }
    },
    // computed: {
    // },
    data() {
        return {
            order: {},
            sorted_values: {},
        }
    },
    mounted() {
        this.sortValues();
    },
    methods: {
        sortValues() {
            for(let field of this.profile_fields) {
                this.order[field.slug] = field.position;
            }
            if(!this.profile_values) {
                this.sorted_values = {};
                return;
            }
            const values = Object.keys(this.profile_values).sort((a, b) => {
                    const ap = this.getProfileFieldPositionBySlug(a);
                    const bp = this.getProfileFieldPositionBySlug(b);
                    return ap > bp ? 1 : -1;
                }).reduce(
                (obj, key) => { 
                    obj[key] = this.profile_values[key]; 
                    return obj;
                }, 
                {}
            );
            // this.$set(this, 'sorted_values', values);
            this.sorted_values = values;
        },
        getProfileFieldNameBySlug(slug) {
            const field = this.profile_fields.find((pf) => {
                return pf.slug == slug;
            });
            if (!field) {
                return "";
            }
            if (!(this.$getLocale() in field.name)) {
                return field.name[Object.keys(field.name)[0]];
            }
            return field.name[this.$getLocale()];
        },
        getProfileFieldFunctionBySlug(slug) {
            const field = this.profile_fields.find((pf) => {
                return pf.slug == slug;
            });
            if (!field) {
                return "";
            }
            return field.function;
        },
        getProfileFieldPositionBySlug(slug) {
            return this.order[slug] ?? 0;
        },
        getValue(value) {
            if (value && this.isJSON(value)) {
                value = JSON.parse(value);
            }
            return value;
        },
        getMultipleValues(value) {
            let string = "";

            if (value && this.isJSON(value)) {
                value = JSON.parse(value);
            }
            if (!value || value.length == 0) {
                return [string];
            }
            if (!isArray(value)) {
                return [this.getValue(value)];
            }
            return value;
        },
        getTagValues(value) {
            if (value && this.isJSON(value)) {
                value = JSON.parse(value);
            }
            return value;
        },
        getScriptValue(slug, value) {
            const field = this.profile_fields.find((pf) => {
                return pf.slug == slug;
            });
            if (field.options[this.$getLocale()][1] == 'integer_minutes') {
                return Math.floor(value / 60)+' h '+(value % 60)+' min.';
            }
            return value;
        },
        isJSON(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        isValidValue(slug, value) {
            return slug != 'id' && value != null && value != '' && value != '[]';
        }
    },
};
</script>

<style>
.profile_fields_list span.tags-item {
    margin-top: 5px;
    width: 100%;
}

.profile_fields_list .item.sc-ion-label-md-h,
.profile_fields_list .item .sc-ion-label-md-h {
    overflow: unset;
    white-space: break-spaces;
    margin-bottom: 5px;
}
.keep_line_breaks {
    white-space: pre-line;
}
</style>