<template>
    <div v-if="isLoading" class="lattice-select-container">
        <LoadingScreen :isLoading="isLoading" />
        <ion-content :fullscreen="false" :scroll-y="false">
            <ion-item>
                <ion-label class="ion-text-center" text-wrap>
                    {{ $t("init.loading.map_tab") }}
                </ion-label>
            </ion-item>
        </ion-content>
    </div>
    <div class="lattice-select-container" :class="{ hidden: isLoading }">
        <ion-item>
            {{ $t("map.routing.notice") }}
        </ion-item>
        <!-- keep alive is important to avoid losing the filters selection -->
        <keep-alive>
            <div v-if="$checkConnection()">
                <ion-item button @click="setRoutingPoint('start')">
                    <!-- <ion-button
                        color="secondary"
                        slot="start"
                        @click="setRoutingPoint('start')">
                        <ion-icon slot="icon-only" :icon="icon_flag"></ion-icon>
                    </ion-button>
                    {{ $t('map.routing.start.button') }} -->
                    <ion-icon :icon="icon_flag" slot="start"></ion-icon>
                    <ion-label>
                        {{ $t("map.routing.start.button") }}
                    </ion-label>
                </ion-item>
                <ion-item
                    v-if="locatecontrol"
                    button
                    @click="locationStartPoint"
                >
                    <!-- <ion-button
            v-if="locatecontrol"
            color="secondary"
            slot="start"
            @click="locationStartPoint"
          >
            <ion-icon slot="icon-only" :icon="icon_location"></ion-icon>
          </ion-button>
          {{ $t("map.routing.start.button_location") }} -->
                    <ion-icon :icon="icon_location" slot="start"></ion-icon>
                    <ion-label>
                        {{ $t("map.routing.start.button_location") }}
                    </ion-label>
                </ion-item>
                <ion-item
                    button
                    @click="setRoutingPoint('end')"
                    :disabled="
                        this.routing_points.length == 0 ||
                        !this.start_marker ||
                        this.start_marker._map == null
                    "
                >
                    <!-- <ion-button
            color="secondary"
            slot="start"
            @click="setRoutingPoint('end')"
            :disabled="
              this.routing_points.length == 0 ||
              !this.start_marker ||
              this.start_marker._map == null
            "
          >
            <ion-icon slot="icon-only" :icon="icon_flag2"></ion-icon>
          </ion-button>
          {{ $t("map.routing.end.button") }} -->
                    <ion-icon :icon="icon_flag2" slot="start"></ion-icon>
                    <ion-label>
                        {{ $t("map.routing.end.button") }}
                    </ion-label>
                </ion-item>
                <ion-item
                    button
                    @click="setRoutingPoint('intermediate')"
                    :disabled="
                        this.routing_points.length < 2 ||
                        !this.start_marker ||
                        this.start_marker._map == null ||
                        this.end_marker._map == null
                    "
                >
                    <!-- <ion-button
            color="secondary"
            slot="start"
            @click="setRoutingPoint('intermediate')"
            :disabled="
              this.routing_points.length < 2 ||
              !this.start_marker ||
              this.start_marker._map == null ||
              this.end_marker._map == null
            "
          >
            <ion-icon slot="icon-only" :icon="icon_waypoint"></ion-icon>
          </ion-button>
          {{ $t("map.routing.intermediate.button") }} -->
                    <ion-icon :icon="icon_waypoint" slot="start"></ion-icon>
                    <ion-label>
                        {{ $t("map.routing.intermediate.button") }}
                    </ion-label>
                </ion-item>
                <ion-item>
                    <ion-avatar
                        class="ion-avatar-category-icon"
                        slot="start"
                        style="margin-right: 30px"
                    >
                        <category-img
                            :category="selected_category"
                        ></category-img>
                    </ion-avatar>
                    <ion-select
                        v-model="selected_category"
                        @ion-change="onCategorySelected"
                    >
                        <ion-select-option :value="0">{{
                            $t("map.routing.select_all")
                        }}</ion-select-option>
                        <template v-for="item in categories" :key="item.id">
                            <ion-select-option
                                v-if="item.parent_id > 0"
                                :value="item"
                                >{{ getFeatureName(item) }}</ion-select-option
                            >
                        </template>
                    </ion-select>
                </ion-item>
                <div class="lattice_button_container">
                    <ion-button
                        color="tertiary"
                        @click="_clear"
                        :disabled="this.routing_points.length < 1"
                    >
                        <ion-icon slot="start" :icon="icon_point"></ion-icon>
                        {{ $t("map.routing.clear.button") }}
                    </ion-button>
                </div>
                <div class="lattice_button_container">
                    <ion-button
                        color="primary"
                        @click="processRouting"
                        :disabled="
                            this.routing_points.length < 2 ||
                            this.start_marker._map == null ||
                            this.end_marker._map == null
                        "
                    >
                        <ion-icon slot="start" :icon="icon_point"></ion-icon>
                        {{ $t("map.routing.confirm.button") }}
                    </ion-button>
                </div>
                <div>
                    <ion-list-header>
                        <h4>{{ $t("map.routing.point_list.title") }}</h4>
                    </ion-list-header>
                    <ion-list class="routing_points">
                        <template
                            v-for="(point, index) in routing_points"
                            :key="index"
                        >
                            <ion-item>
                                <ion-avatar slot="start">
                                    <img
                                        :src="getPointListIconUrl(point)"
                                        :title="getPointListName(point)"
                                    />
                                </ion-avatar>
                                {{
                                    point.lat.toFixed(4) +
                                    ", " +
                                    point.lng.toFixed(4)
                                }}
                                <ion-button
                                    slot="end"
                                    color="secondary"
                                    @click="removePoint(point)"
                                >
                                    <ion-icon
                                        slot="icon-only"
                                        :icon="icon_close"
                                    ></ion-icon>
                                </ion-button>
                            </ion-item>
                        </template>
                    </ion-list>
                </div>
            </div>
            <div v-else>
                {{ $t("init.errors.no_offline_option") }}
            </div>
        </keep-alive>
        <div v-if="!filtering">
            <ion-list-header>
                <h4>{{ $t("map.routing.offline.title") }}</h4>
            </ion-list-header>
            <ion-list>
                <template
                    v-for="feature in offline_routing"
                    :key="feature.offline_id"
                >
                    <ion-item>
                        {{ feature.id }}
                        <ion-button
                            slot="end"
                            color="danger"
                            @click="deleteOfflineRouting(feature)"
                        >
                            <ion-icon
                                slot="icon-only"
                                :icon="icon_delete"
                            ></ion-icon>
                        </ion-button>
                        <ion-button
                            slot="end"
                            color="secondary"
                            @click="selectOfflineRouting(feature)"
                        >
                            <ion-icon
                                slot="icon-only"
                                :icon="icon_go"
                            ></ion-icon>
                        </ion-button>
                    </ion-item>
                </template>
            </ion-list>
        </div>
    </div>
</template>

<script>
// Storage
import ApiStorage from "@/api/storage";

// Client
import ApiClient from "@/api/client";

import GenericList from "./GenericList.vue";
// import FiltersRemote from '@/components/filters/FiltersRemote.vue';
import {
    IonListHeader,
    IonSelect,
    IonSelectOption,
    IonContent,
} from "@ionic/vue";
import {
    locationOutline,
    closeCircleOutline,
    flagOutline,
    flagSharp,
    locateOutline,
    gitCommitOutline,
    arrowForwardCircleOutline,
    trashOutline,
} from "ionicons/icons";
import { isEqual } from "lodash";
import { Toast } from "@capacitor/toast";
import { decompressFromEncodedURIComponent } from "lz-string";

export default {
    name: "LatticeSelect",
    extends: GenericList,
    emits: [
        "close-sidebar",
        "open-sidebar",
        "featureListClick",
        "filter",
        "vectorlayer",
    ],
    components: {
        IonListHeader,
        IonSelect,
        IonSelectOption,
        IonContent,
        // 'filters-remote': FiltersRemote,
    },
    props: {
        leafletObject: Object,
        locatecontrol: Object,
        current_tab: String,
    },
    data() {
        return {
            resource: "arclines",
            featureType: "Lattice",
            model: "Modules\\Lattice\\Entities\\Arcline",
            isLoading: true,
            icon_point: locationOutline,
            icon_close: closeCircleOutline,
            icon_flag: flagOutline,
            icon_flag2: flagSharp,
            icon_location: locateOutline,
            icon_waypoint: gitCommitOutline,
            icon_go: arrowForwardCircleOutline,
            icon_delete: trashOutline,

            categories: {},
            routing_points: [],
            start_icon: null,
            end_icon: null,
            start_marker: null,
            intermediate_markers: [],
            end_marker: null,
            current_route: null,
            route: null,
            route_ids: [],
            selected_lattice_group_id: 0,
            selected_category: 0,
            lattice_groups: [],
            layers: [],
            geo_info: null,
            geo_meta: null,
            waiting_location: false,

            offline_routing: [],
        };
    },
    watch: {
        current_tab(val) {
            if (val == "sidebar_lattice") {
                this.setPreviousMarkers();

                // WARNING: Told to do this but might revert it later
                // // Show layers if on tab
                // for(let layer of this.layers) {
                //     this.$emit('vectorlayer', layer.id, true);
                // }
                // Disable polylines layers
                this.$emit("vectorlayer", "polylines", false);
                this.$emit("vectorlayer", "start-end-markers", false);
                // END WARNING

                this.loadOfflineRouting();
            } else if (
                val == "" ||
                val == "sidebar_layers" ||
                val == "sidebar_embed"
            ) {
                return;
            } else {
                this.removeMarkers();
                // WARNING: Told to do this but might revert it later
                // // Hide layers if not on tab
                // for(let layer of this.layers) {
                //     this.$emit('vectorlayer', layer.id, false);
                // }
                // END WARNING
            }
        },
    },
    async mounted() {
        if (!this.start_icon) {
            this.start_icon = new window.L.Icon({
                iconUrl:
                    process.env.BASE_URL +
                    "assets/modules/lattice/images/flag-start.png",
                iconSize: [25, 25],
                iconAnchor: [12, 12],
            });
            this.end_icon = new window.L.Icon({
                iconUrl:
                    process.env.BASE_URL +
                    "assets/modules/lattice/images/flag-end.png",
                iconSize: [25, 25],
                iconAnchor: [12, 12],
            });
            this.intermediate_icon = new window.L.Icon({
                iconUrl:
                    process.env.BASE_URL +
                    "assets/modules/lattice/images/flag-intermediate.png",
                iconSize: [25, 25],
                iconAnchor: [12, 12],
            });
        }
        this.categories = this.getModelCategories();
        this.lattice_groups = await ApiStorage.getAll("lattice_groups");
        this.layers = await ApiStorage.getAll("vector_layer_list").then(
            (layers) => {
                return layers.filter((l) => {
                    return l.type == "Lattice";
                });
            }
        );

        await this.loadOfflineRouting();

        this.$nextTick(() => {
            // Show layers if on tab at loading time
            if (this.current_tab == "sidebar_lattice") {
                for (let layer of this.layers) {
                    this.$emit("vectorlayer", layer.id, true);
                }
            }
        });

        this.isLoading = false;
    },
    methods: {
        async loadOfflineRouting() {
            this.offline_routing = await ApiClient.getSavedRouting();
        },
        getFeatureName(info) {
            if (info.name[this.$getLocale()]) {
                return info.name[this.$getLocale()];
            }
            return info.name[Object.keys(info.name)[0]];
        },
        setRoutingPoint(rpoint_type) {
            switch (rpoint_type) {
                case "start":
                    this.leafletObject.on("click", this._mapClickRoutingStart);
                    break;
                case "end":
                    this.leafletObject.on("click", this._mapClickRoutingEnd);
                    break;
                case "intermediate":
                    this.leafletObject.on(
                        "click",
                        this._mapClickRoutingIntermediate
                    );
                    break;
            }

            if (["xs", "sm"].includes(this.$root.$getWindowSize())) {
                this.$emit("close-sidebar");
            }
            Toast.show({
                text: this.$t("map.routing.indications"),
            });
        },
        manualRoutingPoint(rpoint_type, latlng) {
            switch (rpoint_type) {
                case "start":
                    this._mapClickRoutingStart({ latlng });
                    break;
                case "end":
                    this._mapClickRoutingEnd({ latlng });
                    break;
                case "intermediate":
                    this._mapClickRoutingIntermediate({ latlng });
                    break;
            }
        },
        _mapClickRoutingStart(event) {
            this.leafletObject.off("click", this._mapClickRoutingStart);
            this._getNearest(event.latlng, (data) => {
                if (
                    this.routing_points.length > 1 ||
                    !this.end_marker ||
                    this.end_marker._map == null
                ) {
                    this.removeMarkerStart();
                }

                let prev = this.routing_points;
                prev.unshift(data.point);
                this.routing_points = prev;
                this.setMarkerStart(data.point);
                this.$emit("open-sidebar", "sidebar_lattice");
            });
        },
        setMarkerStart(latlng) {
            if (!this.start_marker) {
                this.start_marker = new window.L.Marker(latlng, {
                    icon: this.start_icon,
                });
                this.start_marker.on("click", this.removeMarkerStart);
            }
            this.start_marker.addTo(this.leafletObject);
            this.start_marker.setLatLng(latlng);
        },
        removeMarkerStart() {
            if (this.start_marker) {
                this.start_marker.remove();
                this.start_marker = null;
            }
            this.routing_points.splice(0, 1);
        },
        _mapClickRoutingEnd(event) {
            if (this.routing_points.length < 1) {
                return;
            }

            this.leafletObject.off("click", this._mapClickRoutingEnd);
            this._getNearest(event.latlng, (data) => {
                if (this.routing_points.length == 1) {
                    this.routing_points[this.routing_points.length] =
                        data.point;
                } else {
                    this.routing_points[this.routing_points.length - 1] =
                        data.point;
                }
                this.setMarkerEnd(data.point);
            });
        },
        setMarkerEnd(latlng) {
            if (!this.end_marker) {
                this.end_marker = new window.L.Marker(latlng, {
                    icon: this.end_icon,
                });
                this.end_marker.on("click", this.removeMarkerEnd);
            }
            this.end_marker.addTo(this.leafletObject);
            this.end_marker.setLatLng(latlng);
            this.$emit("open-sidebar", "sidebar_lattice");
        },
        removeMarkerEnd() {
            if (this.end_marker) {
                this.end_marker.remove();
                this.end_marker = null;
            }
            this.routing_points.splice(this.routing_points.length - 1, 1);
        },
        _mapClickRoutingIntermediate(event) {
            if (this.routing_points.length < 2) {
                return;
            }
            this.leafletObject.off("click", this._mapClickRoutingIntermediate);
            this._getNearest(event.latlng, (data) => {
                this.routing_points.splice(
                    this.routing_points.length - 1,
                    0,
                    data.point
                );
                this.addMarkerIntermediate(data.point);
            });
        },
        addMarkerIntermediate(latlng) {
            const marker = new window.L.Marker(latlng, {
                icon: this.intermediate_icon,
            });
            marker.on("click", (event) => {
                this.removeIntermediateMarker(event.target.getLatLng());
            });

            marker.addTo(this.leafletObject);
            marker.setLatLng(latlng);

            this.intermediate_markers.push(marker);
            this.$emit("open-sidebar", "sidebar_lattice");

            return marker;
        },
        removeIntermediateMarker(location) {
            const position0 = this.routing_points.findIndex((p) => {
                return p.lat == location.lat && p.lng == location.lng;
            });
            const position1 = this.intermediate_markers.findIndex((mk) => {
                return isEqual(mk.getLatLng(), location);
            });

            if (position0 > -1) {
                this.routing_points.splice(position0, 1);
            }

            if (position1 > -1) {
                this.intermediate_markers[position1].remove();
                this.intermediate_markers.splice(position1, 1);
            }
        },
        processRouting() {
            ApiClient.routingGetRoute({
                points: this.routing_points,
                categories:
                    this.selected_category == 0
                        ? []
                        : [this.selected_category.id],
            }).then((response) => {
                if (response.data.status) {
                    this.setCurrentRoute(
                        response.data.geojson,
                        response.data.ids,
                        response.data.geo_info,
                        response.data.geo_meta
                    );
                    this.confirmRoute();
                } else {
                    Toast.show({
                        text: this.$t("map.routing.process_error"),
                    });
                }
            });
        },
        _getNearest(latlng, cb) {
            ApiClient.routingGetNearestPoint({
                lat: latlng.lat,
                lng: latlng.lng,
                categories:
                    this.selected_category == 0
                        ? []
                        : [this.selected_category.id],
            })
                .then((response) => {
                    if (response.data) {
                        cb(response.data);
                        if (!this.selected_lattice_group_id) {
                            this.selected_lattice_group_id =
                                response.data.lattice_group_id;
                        }
                    } else {
                        Toast.show({
                            text: this.$t("map.routing.nearest_error"),
                        });
                    }
                })
                .catch(() => {
                    Toast.show({
                        text: this.$t("map.routing.nearest_error"),
                    });
                });
        },
        setCurrentRoute(geojson, ids, geo_info, geo_meta) {
            if (this.current_route) {
                this._route.remove();
            }
            this._route_ids = ids;
            const lattice_group = this.lattice_groups.find((g) => {
                return (g.id = this.selected_lattice_group_id);
            });
            this.current_route = geojson;
            this.geo_info = geo_info;
            this.geo_meta = geo_meta;
            this._route = window.L.geoJSON(geojson, {
                style: () => {
                    return {
                        color: lattice_group
                            ? lattice_group.selection_style_color
                            : "#E99B00",
                        width: lattice_group
                            ? lattice_group.selection_style_width
                            : 5,
                        opacity: lattice_group
                            ? lattice_group.selection_style_opacity
                            : 0.9,
                        zIndex: 460,
                    };
                },
            });
            this._route.addTo(this.leafletObject);
        },
        _clear() {
            if (this.start_marker) {
                this.start_marker.remove();
            }
            if (this.end_marker) {
                this.end_marker.remove();
            }
            this.intermediate_markers.forEach((mk) => mk.remove());
            if (this.current_route) {
                this._route.remove();
                this._route = null;
                this._route_ids = [];
                this.current_route = null;
            }
            this.routing_points = [];

            this.$emit("featureListClick", null);
        },
        removePoint(point) {
            if (
                this.start_marker &&
                isEqual(this.start_marker.getLatLng(), point)
            ) {
                this.removeMarkerStart();
            } else if (
                this.end_marker &&
                isEqual(this.end_marker.getLatLng(), point)
            ) {
                this.removeMarkerEnd();
            } else {
                this.removeIntermediateMarker(point);
            }
        },
        getPointListName(point) {
            if (
                this.start_marker &&
                isEqual(this.start_marker.getLatLng(), point)
            ) {
                return this.$t("map.routing.start.marker");
            }
            if (
                this.end_marker &&
                isEqual(this.end_marker.getLatLng(), point)
            ) {
                return this.$t("map.routing.end.marker");
            }
            return this.$t("map.routing.intermediate.marker");
        },
        getPointListIconUrl(point) {
            if (
                this.start_marker &&
                isEqual(this.start_marker.getLatLng(), point)
            ) {
                return (
                    process.env.BASE_URL +
                    "assets/modules/lattice/images/flag-start.png"
                );
            }
            if (
                this.end_marker &&
                isEqual(this.end_marker.getLatLng(), point)
            ) {
                return (
                    process.env.BASE_URL +
                    "assets/modules/lattice/images/flag-end.png"
                );
            }
            return (
                process.env.BASE_URL +
                "assets/modules/lattice/images/flag-intermediate.png"
            );
        },
        confirmRoute() {
            const lattice_group = this.lattice_groups.find((g) => {
                return (g.id = this.selected_lattice_group_id);
            });
            this.$emit("featureListClick", {
                type: this.featureType,
                id: lattice_group.id,
                name: lattice_group.name,
                info: {
                    points: this.routing_points,
                    lattice_group: lattice_group,
                },
            });
            this._route.remove();
        },
        locationStartPoint() {
            if (!this.locatecontrol) {
                return false;
            }

            if (["xs", "sm"].includes(this.$root.$getWindowSize())) {
                this.$emit("close-sidebar");
            }

            this.locatecontrol.start();
            this.waiting_location = true;

            if (this.$parent.last_location) {
                this._onLocationFound({ latlng: this.$parent.last_location });
            } else {
                this.leafletObject.on("locationfound", this._onLocationFound);
            }
            // this.leafletObject.on('locationfound', this._onLocationFound);
        },
        _onLocationFound(event) {
            if (!this.waiting_location) {
                return;
            }
            this.waiting_location = false;
            this._mapClickRoutingStart(event);
            this.leafletObject.off("locationfound", this._onLocationFound);
        },
        removeMarkers() {
            if (this.start_marker) {
                this.start_marker.remove();
            }
            if (this.end_marker) {
                this.end_marker.remove();
            }
            this.intermediate_markers.forEach((m) => {
                m.remove();
            });
        },
        setPreviousMarkers() {
            if (this.start_marker) {
                this.start_marker.addTo(this.leafletObject);
            }
            if (this.end_marker) {
                this.end_marker.addTo(this.leafletObject);
            }
            this.intermediate_markers.forEach((m) => {
                m.addTo(this.leafletObject);
            });
        },
        selectOfflineRouting(feature_info) {
            const selected_feature = JSON.parse(
                decompressFromEncodedURIComponent(
                    decodeURIComponent(feature_info.feature_string)
                )
            );
            selected_feature.offline_data = feature_info.data;
            this.$emit("featureListClick", null);
            this.$nextTick(() => {
                this.$emit("featureListClick", selected_feature);
            });
        },
        async deleteOfflineRouting(feature_info) {
            this.setLoading();
            await ApiClient.removeRouting(feature_info.id);
            this.offline_routing = await ApiClient.getSavedRouting();
            this.unsetLoading();
        },
        // getModelCategories() {
        //     return ApiStorage.getAll('categories').then(categories => {
        //         let result = {};
        //         categories.filter(c => {
        //             return c.type == this.model;
        //         })
        //         .forEach(c => {
        //             result[c.id] = c;
        //         });
        //         return result;
        //     });
        // },
        applyFilters(functions) {
            this.filter_functions = functions;
            this.$emit("filter", {
                type: this.model,
                functions,
            });
            this.setFilteredItems();
            this.filtering = false;
        },
        onCategorySelected(event) {
            if (event.detail.value == 0) {
                this.applyFilters([]);
            } else {
                this.applyFilters([
                    {
                        data: event.detail.value.id,
                        run: function (element) {
                            if (!element) {
                                return false;
                            }
                            return element.categories.includes(this.data);
                        },
                    },
                ]);
            }
        },
        setLoading() {
            this.isLoading = true;
        },
        unsetLoading() {
            this.isLoading = false;
        },
    },
};
</script>

<style>
.lattice-select-container {
    flex: 99;
    display: flex;
    flex-direction: column;
}
.lattice-select-container.hidden {
    display: none;
}
.lattice_button_container {
    margin-left: 15px;
}
</style>