<template>
    <ion-content :class="{ loader_content: true, fadeout: !isLoading }" :fullscreen="false" :scroll-y="false">
        <ion-item v-if="error" color="danger">
            <ion-icon slot="end" :icon="alertCircle"></ion-icon>
            <ion-label text-wrap>
                {{ error }}
            </ion-label>
        </ion-item>
        <div v-else>
            <div class="ion-text-center">
                <img v-if="updating" class="loading_image" src="assets/img/grafismo-carga-segunda-vez.svg" />
                <img v-else class="loading_image" src="assets/img/grafismo-carga-primera-vez.svg" />
            </div>
            <div class="ion-text-center loading_message">
                <div v-if="updating === true" style="max-width: 450px; margin: auto;">
                    {{ $t('init.loading.updating') }}
                </div>
                <div v-else-if="updating === false" style="max-width: 450px; margin: auto;">
                    {{ $t('init.loading.first_time') }}
                </div>
                <div v-else style="max-width: 450px; margin: auto;">
                    {{ $t('init.loading.map_tab') }}
                </div>
            </div>
            <div class="ion-text-center">
                <ion-spinner name="crescent"></ion-spinner>
            </div>
            <div class="ion-text-center">
                {{ percent }}%
            </div>
        </div>
    </ion-content>
</template>

<script>
import { IonContent, IonItem, IonSpinner, IonLabel, IonIcon } from '@ionic/vue';
import { alertCircle } from 'ionicons/icons';
export default {
    name: "LoadingScreen",
    data() {
        return {
            alertCircle,
        }
    },
    props: {
        isLoading: Boolean,
        percent: Number,
        error: String,
        updating: {
            type: [Boolean],
            custom: true,
            default: () => null
        },
    },
    components: {
        IonItem,
        IonContent,
        IonSpinner,
        IonLabel,
        IonIcon,
    },
    // mounted() {
    //     this.buffer_percent = this.$getState('buffer_percent');

    //     this.emitter.on('app-state-updated', async (newState) => {
    //         this.buffer_percent = this.$getState('buffer_percent');
    //     });
    // },
};
</script>

<style>
.loader_content {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
}

.fadeout {
  animation: fadeout .5s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.loading_message {
    font-size: 20px;
}
.loading_image {
    height: 44vh;
    margin-top: 8vh;
    margin-bottom: 8vh;
}
</style>
