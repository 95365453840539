<template>
    <ion-page>
        <ion-header class="noprint">
            <ion-toolbar translucent>
                <ion-buttons slot="start">
                    <!-- <ion-menu-button></ion-menu-button> -->
                    <ion-button v-if="$isBrowser()" @click="onBackPressed()">
                        <ion-icon slot="icon-only" :icon="icon_back"></ion-icon>
                    </ion-button>
                    <ion-back-button
                        default-href="/pages/map#sidebar_info"
                        text=""
                        v-else
                    ></ion-back-button>
                </ion-buttons>
                <ion-title class="main-logo-container">
                    <h1 class="title">
                        <img
                            :alt="$t('general.logo_alt')"
                            class="title-image"
                            :src="
                                base_url +
                                'assets/img/logo-cabecera-InRuting.png'
                            "
                        />
                    </h1>
                </ion-title>
                <ion-buttons slot="end">
                    <ion-button class="print_button" color="secondary" @click="forcePrint()">
                        <ion-icon slot="icon-only" :icon="icon_print"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
            <ion-progress-bar
                v-if="isLoading"
                :color="loading_error ? 'danger' : 'primary'"
                :value="loading_percent"
                :buffer="buffer_percent"
            ></ion-progress-bar>
        </ion-header>
        <ion-content v-if="isLoadingPartial">
            <!-- <LoadingScreen :isLoading="isLoading" /> -->
            <DataLoadingScreen
                :isLoading="isLoadingPartial"
                :updating="updating"
                :error="loading_error"
                :percent="loading_percent * 100"
            />
        </ion-content>
        <ion-content class="print-page" v-else>
            <div v-if="error && error != ''">
                {{ error }}
            </div>
            <div class="map_frame" style="height: 300px">
                <l-map
                    v-if="!isLoadingPartial"
                    @ready="init"
                    ref="map"
                    :use-global-leaflet="true"
                    :zoom-animation="true"
                    :bounds="workbounds"
                    style="
                        position: inherit;
                        width: 100%;
                        min-height: 300px;
                        max-height: 300px;
                    "
                >
                    <l-control-scale position="bottomleft"></l-control-scale>
                    <l-tile-layer
                        v-for="tileProvider in baseLayers"
                        :key="tileProvider.url"
                        :name="tileProvider.name"
                        :visible="tileProvider.visible"
                        :url="tileProvider.url"
                        :attribution="tileProvider.attribution"
                        layer-type="base"
                    >
                    </l-tile-layer>
                </l-map>
            </div>
            <div v-if="showSpinner" style="text-align: center">
                <ion-spinner style="margin: auto" name="crescent"></ion-spinner>
            </div>
            <div v-if="selected_feature && $refs.map" class="main-object">
                <point-info
                    :selected_feature="selected_feature"
                    :leafletObject="$refs.map.leafletObject"
                ></point-info>
                <polyline-info
                    :selected_feature="selected_feature"
                    :leafletObject="$refs.map.leafletObject"
                ></polyline-info>
                <arcline-info
                    :selected_feature="selected_feature"
                    :leafletObject="$refs.map.leafletObject"
                    current_tab="sidebar_info"
                    ref="arcline_info"
                ></arcline-info>
                <template v-if="selected_feature.type == 'Lattice'">
                    <arcline-extra-info
                        ref="arcline_extra_info"
                        :selected_feature="selected_feature"
                        :workbounds="workbounds"
                        :baseLayers="baseLayers"
                        @loaded="arclineExtraInfoLoaded"
                    ></arcline-extra-info>
                </template>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import ApiStorage from "@/api/storage";
import {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonBackButton,
    IonIcon,
    IonTitle,
    IonProgressBar,
    IonSpinner,
} from "@ionic/vue";
import { arrowBackOutline, printOutline } from "ionicons/icons";

import { ref, toRefs } from "vue";

// import LoadingScreen from '@/components/LoadingScreen.vue'
import DataLoadingScreen from "@/components/DataLoadingScreen.vue";
import { decompressFromEncodedURIComponent } from "lz-string";

// Info components
import PointInfo from "@/components/modules/PointInfo.vue";
import PolylineInfo from "@/components/modules/PolylineInfo.vue";
import ArclineInfo from "@/components/modules/ArclineInfo.vue";
import ArclineExtraInfo from "@/components/modules/ArclineExtraInfo.vue";

// Cordova print plugin
import { Printer } from '@awesome-cordova-plugins/printer';

import * as $ from "jquery";

export default {
    name: "PrintPage",
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonButton,
        IonBackButton,
        IonIcon,
        IonTitle,
        // LoadingScreen,
        IonProgressBar,
        IonSpinner,
        DataLoadingScreen,
        "point-info": PointInfo,
        "polyline-info": PolylineInfo,
        "arcline-info": ArclineInfo,
        "arcline-extra-info": ArclineExtraInfo,
    },
    data() {
        return {
            routing_points: [],
            showSpinner: false,
        };
    },
    props: {
        feature_param: {
            type: String,
            required: false,
        },
    },
    async mounted() {
        this.workbounds = await ApiStorage.get("config.workbounds");

        this.emitter.on("app-state-updated", (newState) => {
            this.setState(newState);
        });

        this.setState(this.$getState());
    },
    methods: {
        /**
         * Try to get the whole size of the contents and
         * extend the required DOM elements to that size.
         * Otherwise will only print one page.
         */
        setPrintSize() {
            let height = 100; // Some extra size to avoid scrollbar
            for (let domElement of $("ion-content.print-page").children()) {
                height += domElement.offsetHeight;
            }
            $("ion-app.ion-page").css("height", height + "px");
            $("div#app").css("height", height + "px");
        },
        unsetPrintSize() {
            $("ion-app.ion-page").css("height", "auto");
            $("div#app").css("height", "auto");
        },
        setPrintEvents() {
            var beforePrint = () => {
                this.setPrintSize();
            };
            var afterPrint = () => {
                this.unsetPrintSize();
            };

            if (window.matchMedia) {
                var mediaQueryList = window.matchMedia("print");
                mediaQueryList.addListener((mql) => {
                    if (mql.matches) {
                        beforePrint();
                    } else {
                        afterPrint();
                    }
                });
            }

            window.onbeforeprint = beforePrint;
            window.onafterprint = afterPrint;
        },
        setState(newState) {
            this.isLoading = Object.prototype.hasOwnProperty.call(
                newState,
                "isLoading"
            )
                ? newState.isLoading
                : this.isLoading;
            this.isLoadingPartial = Object.prototype.hasOwnProperty.call(
                newState,
                "isLoadingPartial"
            )
                ? newState.isLoadingPartial
                : this.isLoadingPartial;
            this.loading_percent = Object.prototype.hasOwnProperty.call(
                newState,
                "loading_percent"
            )
                ? newState.loading_percent / 100
                : this.loading_percent;
            this.buffer_percent = Object.prototype.hasOwnProperty.call(
                newState,
                "buffer_percent"
            )
                ? newState.buffer_percent / 100
                : this.buffer_percent;
            this.loading_error = Object.prototype.hasOwnProperty.call(
                newState,
                "loading_error"
            )
                ? newState.loading_error
                : this.loading_error;
            this.updating = Object.prototype.hasOwnProperty.call(
                newState,
                "updating"
            )
                ? newState.updating
                : this.updating;
        },
        init(target_map) {
            // feature_param property is quite unreliable, read the query
            const feature_param = this.$route.query.selected_feature;
            this.setPrintEvents();
            this.setSelectedFeature(feature_param);
            this.$nextTick(() => {
                this.triggerMapResize(target_map, () => {
                    this.setFeature();
                });
            });
        },
        triggerMapResize(target_map, callback) {
            if (target_map && target_map._loaded) {
                let resized = false;
                try {
                    resized = target_map.invalidateSize(true);
                } catch (error) {
                    // It is sometimes not really ready
                    console.log(error);
                }
                if (!resized || resized._size.x == 0) {
                    setTimeout(() => {
                        this.triggerMapResize(target_map, callback);
                    }, 200);
                    return;
                }
                this.$nextTick(() => {
                    if (
                        target_map.getSize().x > 0 &&
                        target_map.getZoom() < 3 &&
                        this.workbounds
                    ) {
                        // Map messes itself, fit bounds
                        target_map.fitBounds(this.workbounds, {
                            animate: false,
                        });
                    }
                    callback();
                });
            } else {
                setTimeout(() => {
                    this.triggerMapResize(target_map, callback);
                }, 200);
            }
        },
        setFeature() {
            // let location;
            switch (this.selected_feature.type) {
                // case 'Point':
                //     location = window.L.latLng(
                //         this.selected_feature.info.point.coordinates[1],
                //         this.selected_feature.info.point.coordinates[0]
                //     );
                //     this.$refs.map.leafletObject.flyTo(location, 15);
                //     break;
                // case 'Polyline':
                // break;
                case "Lattice":
                    this.showSpinner = true;
                    this.showRoutingPoints();
                    break;
            }
        },
        showRoutingPoints() {
            const start_icon = new window.L.Icon({
                iconUrl:
                    process.env.BASE_URL +
                    "assets/modules/lattice/images/flag-start.png",
                iconSize: [25, 25],
                iconAnchor: [12, 12],
            });
            const end_icon = new window.L.Icon({
                iconUrl:
                    process.env.BASE_URL +
                    "assets/modules/lattice/images/flag-end.png",
                iconSize: [25, 25],
                iconAnchor: [12, 12],
            });
            const intermediate_icon = new window.L.Icon({
                iconUrl:
                    process.env.BASE_URL +
                    "assets/modules/lattice/images/flag-intermediate.png",
                iconSize: [25, 25],
                iconAnchor: [12, 12],
            });
            const points = this.selected_feature.info.points;
            for (let location of points) {
                let icon;
                if (location == points[0]) {
                    icon = start_icon;
                } else if (location == points[points.length - 1]) {
                    icon = end_icon;
                } else {
                    icon = intermediate_icon;
                }
                new window.L.Marker(location, {
                    icon: icon,
                }).addTo(this.$refs.map.leafletObject);
            }
        },
        onBackPressed() {
            if (this.$isBrowser()) {
                window.close();
            }
        },
        arclineExtraInfoLoaded() {
            this.showSpinner = false;
        },
        // showSpinner() {
        //     return (
        //         this.selected_feature.type == "Lattice" &&
        //         (!this.$refs.arcline_extra_info
        //          || !this.$refs.arcline_extra_info.isLoaded())
        //     );
        // },
        forcePrint() {
            if(this.$isBrowser()) {
                window.print();
            } else {
                Printer.print();
            }
        }
    },
    setup(props) {
        const { feature_param } = toRefs(props);
        const map = ref(null);
        const error = ref("");
        const base_url = ref(process.env.BASE_URL);
        const icon_back = ref(arrowBackOutline);
        const icon_print = ref(printOutline);
        const isLoading = ref(true);
        const isLoadingPartial = ref(true);
        const loading_error = ref(null);
        const loading_percent = ref(0);
        const buffer_percent = ref(0);
        const updating = ref(null);
        const arcline_info = ref(null);
        const arcline_extra_info = ref(null);

        const workbounds = ref([]);
        const baseLayers = ref([
            {
                name: "OpenStreetMap",
                visible: true,
                attribution:
                    '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            },
            {
                name: "OpenTopoMap",
                visible: false,
                url: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
                attribution:
                    'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
            },
        ]);

        const initialised = ref(false);
        const selected_feature = ref({});
        const setSelectedFeature = async (param) => {
            param = param || feature_param.value;
            try {
                selected_feature.value = JSON.parse(
                    decompressFromEncodedURIComponent(decodeURIComponent(param))
                );
            } catch (e) {
                error.value = e;
            }
            initialised.value = true;
        };

        return {
            map,
            initialised,
            selected_feature,
            baseLayers,
            setSelectedFeature,
            error,
            workbounds,
            isLoading,
            isLoadingPartial,
            loading_error,
            loading_percent,
            buffer_percent,
            updating,
            arcline_info,
            arcline_extra_info,

            base_url,

            icon_back,
            icon_print,
        };
    },
};
</script>

<style>
.print-page {
    display: flex;
    flex-direction: column;
    max-width: 21cm;
}
.map_frame {
    height: 300px;
    width: 100%;
    position: relative;
}
@media print {
    /**
     * This is a try to override some properties
     * of ionic's CSS in order to print better.
     * Unfortunately, the use of shadow-root requires
     * using dirty JS tricks to do the real job.
     * @see setPrintEvents()
     */
    .noprint {
        display: none;
    }
    body {
        position: relative !important;
        overflow: visible !important;
        display: flex !important;
    }
    body.backdrop-no-scroll {
        overflow: visible !important;
    }
    div#app {
        height: auto;
        flex: 1;
        display: flex;
    }
    ion-app.ion-page {
        position: relative !important;
        overflow: visible !important;
        height: auto;
        flex: 1;
    }
    .print-page {
        /* height: 100%; */
        position: relative;
        overflow: visible;
    }
    .menu-content {
        position: relative !important;
        overflow: visible !important;
        height: auto;
        flex: 1;
        display: flex;
    }
    div.ion-page {
        position: relative !important;
        /* overflow: visible !important; */
        height: auto;
        flex: 1;
    }
    .print-page {
        /* height: 100%; */
        height: auto !important;
        overflow: visible !important;
        position: relative !important;
    }
    .map_frame,
    .leaflet-container,
    p,
    .info-tab div {
        page-break-inside: avoid;
    }
    /* .inruting_elevation { page-break-after: always; } */
    ion-item::part(native) {
        page-break-inside: avoid;
        break-inside: avoid;
        position: relative;
    }
}
/* Styles for iOS app only */
.plt-capacitor .toolbar-title-default.ios {
    min-height: 100px;
}
/*.plt-capacitor .main-logo-container.ios,*/
.noprint .main-logo-container.ios {
    height: 45px;
}
/* .print_button {
  color: var(--color-secondary);
} */

.print-page .print-view-hide {
    display: none;
}
</style>