<template>
    <ion-modal
        class="upload-modal"
        :is-open="upload_modal_open"
        :show-backdrop="true"
        :can-dismiss="!isLoading"
        :backdrop-dismiss="!isLoading"
        :presenting-element="$parent.$refs.ionRouterOutlet"
        @did-dismiss="cancelUpload">
        <ion-header>
            <ion-toolbar>
                <ion-title>{{ $t('map.upload.prompt_title') }}</ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="cancelUpload"><ion-icon  :icon="icon_close"></ion-icon></ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <div v-if="isLoading">
            <ion-label class="ion-text-center spinner-container" text-wrap>
                <ion-spinner name="crescent"></ion-spinner>
            </ion-label>
        </div>
        <div v-else-if="finished">
            <ion-title>{{ $t('map.upload.thanks.title') }}</ion-title>
            <ion-item>
                {{ $t('map.upload.thanks.description') }}
            </ion-item>
            <ion-item>
                <ion-button @click="successUpload" color="success">
                    <ion-icon slot="start" :icon="icon_check"></ion-icon>
                    {{ $t('map.upload.thanks.button') }}
                </ion-button>
            </ion-item>
        </div>
        <div v-else>
            <ion-list>
                <ion-list-header>{{ getItemName(feature.name) }}</ion-list-header>
                <ion-item>
                    <ion-label position="stacked">{{ $t('map.upload.files.label') }}</ion-label>
                    <!-- <ion-input id="upload_image_input" type="file" accept="image/*" multiple></ion-input> -->
                    <ion-input id="upload_image_input" type="file" accept="image/*"></ion-input>
                </ion-item>
                <ion-item>
                    <ion-label position="floating">{{ $t('map.upload.name.label') }}</ion-label>
                    <ion-input v-model="formdata.name"></ion-input>
                </ion-item>
            </ion-list>
            <ion-accordion-group>
                <ion-accordion toggle-icon-slot="start">
                    <ion-item slot="header" class="text-wrap">
                        {{ $t('map.upload.terms.label') }}
                        <ion-checkbox
                            color="primary"
                            slot="end"
                            v-model="formdata.terms"
                            @click.stop=""
                            ></ion-checkbox>
                    </ion-item>

                    <ion-list slot="content" style="overflow: auto; max-height: 150px;">
                        <!-- <ion-item class="accordion-subitem text-wrap"> -->
                        <ion-item class="text-wrap">
                            {{ $t('map.upload.terms.description') }}
                        </ion-item>
                    </ion-list>
                </ion-accordion>
            </ion-accordion-group>

            <ion-button @click="cancelUpload" color="danger">
                <ion-icon slot="start" :icon="icon_close"></ion-icon>
                {{ $t('map.upload.cancel') }}
            </ion-button>
            <ion-button @click="acceptUpload" color="success">
                <ion-icon slot="start" :icon="icon_check"></ion-icon>
                {{ $t('map.upload.apply') }}
            </ion-button>
        </div>
    </ion-modal>
</template>

<script>
// Storage
import ApiClient from "@/api/client";

/* Moment.js */
// import * as moment from 'moment';

// Ionic components
import { IonItem, IonButton, IonButtons, IonIcon, IonHeader, IonTitle, IonLabel, IonModal, IonToolbar, IonAccordion, IonList, IonInput, IonCheckbox, IonListHeader, IonAccordionGroup, IonSpinner } from '@ionic/vue';
import { close, checkmarkOutline } from 'ionicons/icons';
import { Toast } from '@capacitor/toast';

// Other components
import * as $ from "jquery";

export default {
    name: 'UploadSelect',
    components: {
        IonItem,
        IonButton,
        IonButtons,
        IonIcon,
        IonHeader,
        IonTitle,
        IonLabel,
        IonModal,
        IonToolbar,
        IonAccordion,
        IonList,
        IonInput,
        IonCheckbox,
        IonListHeader,
        IonAccordionGroup,
        IonSpinner,
    },
    data() {
        return {
            isLoading: true,
            formdata: {
                terms: false,
                name: null,
            },
            upload_modal_open: false,
            finished: false,

            icon_close: close,
            icon_check: checkmarkOutline,
        }
    },
    props: {
        active: Boolean,
        element_type: String,
        feature: Object,
    },
    watch: {
        active(val) {
            if(val) {
                this.openUpload();
            } else {
                this.cancelUpload();
            }
        }
    },
    methods: {
        openUpload() {
            this.finished = false;
            this.formdata = {
                terms: false,
                name: null,
            };
            this.upload_modal_open = true;
            this.isLoading = false;
        },
        acceptUpload() {
            if(!this.formdata.terms) {
                Toast.show({
                    text: this.$t('map.upload.terms.error'),
                });
                return;
            }
            this.isLoading = true;

            let sendData = new FormData();
            sendData.append('name', this.formdata.name);
            sendData.append('element_type', this.element_type);
            sendData.append('element_id', this.feature.id);

            // let count = 0;
            for(let filedata of $('#upload_image_input input')[0].files) {
                // sendData.append('images['+count+']', filedata);
                // count++;
                sendData.append('image', filedata);
                break;
            }

            ApiClient.uploadImage(sendData).then(response => {
                if(response.status != 'success') {
                    Toast.show({
                        text: response.message,
                    });
                } else {
                    this.finishUpload();
                }
                this.isLoading = false;
            }).catch(error => {
                Toast.show({
                    text: error,
                });
                this.isLoading = false;
            });
        },
        cancelUpload() {
            if(this.isLoading) {
                return;
            }
            this.finished = false;
            this.upload_modal_open = false;
            this.formdata = {
                terms: false,
                name: null,
            };
            this.$emit('onFinish', false);
        },
        finishUpload() {
            this.formdata = {
                terms: false,
                name: null,
            };
            this.finished = true;
        },
        successUpload() {
            this.upload_modal_open = false;
            this.$emit('onFinish', true);
        },
        getItemName(names) {
            if(!names[this.$getLocale()]) {
                return names[Object.keys(names)[0]];
            }
            return names[this.$getLocale()];
        },
    },
}
</script>

<style>
.upload-modal {
    margin: auto;
}
ion-modal.upload-modal::part(content) {
    max-width: 400px;
}
ion-modal ion-label, ion-modal ion-title {
    color: black;
}
ion-modal .spinner-container {
    width: 100%;
    display: inline-block;
}
</style>