<template>
    <div class="filters-container">
        <ion-row class="ion-row-fixed">
            <ion-col size-xs="6" class="ion-text-center">
                <ion-button @click="show_filters=false" color="secondary">
                    <ion-icon slot="start" :icon="icon_list"></ion-icon>
                    {{ $t('map.filters.categories.title') }}
                </ion-button>
            </ion-col>
            <ion-col size-xs="6" class="ion-text-center">
                <ion-button @click="show_filters=true" color="secondary">
                    <ion-icon slot="start" :icon="icon_filter"></ion-icon>
                    {{ $t('map.filters.profile_fields.title') }}
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-content class="ion-content-filters">
            <keep-alive>
                <ion-accordion-group v-if="!show_filters" multiple="true" :value="default_categories_accordion">
                    <template v-for="parent in tree_categories" :key="parent.id">
                        <ion-accordion toggle-icon-slot="start" :value="parent.id">
                            <ion-item slot="header" >
                                <ion-avatar slot="start" class="ion-avatar-category-icon">
                                    <category-img :category="parent"></category-img>
                                </ion-avatar>
                                <ion-label>{{ getItemName(parent.name) }}</ion-label>
                                <!-- In parent is !$event.target.checked because the
                                    click event is in the checkbox, not the item -->
                                <ion-checkbox
                                    color="secondary"
                                    slot="end"
                                    @click.stop="parentCategoryToggle(parent.id, !$event.target.checked)"
                                    :checked="this.selected_categories[parent.id]"
                                    ></ion-checkbox>
                            </ion-item>

                            <ion-list slot="content">
                                <template v-for="category in parent.children" :key="category.id">
                                    <ion-item class="accordion-subitem"
                                        @click.stop="categoryToggle(category.id, $event.target.checked)">
                                        <ion-avatar slot="start" class="ion-avatar-category-icon">
                                            <category-img :category="category"></category-img>
                                        </ion-avatar>
                                        <ion-label>{{ getItemName(category.name) }}</ion-label>
                                        <ion-checkbox
                                            color="secondary"
                                            slot="end"
                                            :checked="this.selected_categories[category.id]"
                                            ></ion-checkbox>
                                    </ion-item>
                                </template>
                            </ion-list>
                        </ion-accordion>
                    </template>
                </ion-accordion-group>
                <ion-list v-else>
                    <template v-for="field in extra_filters" :key="field.id">
                        <component
                            :is="'field-filter-'+field.function"
                            :ref="refisterFieldRef"
                            :field="field"
                            v-model="filters[field.slug]"
                            :profile_values="profile_values"
                        ></component>
                    </template>
                    <template v-for="field in profile_fields" :key="field.id">
                        <component
                            :is="'field-filter-'+field.function"
                            :ref="refisterFieldRef"
                            :field="field"
                            v-model="filters[field.id]"
                            :profile_values="profile_values"
                        ></component>
                    </template>
                </ion-list>
            </keep-alive>
        </ion-content>
        <ion-row class="ion-row-fixed">
            <ion-col size-xs="6" class="ion-text-center">
                <ion-button @click="clearFilters" color="warning">
                    <ion-icon slot="start" :icon="icon_close"></ion-icon>
                    {{ $t('map.filters.clear') }}
                </ion-button>
            </ion-col>
            <ion-col size-xs="6" class="ion-text-center">
                <ion-button @click="applyFilters" color="success">
                    <ion-icon slot="start" :icon="icon_check"></ion-icon>
                    {{ $t('map.filters.apply') }}
                </ion-button>
            </ion-col>
        </ion-row>
    </div>
</template>

<script>
// Storage
import ApiStorage from "@/api/storage";

// Ionic components
import { IonContent, IonCol, IonRow, IonButton, IonIcon, IonList, IonItem, IonLabel, IonAvatar, IonListHeader, IonAccordion, IonAccordionGroup, IonCheckbox } from '@ionic/vue';
import { closeSharp, checkmarkCircleSharp, list, filterSharp } from 'ionicons/icons';

// Filter components
import FieldFilterString from '@/components/filters/FieldFilterString.vue'
import FieldFilterText from '@/components/filters/FieldFilterText.vue'
import FieldFilterUrl from '@/components/filters/FieldFilterUrl.vue'
import FieldFilterSelect from '@/components/filters/FieldFilterSelect.vue'
import FieldFilterMultiple from '@/components/filters/FieldFilterMultiple.vue'
import FieldFilterNumber from '@/components/filters/FieldFilterNumber.vue'
import FieldFilterDate from '@/components/filters/FieldFilterDate.vue'
import FieldFilterScript from '@/components/filters/FieldFilterScript.vue'
import FieldFilterTags from '@/components/filters/FieldFilterTags.vue'
import FieldFilterDuration from '@/components/filters/FieldFilterDuration.vue'
import GCaminsCategoryImg from '@/components/GCaminsCategoryImg.vue';

export default {
    name: 'Filters',
    components: {
        IonContent,
        IonList,
        IonItem,
        IonLabel,
        IonAvatar,
        IonCol,
        IonRow,
        IonButton,
        IonIcon,
        IonListHeader,
        IonAccordion,
        IonAccordionGroup,
        IonCheckbox,
        'field-filter-string': FieldFilterString,
        'field-filter-text': FieldFilterText,
        'field-filter-url': FieldFilterUrl,
        'field-filter-select': FieldFilterSelect,
        'field-filter-multiple': FieldFilterMultiple,
        'field-filter-number': FieldFilterNumber,
        'field-filter-date': FieldFilterDate,
        'field-filter-script': FieldFilterScript,
        'field-filter-tags': FieldFilterTags,
        'field-filter-duration': FieldFilterDuration,
        'category-img': GCaminsCategoryImg,
    },
    props: {
        model: {
            type: [String],
            default: () => '',
        },
        categories: {
            type: [Object],
            default: () => {},
        },
        extra_filters: {
            type: [Object],
            default: () => {},
        },
        profile_values: {
            type: [Object],
            default: () => {},
        },
    },
    data() {
        return  {
            show_filters: false,
            profile_fields: [],
            filters: {},
            tree_categories: [],
            selected_categories: {},
            default_categories_accordion: [],

            icon_close: closeSharp,
            icon_check: checkmarkCircleSharp,
            icon_list: list,
            icon_filter: filterSharp,

            fieldRefs: [],
        }
    },
    watch: {
        categories() {
            this.setCategories();
        }
    },
    async mounted() {
        this.profile_fields = await ApiStorage.getAll('profile_fields').then(profile_fields => {
            return profile_fields.filter(f => {
                return f.type == this.model;
            }).sort((a,b) => {
                return a.position > b.position;
            });
        });

        this.setCategories();

        // Open accordion on start if only one
        if(this.tree_categories.length == 1) {
            // Shall be string for some reason
            this.default_categories_accordion.push(String(this.tree_categories[0].id));
        }
    },
    beforeUpdate() {
        this.fieldRefs = []
    },
    methods: {
        refisterFieldRef(el) {
            if(el && !this.fieldRefs.includes(el)) {
                this.fieldRefs.push(el);
            }
        },
        setCategories() {
            let heading = [];
            this.tree_categories = [];
            for(let cat_id of Object.keys(this.categories)) {
                if(!this.categories[cat_id].parent_id) {
                    heading.push(this.categories[cat_id]);
                }
                this.selected_categories[cat_id] = this.categories[ cat_id ].is_visible[this.$getLocale()];
            }
            for(const hcat of heading) {
                let children = [];
                for(let cat_id of Object.keys(this.categories)) {
                    if(this.categories[cat_id].parent_id == hcat.id) {
                        children.push(this.categories[cat_id]);
                    }
                }
                hcat.children = children;
                this.tree_categories.push(hcat);
            }
        },
        applyFilters(e) {
            let functions = [
                this._getCategoryFilterFunction()
            ];
            for(let index in this.fieldRefs) {
                const fnObj = this.fieldRefs[index].getFilterFunction();
                if(fnObj) {
                    // Add +1 to the index as the category filter is
                    // using the first one.
                    functions[ parseInt(index)+1 ] = fnObj;
                }
            }
            this.$emit('filter', functions);
            e.stopPropagation();
        },
        clearFilters() {
            for(let filter of this.fieldRefs) {
                filter.clear();
            }
            // this.setCategories();
        },
        _getCategoryFilterFunction() {
            return {
                data: this.selected_categories,
                run: function(element) {
                    if (!('categories' in element)) {
                        return false;
                    }
                    for (var cat_id of element.categories) {
                        if(cat_id!=null && this.data[ cat_id ]) {
                            return true;
                        }
                    }
                    return false;
                }
            };
        },
        getItemName(names) {
            if(!names[this.$getLocale()]) {
                return names[Object.keys(names)[0]];
            }
            return names[this.$getLocale()];
        },
        parentCategoryToggle(category_id, active) {
            let categorizables = [];
            for(let cat_id in this.categories) {
                let cat = this.categories[cat_id];
                if(cat.parent_id == category_id) {
                    this.selected_categories[cat.id] = active;
                    if(!categorizables.includes(cat.type)) {
                        categorizables.push(cat.type);
                    }
                }
            }
        },
        categoryToggle(category_id, active) {
            let categorizables = [];
            for(let cat_id in this.categories) {
                let cat = this.categories[cat_id];
                if(cat.id == category_id) {
                    if(this.selected_categories[cat.id] != active) {
                        this.selected_categories[cat.id] = active;
                        if(!categorizables.includes(cat.type)) {
                            categorizables.push(cat.type);
                        }
                    }
                }
            }
        },
    }
}
</script>

<style>
.filters-container {
    flex: 99;
    display: flex;
    flex-direction: column;
}
.ion-row-fixed {
    flex: 1;
}
.ion-content-filters {
    flex: 10;
    min-height: 100px;
}
.accordion-subitem {
    padding-left: 20px;
}
</style>