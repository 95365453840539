<template>
    <ion-modal
        class="export-modal"
        :is-open="export_modal_open"
        :can-dismiss="true"
        :presenting-element="$parent.$refs.ionRouterOutlet"
        :show-backdrop="true"
        @did-dismiss="cancelExport">
        <ion-header>
            <ion-toolbar>
                <ion-title>{{ $t('map.exports.prompt_title') }}</ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="cancelExport"  :title="$t('utils_info.cancel_export')"><ion-icon  :icon="icon_close"></ion-icon></ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <!-- <ion-radio-group :value="export_select_lang" @ion-change="export_select_lang = $event.currentTarget.value">
                <ion-list-header>
                    <ion-label>{{ $t('map.exports.select_language') }}</ion-label>
                </ion-list-header>

                <template v-for="lang in languages"  v-bind:key="lang.code">
                    <ion-item>
                        <ion-label>{{ lang.name }}</ion-label>
                        <ion-radio
                        color="secondary"
                        slot="end"
                        :value="lang.code"></ion-radio>
                    </ion-item>
                </template>
            </ion-radio-group> -->
            
            <ion-radio-group :value="export_select_format" @ion-change="export_select_format = $event.currentTarget.value">
                <ion-list-header>
                    <ion-label>{{ $t('map.exports.select_format') }}</ion-label>
                </ion-list-header>

                <template v-for="(exportfn,index) in export_functions"  v-bind:key="index">
                    <ion-item>
                        <ion-label>{{ exportfn.text }}</ion-label>
                        <ion-radio
                        color="secondary"
                        slot="end"
                        :value="index"></ion-radio>
                    </ion-item>
                </template>
            </ion-radio-group>

            <ion-button @click="cancelExport" color="danger" :title="$t('utils_info.cancel_export')">
                <ion-icon slot="start" :icon="icon_close"></ion-icon>
                {{ $t('map.exports.cancel') }}
            </ion-button>
            <ion-button @click="acceptExport" color="success"  :title="$t('utils_info.accept_export')">
                <ion-icon slot="start" :icon="icon_check"></ion-icon>
                {{ $t('map.exports.apply') }}
            </ion-button>
        </ion-content>
    </ion-modal>
</template>

<script>
// Storage
// import ApiStorage from "@/api/storage";
import ApiClient from "@/api/client";

/* Moment.js */
import * as moment from 'moment';

// Ionic components
import { IonContent, IonItem, IonButton, IonButtons, IonIcon, IonHeader, IonTitle, IonLabel, IonRadioGroup, IonRadio, IonListHeader, IonModal, IonToolbar } from '@ionic/vue';
import { close, checkmarkOutline } from 'ionicons/icons';

// Other components
// import LoadingScreen from '@/components/LoadingScreen.vue'

export default {
    name: 'ExportSelect',
    components: {
        IonContent,
        IonItem,
        IonButton,
        IonButtons,
        IonIcon,
        IonHeader,
        IonTitle,
        IonLabel,
        IonRadioGroup,
        IonRadio,
        IonListHeader,
        IonModal,
        IonToolbar,
        // LoadingScreen,
    },
    data() {
        return {
            isLoading: true,
            
            // languages: [],
            export_select_lang: this.$getLocale(),
            export_select_format: 0,
            export_modal_open: false,

            icon_close: close,
            icon_check: checkmarkOutline,
        }
    },
    props: {
        active: null,
        export_functions: null,
        resource: null,
        resource_params: null,
        // feature: null,
    },
    watch: {
        active(val) {
            if(val) {
                this.openExport();
            } else {
                this.cancelExport();
            }
        }
    },
    methods: {
        async openExport() {
            // if(this.languages.length) {
            //     this.export_modal_open = true;
            //     return;
            // }
            // this.languages = await ApiStorage.getAll('languages').then(result => result);
            this.export_modal_open = true;
        },
        acceptExport() {
            this.export_modal_open = false;
            let format;
            let lang = this.export_select_lang;
            let filename;
            switch (this.export_select_format) {
                case 0:
                    format = 'geojson';
                    filename = 'inruting.'+this.resource+'.' + moment().format('YYYY-MM-DD HH:mm:ss') + '.json';
                    break;
                case 1:
                    format = 'gpx';
                    filename = 'inruting.'+this.resource+'.' + moment().format('YYYY-MM-DD HH:mm:ss') + '.gpx';
                    break;
                case 2:
                    format = 'kml';
                    filename = 'inruting.'+this.resource+'.' + moment().format('YYYY-MM-DD HH:mm:ss') + '.kml';
                    break;
                case 3:
                    format = 'shp';
                    filename = 'inruting.'+this.resource+'.' + moment().format('YYYY-MM-DD HH:mm:ss') + '.zip';
                    break;
            }
            ApiClient.export(this.resource, format, lang, this.resource_params, filename);
            this.$emit('onFinish', true);
        },
        cancelExport() {
            this.export_modal_open = false;
            this.$emit('onFinish', false);
        },
    },
}
</script>

<style>
.export-modal {
    margin: auto;
}
ion-modal.export-modal::part(content) {
    max-width: 400px;
    max-height: 80%;
    --height: 80%;
    --min-width: 400px;
    --max-height: 80%; 
    --max-width: 400px;
    display: flex;
    flex-direction: column;
}
ion-modal.export-modal ion-content {
    flex: 1;
}
</style>