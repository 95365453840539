<template>
    <!-- TODO: Set min-max range -->
    <ion-item>
        <ion-label position="stacked">{{ field.name[$getLocale()] }}</ion-label>
        <ion-row style="width: 100%;">
            <ion-col size-xs="5">
                <ion-label position="floating">{{ $t('map.filters.number.min') }}</ion-label>
                <ion-input
                    type="number"
                    inputmode="numeric"
                    v-model="min"
                    @ion-input="handleInputMin"></ion-input>
            </ion-col>
            <ion-col size-xs="5">
                <ion-label position="floating">{{ $t('map.filters.number.max') }}</ion-label>
                <ion-input
                    type="number"
                    inputmode="numeric"
                    v-model="max"
                    @ion-input="handleInputMax"></ion-input>
            </ion-col>
            <ion-col size-xs="2">
                <ion-button @click="clear()">
                    <ion-icon slot="icon-only" :icon="icon_close"></ion-icon>
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-item>
</template>

<script>
// Ionic components
import { IonItem, IonCol, IonRow, IonInput, IonLabel, IonButton, IonIcon } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';
import { isEqual } from 'lodash';

export default {
    name: 'FieldFilterNumber',
    components: {
        IonItem,
        IonCol,
        IonRow,
        IonInput,
        IonLabel,
        IonButton,
        IonIcon,
    },
    props:{
        field: Object,
        value: { // TODO: set min-max or initial value
            type: Array,
            default: () => { return [0, 0]; },
        },
        registerFilter: Function,
    },
    data() {
        return {
            content: this.value ?? [0, 0],
            min: 0,
            max: 0,
            icon_close: closeOutline,
        }
    },
    mounted() {
        if(this.registerFilter) this.registerFilter(this);
    },
    methods: {
      handleInputMin(event) {
          this.content[0] = parseFloat(event.detail.target.value);
          this.handleInput();
      },
      handleInputMax(event) {
          this.content[1] = parseFloat(event.detail.target.value);
          this.handleInput();
      },
      handleInput() {
          this.$emit('input', this.content);
      },
      getFilterFunction() {
        if(isEqual(this.content, this.getInitialValue())) {
            return { field: this.field.slug, data: {}, run: function() { return true } };
        }
        return {
          data: this.content,
          field: this.field.slug,
          run: function(element) {
              if(!element || !element.profile_field_values || !element.profile_field_values[this.field]) {
                  return false;
              }
              const val = parseFloat(element.profile_field_values[this.field]);
              if (val > this.data[0]
                  && val < this.data[1]) {
                  return true;
              }
              return false;
          }
        };
      },
      clear() {
          this.min = 0;
          this.max = 0;
          this.content = this.getInitialValue();
          this.handleInput();
      },
      getInitialValue() {
         return [0, 0];
      },
    },
};
</script>

<style>
</style>
