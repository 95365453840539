<template>
    <div class="map_container">
        <div ref="map_container" class="map_frame">
            <l-map
                @ready="init"
                :use-global-leaflet="true"
                :zoom-animation="true"
                ref="map"
                style="position: inherit; width: 100%; height: 100%"
                :options="mapOptions"
                :zoom="zoom"
            >
                <l-control-scale position="bottomleft"></l-control-scale>
                <template v-for="tileProvider in baseLayers">
                    <l-wms-tile-layer
                        v-if="tileProvider.type == 'wms'"
                        :key="tileProvider.name"
                        :name="tileProvider.name"
                        :base-url="tileProvider.url"
                        :layers="tileProvider.layer_list"
                        :transparent="false"
                        :opacity="1"
                        :attribution="tileProvider.attribution"
                        :visible="tileProvider.visible"
                        layer-type="base"
                        format="image/jpeg"
                    >
                    </l-wms-tile-layer>
                    <l-tile-layer
                        v-else
                        :key="tileProvider.name"
                        :name="tileProvider.name"
                        :visible="tileProvider.visible"
                        :url="tileProvider.url"
                        :attribution="tileProvider.attribution"
                        layer-type="base"
                    >
                    </l-tile-layer>
                </template>
                <gcamins-tile-layer
                    v-for="layer in tilelayers"
                    :key="layer.id"
                    :tilelayer="layer"
                    :visible="selected_tilelayers[layer.id]"
                >
                </gcamins-tile-layer>
                <template v-if="Object.keys(categorizables).length > 0">
                    <l-pointlayer
                        :mapObject="map"
                        :active="selected_vectorlayers"
                        :categories_list="categories_list"
                        :filter_functions="
                            filter_functions['Modules\\Points\\Entities\\Point']
                        "
                        :current_tab="current_tab"
                        @vector_layers_loaded="prepareVectorLayers"
                        @featureClick="featureClick"
                        @vectorlayer="vectorlayersSelected"
                    ></l-pointlayer>
                    <l-polylinelayer
                        :mapObject="map"
                        :active="selected_vectorlayers"
                        :categories_list="categories_list"
                        :filter_functions="
                            filter_functions[
                                'Modules\\Polylines\\Entities\\Polyline'
                            ]
                        "
                        :current_tab="current_tab"
                        @vector_layers_loaded="prepareVectorLayers"
                        @featureClick="featureClick"
                    ></l-polylinelayer>
                    <l-arclinelayer
                        :mapObject="map"
                        :active="selected_vectorlayers"
                        :categories_list="categories_list"
                        :filter_functions="
                            filter_functions[
                                'Modules\\Lattice\\Entities\\Arcline'
                            ]
                        "
                        :current_tab="current_tab"
                        @vector_layers_loaded="prepareVectorLayers"
                        @featureClick="featureClick"
                    ></l-arclinelayer>
                    <l-incidentlayer
                        :mapObject="map"
                        :active="selected_vectorlayers"
                        :categories_list="categories_list"
                        :filter_functions="
                            filter_functions[
                                'Modules\\Incidents\\Entities\\Incident'
                            ]
                        "
                        :current_tab="current_tab"
                        @vector_layers_loaded="prepareVectorLayers"
                        @featureClick="featureClick"
                        @vectorlayer="vectorlayersSelected"
                    ></l-incidentlayer>
                </template>
                <slot></slot>
            </l-map>
            <div
                class="leaflet-sidebar collapsed"
                ref="sidebar_container"
                id="sidebar_container"
            >
                <!-- Nav tabs -->
                <div class="leaflet-sidebar-tabs">
                    <!-- top aligned tabs -->
                    <ul role="tablist">
                        <li
                            :class="
                                selected_feature && selected_feature.id != 0
                                    ? ''
                                    : 'disabled'
                            "
                        >
                            <a
                                href="#sidebar_info"
                                role="tab"
                                :title="$t('map.info.header')"
                            >
                                <ion-icon
                                    :icon="icon_information"
                                    slot="start"
                                ></ion-icon>
                            </a>
                        </li>
                        <li>
                            <a
                                href="#sidebar_search"
                                role="tab"
                                :title="$t('map.sidebar.search.header')"
                            >
                                <ion-icon
                                    :icon="icon_search"
                                    slot="start"
                                ></ion-icon>
                            </a>
                        </li>
                        <li>
                            <a
                                href="#sidebar_points"
                                role="tab"
                                :title="$t('map.sidebar.points.header')"
                            >
                                <ion-icon
                                    :icon="icon_point"
                                    slot="start"
                                ></ion-icon>
                            </a>
                        </li>
                        <li>
                            <a
                                href="#sidebar_polylines"
                                role="tab"
                                :title="$t('map.sidebar.polylines.header')"
                            >
                                <ion-icon
                                    :icon="icon_polyline"
                                    slot="start"
                                ></ion-icon>
                            </a>
                        </li>
                        <li>
                            <a
                                href="#sidebar_lattice"
                                role="tab"
                                :title="$t('map.sidebar.lattice.header')"
                            >
                                <ion-icon
                                    :icon="icon_lattice"
                                    slot="start"
                                ></ion-icon>
                            </a>
                        </li>
                        <li>
                            <a
                                href="#sidebar_layers"
                                role="tab"
                                :title="$t('map.sidebar.layers.header')"
                            >
                                <ion-icon
                                    :icon="icon_layers"
                                    slot="start"
                                ></ion-icon>
                            </a>
                        </li>
                        <li>
                            <a
                                href="#sidebar_incidents"
                                role="tab"
                                :title="$t('map.sidebar.incidents.header')"
                            >
                                <ion-icon
                                    :icon="icon_warning"
                                    slot="start"
                                ></ion-icon>
                            </a>
                        </li>
                    </ul>

                    <!-- bottom aligned tabs -->
                    <ul role="tablist" v-if="$isBrowser()">
                        <!-- <li><a @click.stop="toggleViewmode">
                            <vs-tooltip :text="trans('map.viewmode')" position="right">
                                <i class="fa" :class="viewmode == 'cards'?'fa-th-list':'fa-th'"></i>
                            </vs-tooltip>
                        </a></li> -->
                        <li>
                            <a
                                href="#sidebar_embed"
                                role="tab"
                                :title="$t('map.embed.header')"
                            >
                                <ion-icon
                                    :icon="icon_embed"
                                    slot="start"
                                ></ion-icon>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- Tab panes -->
                <div class="leaflet-sidebar-content">
                    <div class="leaflet-sidebar-pane" id="sidebar_info">
                        <h2 class="leaflet-sidebar-header">
                            {{ this.map_info_header }}
                            <div class="leaflet-sidebar-close">
                                <ion-img
                                    class="leaflet-sidebar-close-icon"
                                    src="assets/img/ICO-CIERRE-PANEL.svg"
                                ></ion-img>
                            </div>
                        </h2>
                        <point-info
                            :selected_feature="selected_feature"
                            :leafletObject="map"
                            :prevent_focus="prevent_focus"
                            @set-as-start="onSetAsStart"
                            @set-as-end="onSetAsEnd"
                            @header_change="onHeaderChange"
                            @featureListClick="featureListClick"
                        ></point-info>
                        <polyline-info
                            :selected_feature="selected_feature"
                            :leafletObject="map"
                            :prevent_focus="prevent_focus"
                            @header_change="onHeaderChange"
                            @featureListClick="featureListClick"
                        ></polyline-info>
                        <arcline-info
                            :selected_feature="selected_feature"
                            :leafletObject="map"
                            :current_tab="current_tab"
                            :prevent_focus="prevent_focus"
                            @header_change="onHeaderChange"
                            @featureListClick="featureListClick"
                        ></arcline-info>
                        <incident-info
                            :selected_feature="selected_feature"
                            :leafletObject="map"
                            :prevent_focus="prevent_focus"
                            @set-as-start="onSetAsStart"
                            @set-as-end="onSetAsEnd"
                            @header_change="onHeaderChange"
                            @featureListClick="featureListClick"
                        ></incident-info>
                    </div>
                    <div class="leaflet-sidebar-pane" id="sidebar_search">
                        <h2 class="leaflet-sidebar-header">
                            {{ $t("map.sidebar.search.header") }}
                            <div class="leaflet-sidebar-close">
                                <ion-img
                                    class="leaflet-sidebar-close-icon"
                                    src="assets/img/ICO-CIERRE-PANEL.svg"
                                ></ion-img>
                            </div>
                        </h2>
                        <geocoding
                            :leafletObject="map"
                            :bounds="bounds"
                            @close-sidebar="onCloseSidebar"
                        ></geocoding>
                    </div>
                    <div class="leaflet-sidebar-pane" id="sidebar_points">
                        <h2 class="leaflet-sidebar-header">
                            {{ $t("map.sidebar.points.header") }}
                            <div class="leaflet-sidebar-close">
                                <ion-img
                                    class="leaflet-sidebar-close-icon"
                                    src="assets/img/ICO-CIERRE-PANEL.svg"
                                ></ion-img>
                            </div>
                        </h2>
                        <pointlist
                            v-if="categories.length > 0"
                            :all_categories="categories"
                            :ref="setPointListRef"
                            @featureListClick="featureListClick"
                            @filter="applyFilters"
                        ></pointlist>
                    </div>
                    <div class="leaflet-sidebar-pane" id="sidebar_polylines">
                        <h2 class="leaflet-sidebar-header">
                            {{ $t("map.sidebar.polylines.header") }}
                            <div class="leaflet-sidebar-close">
                                <ion-img
                                    class="leaflet-sidebar-close-icon"
                                    src="assets/img/ICO-CIERRE-PANEL.svg"
                                ></ion-img>
                            </div>
                        </h2>
                        <polylinelist
                            v-if="categories.length > 0"
                            :all_categories="categories"
                            :current_tab="current_tab"
                            @featureListClick="featureListClick"
                            @filter="applyFilters"
                            @vectorlayer="vectorlayersSelected"
                        ></polylinelist>
                    </div>
                    <div class="leaflet-sidebar-pane" id="sidebar_lattice">
                        <h2 class="leaflet-sidebar-header">
                            {{ $t("map.sidebar.lattice.header") }}
                            <div class="leaflet-sidebar-close">
                                <ion-img
                                    class="leaflet-sidebar-close-icon"
                                    src="assets/img/ICO-CIERRE-PANEL.svg"
                                ></ion-img>
                            </div>
                        </h2>
                        <lattice-select
                            v-if="categories.length > 0"
                            :all_categories="categories"
                            :ref="setLatticeSelectRef"
                            :locatecontrol="locatecontrol"
                            :leafletObject="map"
                            :current_tab="current_tab"
                            @close-sidebar="onCloseSidebar"
                            @open-sidebar="onOpenSidebar"
                            @featureListClick="featureListClick"
                            @filter="applyFilters"
                            @vectorlayer="vectorlayersSelected"
                        ></lattice-select>
                    </div>
                    <div class="leaflet-sidebar-pane" id="sidebar_layers">
                        <h2 class="leaflet-sidebar-header">
                            {{ $t("map.sidebar.layers.header") }}
                            <div class="leaflet-sidebar-close">
                                <ion-img
                                    class="leaflet-sidebar-close-icon"
                                    src="assets/img/ICO-CIERRE-PANEL.svg"
                                ></ion-img>
                            </div>
                        </h2>
                        <layers-select
                            :leafletObject="map"
                            :base-layers="baseLayers"
                            :tilelayers="tilelayers"
                            :vector_layers="vector_layers"
                            :selected_baselayer="selected_baselayer"
                            :selected_tilelayers="selected_tilelayers"
                            :selected_vectorlayers="selected_vectorlayers"
                            @base_layer="setBaseLayer"
                            @tilelayer="tilelayersSelected"
                            @vectorlayer="vectorlayersSelected"
                        ></layers-select>
                    </div>
                    <div class="leaflet-sidebar-pane" id="sidebar_incidents">
                        <h2 class="leaflet-sidebar-header">
                            {{ $t("map.sidebar.incidents.header") }}
                            <div class="leaflet-sidebar-close">
                                <ion-img
                                    class="leaflet-sidebar-close-icon"
                                    src="assets/img/ICO-CIERRE-PANEL.svg"
                                ></ion-img>
                            </div>
                        </h2>
                        <incidents-list
                            v-if="categories.length > 0"
                            :all_categories="categories"
                            :leafletObject="map"
                            :current_tab="current_tab"
                            @close-sidebar="onCloseSidebar"
                            @open-sidebar="onOpenSidebar"
                        ></incidents-list>
                    </div>
                    <div class="leaflet-sidebar-pane" id="sidebar_embed">
                        <h2 class="leaflet-sidebar-header">
                            {{ $t("map.embed.header") }}
                            <div class="leaflet-sidebar-close">
                                <ion-img
                                    class="leaflet-sidebar-close-icon"
                                    src="assets/img/ICO-CIERRE-PANEL.svg"
                                ></ion-img>
                            </div>
                        </h2>
                        <embed-config
                            :leafletObject="map"
                            :selected_feature="selected_feature"
                            :current_tab="current_tab"
                            :selected_baselayer="selected_baselayer"
                            :selected_tilelayers="selected_tilelayers"
                            :selected_vectorlayers="selected_vectorlayers"
                            @close-sidebar="onCloseSidebar"
                            @open-sidebar="onOpenSidebar"
                        ></embed-config>
                    </div>
                </div>
            </div>
        </div>
        <tracking-consent v-if="map" :map="map"></tracking-consent>
    </div>
</template>

<script>
// Storage
import ApiStorage from "@/api/storage";
import ApiClient from "@/api/client";
import ApiFilesystem from "@/api/filesystem";

// Utils and components
import * as $ from "jquery";

// Layer components
import GcaminsTileLayer from "@/components/GcaminsTileLayer.vue";
import PointLayer from "@/components/modules/PointLayer.vue";
import PolylineLayer from "@/components/modules/PolylineLayer.vue";
import ArclineLayer from "@/components/modules/ArclineLayer.vue";
import IncidentLayer from "@/components/modules/IncidentLayer.vue";

// List components
import PointList from "@/components/modules/PointList.vue";
import PolylineList from "@/components/modules/PolylineList.vue";
import LatticeSelect from "@/components/modules/LatticeSelect.vue";
import LayersSelect from "@/components/modules/LayersSelect.vue";
import IncidentsList from "@/components/modules/IncidentsList.vue";

// Info components
import PointInfo from "@/components/modules/PointInfo.vue";
import PolylineInfo from "@/components/modules/PolylineInfo.vue";
import ArclineInfo from "@/components/modules/ArclineInfo.vue";
import IncidentInfo from "@/components/modules/IncidentInfo.vue";

// FullScreen control
import screenfull from "screenfull";
import "leaflet.fullscreen/Control.FullScreen";
import "leaflet.fullscreen/Control.FullScreen.css";

// Loading control
import "leaflet-loading";
import "leaflet-loading/src/Control.Loading.css";

// Locate control
import "leaflet.locatecontrol";
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css";
import { Geolocation } from "@capacitor/geolocation";

// Geocoding component
import GcaminsGeocoding from "@/components/GcaminsGeocoding.vue";

// VisualClick
import "leaflet.visualclick/src/L.VisualClick.js";
import "leaflet.visualclick/src/L.VisualClick.css";

// Tracking consent modal
import TrackingConsent from "@/components/TrackingConsent.vue";

// Embed
import EmbedConfig from "@/components/EmbedConfig.vue";

// Ionic components
import { Toast } from "@capacitor/toast";
import { IonIcon, IonImg } from "@ionic/vue";
import {
    informationCircleOutline,
    locationSharp,
    close,
    analyticsSharp,
    addCircle,
    layersSharp,
    searchOutline,
    warningOutline,
    codeSlashOutline,
} from "ionicons/icons";

var tileProviders = [
    {
        type: "url",
        name: "OpenStreetMap",
        visible: true,
        attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    },
    {
        type: "url",
        name: "OpenTopoMap",
        visible: false,
        url: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
        attribution:
            'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
    },
    {
        type: "wms",
        name: "Topogràfic ICGC",
        visible: false,
        url: "https://geoserveis.icgc.cat/icc_mapesmultibase/utm/wms/service",
        attribution:
            '&copy; <a href="https://www.icgc.cat/">Institut Cartogràfic i Geològic de Catalunya</a>',
        layer_list: "topo",
    },
    {
        type: "wms",
        name: "Mapa Base (IGN)",
        visible: false,
        url: "https://www.ign.es/wms-inspire/ign-base",
        attribution:
            '&copy; <a href="https://www.ign.es">Infraestructura de Datos Espaciales de España</a> (CC BY 4.0 scne.es)',
        layer_list: "IGNBaseTodo",
    },
    {
        type: "wms",
        name: "Ortofoto ICGC",
        visible: false,
        url: "https://geoserveis.icgc.cat/icc_mapesbase/wms/service",
        attribution:
            '&copy; <a href="https://www.icgc.cat/">Institut Cartogràfic i Geològic de Catalunya</a>',
        layer_list: "orto25c",
    },
];

export default {
    name: "GCaminsMap",
    inheritAttrs: false,
    components: {
        GcaminsTileLayer,
        IonIcon,
        IonImg,
        "l-pointlayer": PointLayer,
        pointlist: PointList,
        "l-polylinelayer": PolylineLayer,
        polylinelist: PolylineList,
        "l-arclinelayer": ArclineLayer,
        "l-incidentlayer": IncidentLayer,
        "lattice-select": LatticeSelect,
        "layers-select": LayersSelect,
        "point-info": PointInfo,
        "polyline-info": PolylineInfo,
        "arcline-info": ArclineInfo,
        "incident-info": IncidentInfo,
        geocoding: GcaminsGeocoding,
        "incidents-list": IncidentsList,
        "tracking-consent": TrackingConsent,
        "embed-config": EmbedConfig,
    },
    props: {
        bounds: {
            type: [Object, Array],
            custom: true,
            default: () => undefined,
        },
        feature_param: {
            type: Object,
            required: false,
        },
        map_status: {
            type: Object,
            required: false,
        },
        sidebar_tab: {
            type: String,
            required: false,
        },
        start_location: {
            type: Number,
            required: false,
        },
    },
    data: () => ({
        map: null,
        last_bounds: null,
        editable: false,
        // opacity: 0.6,
        mapOptions: {
            attributionControl: true,
            // zoomSnap: 0.5,
            tapTolerance: 10,
        },
        zoom: 10,
        minZoom: 4,
        maxZoom: 20,
        locatecontrol: null,
        last_location: null,
        // viewmode: 'cards',
        baseLayers: tileProviders,
        selected_baselayer: 0,
        // loading_threads: 0,
        categorizables: {},

        markers_map: {},
        polylines_map: {},
        categories: [],
        categories_list: {},
        categories_loaded: false,
        categories_highlighted: [],
        selected_categories: {},
        features_highlighted: {},
        lattice_select: null,
        point_list: null,
        prevent_focus: false,

        selected_feature: {
            type: "",
            id: 0,
            name: "",
            info: {},
        },

        tilelayers_groups: [],
        tilelayers: [],
        selected_tilelayers: {},

        vector_layers: [],
        selected_vectorlayers: {},
        feature_collections: {},
        tooltip: null,
        sidebar: null,
        current_tab: null,
        map_info_header: "",

        featurefilter: {},
        categoryfilter: "",
        overlayfilter: "",
        filters: {},
        filter_functions: {},

        icon_information: informationCircleOutline,
        icon_point: locationSharp,
        icon_polyline: analyticsSharp,
        icon_lattice: addCircle,
        icon_close: close,
        icon_layers: layersSharp,
        icon_search: searchOutline,
        icon_warning: warningOutline,
        icon_embed: codeSlashOutline,
    }),
    watch: {
        selected_baselayer(val) {
            for (let i in this.baseLayers) {
                this.baseLayers[i].visible = false;
            }
            this.baseLayers[val].visible = true;
        },
        feature_param(val) {
            if (val) {
                this.featureFromUrl(val);
            }
        },
        map_status(val) {
            if (val) {
                this.mapStatusFromUrl(val);
            }
        },
        sidebar_tab() {
            this.setSidebarTab();
        },
        start_location(val) {
            if (val) {
                this.startWithLocation();
            }
        },
    },
    async mounted() {
        this.map_info_header = this.$t("map.info.header");
        // Fix map size, I wish there was a css solution
        $(window).on("resize", () => {
            this.triggerResize();
        });

        if (!this.$checkConnection() && !this.$isBrowser()) {
            /**
             * Offline tiles if saved previouly.
             * @see @/views/OfflineMode.vue
             */
            const firstTile = await ApiFilesystem.stat(
                "cartography/baselayer/0/0/0.png"
            );
            if (firstTile) {
                var offlineProviders = [
                    {
                        name: "OpenStreetMap",
                        visible: true,
                        attribution:
                            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                        url: ApiFilesystem.convertFileSrc(
                            firstTile.uri.replace("0/0/0", "{z}/{x}/{y}")
                        ),
                        maxZoom: 15,
                    },
                ];
                this.baseLayers = offlineProviders;
            }
        }

        this.selected_baselayer = 0;
    },
    methods: {
        triggerResize(tried) {
            // Don't trigger resize if we're on another page
            if (this.$route.name != "map" && this.$route.name != "embed") {
                return;
            }
            if (this.map && this.map._loaded) {
                try {
                    this.map.invalidateSize(true);
                } catch (error) {
                    // It is sometimes not really ready
                    this.$nextTick(() => {
                        this.triggerResize();
                    });
                }
                this.$nextTick(() => {
                    // If no size, don't fitBounds, will hang
                    if (
                        this.map.getSize().x > 0 &&
                        this.map.getZoom() < 3 &&
                        this.last_bounds
                    ) {
                        // Map messes itself, fit last bounds
                        this.map.fitBounds(this.last_bounds, {
                            animate: false,
                        });
                    }
                });
            } else if (!tried) {
                // try again once
                this.$nextTick(() => {
                    this.triggerResize(true);
                });
            }
        },
        init() {
            if (this.map) {
                // Already initialized
                return false;
            }
            this.map = this.$refs.map.leafletObject;
            if (screenfull && screenfull.isEnabled) {
                this.map.addControl(
                    new window.L.Control.FullScreen(/*{  position: 'topright' }*/)
                );
            }
            this.map.addControl(
                new window.L.Control.Loading({ position: "topleft" })
            );
            this.map.on("moveend", (event) => {
                // Bounds on a bad-sized map can be incorrect, so
                // a lot of checks are needed.
                if (
                    event.target.getBounds() &&
                    event.target.getBounds().isValid() &&
                    event.target.getBounds().contains(this.map.getCenter()) &&
                    this.map.getZoom() > 3 &&
                    this.map.getBoundsZoom(event.target.getBounds()) !=
                        Infinity &&
                    this.map.getBoundsZoom(event.target.getBounds()) > 3
                ) {
                    this.last_bounds = event.target.getBounds();
                }
            });
            this.initLocationControl();
            this.initVisualClickControl();

            this.$nextTick(() => {
                this.loadMapData(); // Load data
                this.triggerResize(); // resize
                if (this.bounds) {
                    this.map.fitBounds(this.bounds, {
                        animate: false,
                    });
                    this.last_bounds = this.bounds;
                }
                this.initSidebar();
            });
        },
        initSidebar() {
            this.sidebar = window.L.control
                .sidebar({
                    autopan: true, // whether to maintain the centered map point when opening the sidebar
                    closeButton: true, // whether t add a close button to the panes
                    container: "sidebar_container", // the DOM container or #ID of a predefined sidebar container that should be used
                    position: "right", // left or right
                })
                .addTo(this.map);

            this.sidebar.on("content", (event) => {
                const newHash = "#" + event.id;
                // Don't push the same hash twice and don't push if
                // we're coming from another page throught feature_param.
                if (this.$route.hash != newHash && !this.feature_param) {
                    let newRoute = {
                        path: this.$route.path,
                        hash: newHash,
                    };
                    this.$router.push(newRoute);
                }
                this.current_tab = event.id;
            });

            this.sidebar.on("closing", () => {
                this.$router.push({
                    path: this.$route.path,
                    hash: "",
                });
                this.current_tab = "";
            });

            this.setSidebarTab();
            if (this.start_location) {
                this.startWithLocation();
            }
        },
        onCloseSidebar() {
            this.sidebar.close();
        },
        onOpenSidebar(tab) {
            this.sidebar.open(tab);
        },
        setSidebarTab() {
            if (
                (this.sidebar_tab || this.sidebar_tab == "") &&
                this.sidebar_tab != this.current_tab
            ) {
                // Check if there is something selected if showing info,
                // otherwise is disabled and shall not show.
                if (
                    this.sidebar_tab == "sidebar_info" &&
                    !this.selected_feature
                ) {
                    return;
                }
                this.$nextTick(() => {
                    if (this.sidebar_tab == "") {
                        this.sidebar.close();
                    } else {
                        this.sidebar.open(this.sidebar_tab);
                    }
                });
            }
        },
        initLocationControl() {
            this.locatecontrol = new window.L.Control.Locate({
                position: "topleft",
                keepCurrentZoomLevel: true,
                showPopup: false,
                icon: "leaflet-control-locate-location-arrow", // No FontAwesome
                string: {
                    title: "",
                },
            });
            this.map.addControl(this.locatecontrol);
            this.map.on("locationfound", this._onLocationFound);

            let callbackId = null;
            const onLocateActivate = () => {
                callbackId = Geolocation.watchPosition(
                    { enableHighAccuracy: true },
                    (result, error) => {
                        if (error && error.message == "location disabled") {
                            Toast.show({
                                text: this.$t("map.location.disabled"),
                            });
                            this.locatecontrol.stop();
                        }
                    }
                );
            };
            this.map.on("locateactivate", () => {
                Geolocation.checkPermissions()
                    .then((status) => {
                        if (status.location != "granted") {
                            Geolocation.requestPermissions().then((status) => {
                                if (status.location == "granted") {
                                    onLocateActivate();
                                }
                            });
                            return;
                        }
                        onLocateActivate();
                    })
                    .catch((error) => {
                        Toast.show({
                            text: error,
                        });
                    });
            });
            this.map.on("locatedeactivate", () => {
                if (callbackId) {
                    Geolocation.clearWatch({ id: callbackId });
                    callbackId = null;
                }
            });
        },
        initVisualClickControl() {
            this.map.visualClick.removeHooks();
            this.map.on(
                this.map.options.visualClickEvents,
                (event) => {
                    if (this.map.visualClick._animating) {
                        return;
                    }
                    this.map.visualClick._animating = true;
                    // @see L.VisualClick.js
                    setTimeout(() => {
                        this.map.visualClick._animating = false;
                    }, 100);
                    this.map.visualClick._onClick(event);
                },
                this.map.visualClick
            );
        },
        async loadMapData() {
            await this.loadTilelayers();
            await this.loadCategories();

            if (this.feature_param) {
                this.featureFromUrl(this.feature_param);
            } else {
                this.mapStatusFromUrl(this.map_status);
            }
        },
        async loadTilelayers() {
            this.tilelayers = await ApiStorage.getAll("tilelayers");
            $.each(this.tilelayers, (index, layer) => {
                if (layer.group) {
                    if (!this.tilelayers_groups[layer.group]) {
                        this.tilelayers_groups[layer.group] = [];
                    }
                    this.tilelayers_groups[layer.group].push(layer);
                }
                this.selected_tilelayers[layer.id] =
                    layer.is_visible[this.$getLocale()] || false;
            });
        },
        async loadCategories() {
            this.categories = await ApiStorage.getAll("categories");
            this.categories_list = {};
            $.each(this.categories, (cat_index, category) => {
                if (!this.categories_list[category.id]) {
                    this.categories_list[category.id] = category;
                }
            });

            // Initial loading.
            if (!this.categories_loaded) {
                $.each(this.categories_list, (cat_id) => {
                    this.selected_categories[cat_id] =
                        this.categories_list[cat_id].is_visible[
                            this.$getLocale()
                        ];
                });

                this.categories_loaded = true;
            }

            this.injectCategorizables();
            this.initFiltering();
        },
        prepareVectorLayers(layerList) {
            if (!this.map.getPane("vector-overlays")) {
                this.initVectorLayers();
            }
            for (let layer of layerList) {
                if (!(layer.id in this.selected_vectorlayers)) {
                    this.selected_vectorlayers[layer.id] = layer.active;
                }
                this.vector_layers.push(layer);
            }
            this.$nextTick(() => {
                this.fixZIndex();
            });
        },
        initVectorLayers() {
            let overlayPane = this.map.getPane("vector-overlays");
            if (!overlayPane) {
                overlayPane = this.map.createPane("vector-overlays");
            }
            overlayPane.style.zIndex = 550; // Above shadowPane, below markerPane

            this.$nextTick(() => {
                // Hack to pass events to lower vector layers
                // Stolen from https://gist.github.com/perliedman/84ce01954a1a43252d1b917ec925b3dd
                let overlayBypass = function (e) {
                    e = e.originalEvent ? e.originalEvent : e;
                    if (e._stopped) {
                        return;
                    }

                    var target = e.target;
                    var stopped;
                    var removed;
                    var ev = new MouseEvent(e.type, e);

                    removed = { node: target, display: target.style.display };
                    target.style.display = "none";
                    target = document.elementFromPoint(e.clientX, e.clientY);

                    if (target && target !== overlayPane) {
                        stopped = !target.dispatchEvent(ev);
                        if (stopped || ev._stopped) {
                            window.L.DomEvent.stopPropagation(e);
                        }
                    }

                    removed.node.style.display = removed.display;
                };

                window.L.DomEvent.on(overlayPane, "click", overlayBypass);
                window.L.DomEvent.on(overlayPane, "mousemove", overlayBypass);
            });
        },
        // addLoading(num) {
        //     this.loading_threads += num;
        // },
        // removeLoading(num) {
        //     this.loading_threads -= num;
        // },
        injectCategorizables() {
            // Prepare the vector-overlays pane before adding categorizables
            // to avoid a race condition where overlays are added before.
            if (!this.map.getPane("vector-overlays")) {
                this.initVectorLayers();
            }
            for (let cat of this.categories) {
                if (!this.categorizables[cat.type]) {
                    let typeBreak = cat.type.split("\\");
                    let type = typeBreak[typeBreak.length - 1].toLowerCase();
                    this.filter_functions[cat.type] = [];
                    this.categorizables[cat.type] = {
                        name: type,
                    };
                }
            }
        },
        featureClick(feature) {
            this.selected_feature = feature;
            this.$emit("featureClick", feature);
            if (feature) {
                this.$nextTick(() => {
                    this.$router.push({
                        path: this.$route.path,
                        hash: "#sidebar_info",
                    });
                    this.sidebar.open("sidebar_info");
                });
            }
        },
        getFeatureName(info) {
            if (info.name[this.$getLocale()]) {
                return info.name[this.$getLocale()];
            }
            return info.name[Object.keys(info.name)[0]];
        },
        // layerMouseover(/*info*/) {
        //     // if(info.properties.category_id) {
        //     //     this.categories_highlighted.push(info.properties.category_id);
        //     // } else if(info.properties.categories) {
        //     //     this.categories_highlighted.concat(JSON.parse(info.properties.categories));
        //     // }
        //     // this.features_highlighted[info.type] = info.ids;
        // },
        // layerMouseout(/*info*/) {
        //     // this.categories_highlighted = [];
        //     // this.features_highlighted[info.type] = [];
        // },
        featureListClick(selected_feature) {
            this.$nextTick(() => {
                // Avoid some bugs
                this.selected_feature = selected_feature;
                // this.emitter.emit('featureClick', selected_feature);
                if (this.selected_feature) {
                    this.$nextTick(() => {
                        this.sidebar.open("sidebar_info");
                    });
                }
            });
        },
        featureFromUrl(selected_feature) {
            const map_status = this.map_status;
            return this.$router
                .replace({
                    path: this.$route.path,
                    hash: "#sidebar_info",
                })
                .then(() => {
                    const waitFn = (resolve) => {
                        // iOS requires waiting until the map
                        // is visible. It becomes size 0 when 
                        // the URL changes.
                        if (this.map.getSize().x == 0) {
                            setTimeout(waitFn, 50);
                            return;
                        }
                        this.selected_feature = selected_feature;
                        this.$nextTick(() => {
                            this.sidebar.open("sidebar_info");
                            this.mapStatusFromUrl(map_status);
                        });
                        resolve();
                    };
                    return new Promise(waitFn);
                });
        },
        mapStatusFromUrl(map_status) {
            if (!map_status) {
                return;
            }
            if (!this.map._loaded) {
                // wait for the map to load
                this.$nextTick(() => {
                    this.mapStatusFromUrl(map_status);
                });
            }
            this.$nextTick(() => {
                if ("center" in map_status && "zoom" in map_status) {
                    this.map.setView(map_status.center, map_status.zoom);
                    this.prevent_focus = true;
                }
                if ("selected_baselayer" in map_status) {
                    this.selected_baselayer = map_status.selected_baselayer;
                }
                if ("selected_tilelayers" in map_status) {
                    this.selected_tilelayers = map_status.selected_tilelayers;
                }
                if ("selected_vectorlayers" in map_status) {
                    // Requires a hack as vector layers load asyncronously
                    this._vectorLayersSelectWhenLoaded(
                        map_status.selected_vectorlayers
                    );
                }
                this.$nextTick(() => {
                    this.prevent_focus = false;
                });
            });
        },
        _vectorLayersSelectWhenLoaded(selected_vectorlayers, tries) {
            // Check if the ID is available, otherwise wait.
            // It should only take a couple of tries.
            // TODO: Improve this
            const max_tries = 20;
            tries = tries || 0;
            for (let id in selected_vectorlayers) {
                if (!(id in this.selected_vectorlayers) && tries <= max_tries) {
                    // Wait!
                    this.$nextTick(() => {
                        tries++;
                        this._vectorLayersSelectWhenLoaded(
                            selected_vectorlayers,
                            tries
                        );
                    });
                    return;
                }
            }
            this.selected_vectorlayers = selected_vectorlayers;
        },
        _getCategoryFilterFunction() {
            return {
                data: this.selected_categories,
                // run: function(){ return true; }
                run: function (element) {
                    if (!("categories" in element)) {
                        return false;
                    }
                    for (var cat_id of element.categories) {
                        if (cat_id!=null && this.data[cat_id]) {
                            return true;
                        }
                    }
                    return false;
                },
            };
        },
        initFiltering() {
            for (let type in this.filter_functions) {
                this.applyFilters({
                    type: type,
                    functions: [this._getCategoryFilterFunction()],
                });
            }
        },
        applyFilters(filters) {
            let functions = filters.functions;
            this.filter_functions[filters.type] = functions;
        },
        setBaseLayer(index) {
            this.selected_baselayer = index;
        },
        tilelayersSelected(id, selected) {
            this.selected_tilelayers[id] = selected;
            this.$nextTick(() => {
                this.fixZIndex();
            });
        },
        vectorlayersSelected(id, selected) {
            this.selected_vectorlayers[id] = selected;
            this.$nextTick(() => {
                this.fixZIndex();
            });
        },
        fixZIndex() {
            // Fix zIndex
            this.vector_layers.sort((a, b) => {
                return a.options.zIndex - b.options.zIndex;
            });
            for (let layer of this.vector_layers) {
                let match = null;
                this.map.eachLayer((l) => {
                    if (
                        l instanceof window.L.VectorGrid &&
                        l.options.id == layer.id
                    ) {
                        match = l;
                    }
                });
                if (match) {
                    match.bringToFront();
                }
            }
        },
        setLatticeSelectRef(el) {
            if (el) {
                this.lattice_select = el;
            }
        },
        setPointListRef(el) {
            if (el) {
                this.point_list = el;
            }
        },
        onSetAsStart(location) {
            if (this.lattice_select) {
                this.lattice_select._mapClickRoutingStart({
                    latlng: window.L.latLng(location[1], location[0]),
                });
            }
        },
        onSetAsEnd(location) {
            if (this.lattice_select) {
                this.lattice_select._mapClickRoutingEnd({
                    latlng: window.L.latLng(location[1], location[0]),
                });
            }
        },
        _onLocationFound(event) {
            this.last_location = event.latlng;
            ApiClient.storeUserLocation(event.latlng);
        },
        startWithLocation() {
            if (!this.locatecontrol) {
                return false;
            }
            this.locatecontrol.start();
        },
        onHeaderChange(text) {
            this.map_info_header = text;
        },
    },
};
</script>

<style>
.map_container {
    height: 100%;
    padding: 0;
}

.map_frame {
    height: 100%;
    width: 100%;
}

/*
Support for browsers that DON'T support Flexbox uses 100% height on the map-container defined above.
Map will at least render, for the small amount of browsers that DON'T support flexbox.
Users will just have to scroll abit ¯\_(ツ)_/¯
*/
@supports (display: flex) {
    .map_container {
        height: 0;
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
    }

    .map_frame {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
    }
}

/* Flex sidebar */
.leaflet-sidebar-content {
    display: flex;
    flex-direction: column;
}
.leaflet-sidebar-pane {
    flex: 1;
    flex-direction: column;
    padding: 5px 0;
}
.leaflet-sidebar-pane.active {
    display: flex !important;
}
.leaflet-sidebar-right .leaflet-sidebar-header {
    /* padding-left: 50px; */
    margin-bottom: 20px !important;
}
.leaflet-sidebar-close {
    position: absolute;
    top: 0;
    width: 30px !important;
    height: 40px;
    text-align: center;
    cursor: pointer;
    padding-top: 8px !important;
}
.leaflet-sidebar-close-icon::part(image) {
    max-height: 20px;
}

/* Overlay pane for selected elements */
.leaflet-overlay-pane {
    z-index: 560 !important;
}
/* Safari & iOS bug fix
 * @see https://github.com/Leaflet/Leaflet/issues/8068
 */
/* @supports (-webkit-touch-callout: none) { */
    .leaflet-control-container .leaflet-top,
    .leaflet-control-container .leaflet-bottom {
        transform: translate3d(0, 0, 0);
        will-change: transform;
    }
/* } */
</style>