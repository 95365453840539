import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Other common CSS */
import './theme/common.css';

/* PWA loader */
import { defineCustomElements } from '@ionic/pwa-elements/loader';

/* Leaflet */
import L from 'leaflet';
import { LMap, LTileLayer, LWmsTileLayer, LMarker, LIcon, LPolyline, LLayerGroup, LTooltip, LPopup, LControlZoom, LControlAttribution, LControlScale, LControlLayers, LGeoJson } from '@vue-leaflet/vue-leaflet';
import 'leaflet/dist/leaflet.css';

// Vector layers in Leaflet
import LVectorLayer from './components/VueProtobufAdapter.vue';

// Override _onClick to remove the legacy fakeStop hack
L.Canvas.Tile = L.Canvas.Tile.extend({
	_onClick: function (e) {
		var point = this._map.mouseEventToLayerPoint(e).subtract(this.getOffset()), layer, clickedLayer;

		for (var id in this._layers) {
			layer = this._layers[id];
			if (layer.options.interactive && layer._containsPoint(point) && !this._map._draggableMoved(layer)) {
				clickedLayer = layer;
			}
		}
		if (clickedLayer)  {
			this._fireEvent([clickedLayer], e);
		}
	},
});

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

// Patch a bug in L.Tooltip
window.L.OldTooltip = window.L.Tooltip.extend({});
window.L.Tooltip = window.L.Tooltip.extend({
    _animateZoom(e) {
        if(this._map) {
            return window.L.OldTooltip.prototype._animateZoom.call(this, e);
        }
    },
    _updatePosition(e) {
        if(this._map) {
            return window.L.OldTooltip.prototype._updatePosition.call(this, e);
        }
    }
});

// Sidebar control
import 'leaflet-sidebar-v2';
import 'leaflet-sidebar-v2/css/leaflet-sidebar.css';

// Translations
import { i18n, $getLocale } from '@/utils/locale'

// Google Analytics
//import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics';

// Other utils
import { $checkConnection, $isBrowser, $getWindowSize } from '@/utils/platform'
import mitt from 'mitt';

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(i18n);

app.component('l-map', LMap);
app.component('l-marker', LMarker);
app.component('l-icon', LIcon);
app.component('l-tile-layer', LTileLayer);
app.component('l-wms-tile-layer', LWmsTileLayer);
app.component('l-polyline', LPolyline);
app.component('l-layer-group', LLayerGroup);
app.component('l-tooltip', LTooltip);
app.component('l-popup', LPopup);
app.component('l-control-zoom', LControlZoom);
app.component('l-control-attribution', LControlAttribution);
app.component('l-control-scale', LControlScale);
app.component('l-control-layers', LControlLayers);
app.component('l-geo-json', LGeoJson);
app.component('v-protobuf', LVectorLayer);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$getLocale = $getLocale;
app.config.globalProperties.$isBrowser = $isBrowser;
app.config.globalProperties.$checkConnection = $checkConnection;
app.config.globalProperties.$getWindowSize = $getWindowSize;

// GA
//app.config.globalProperties.ga = GoogleAnalytics;

// A global state for all components, use with caution.
// It is mostly to store the "loading state" so all components
// are aware of the current state information.
app.config.globalProperties._$appState = {};
app.config.globalProperties.$setState = function(state) {
    this._$appState = Object.assign(this._$appState, state);
    this.emitter.emit('app-state-updated', this.$getState());
};
app.config.globalProperties.$getState = function(param) {
    return param ? this._$appState[param] : this._$appState;
};

router.isReady().then(() => {
  app.mount('#app');
});

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);