import { Network } from '@awesome-cordova-plugins/network';
import { isPlatform } from '@ionic/vue';

const $checkConnection = function() {
    if(typeof (window.cordova) === 'undefined') {
        return navigator.onLine; // we are on the browser
    } else if(navigator.connection.type == Network.Connection.NONE) {
        return false; // offline
    }

    return true; // online
};

const $isBrowser = function() {
    return isPlatform('mobileweb') || (!isPlatform('android') && !isPlatform('ios'));
}

const $getWindowSize = function() {
    if (window.innerWidth < 576) return 'xs'
    if (window.innerWidth >= 576 && window.innerWidth < 768) return 'sm'
    if (window.innerWidth >= 768 && window.innerWidth < 992) return 'md'
    if (window.innerWidth >= 992 && window.innerWidth < 1200) return 'lg'
    if (window.innerWidth >= 1200) return 'xl'
}


export { $checkConnection, $isBrowser, $getWindowSize };