<template>
    <ion-header>
        <ion-toolbar translucent>
            <ion-buttons slot="start" class="common-header-buttons">
                <ion-menu-button class="menu-button"></ion-menu-button>
            </ion-buttons>
            <ion-title class="main-logo-container">
                <h1 class="title">
                    <img
                        :alt="$t('general.logo_alt')"
                        @click="goHome"
                        class="title-image"
                        :src="
                            base_url + 'assets/img/logo-cabecera-InRuting.png'
                        "
                    />
                </h1>
            </ion-title>

            <ion-select
                slot="end"
                class="language-selector"
                :placeholder="$t('general.language.placeholder')"
                v-model="$root.$i18n.locale"
                :value="$root.$i18n.locale"
                @ion-change="setLastLocale"
            >
                <ion-select-option value="ca_ES">CAT</ion-select-option>
                <ion-select-option value="es_ES">ESP</ion-select-option>
                <ion-select-option value="en_US">ENG</ion-select-option>
                <ion-select-option value="fr_FR">FRA</ion-select-option>
            </ion-select>
        </ion-toolbar>
        <ion-progress-bar
            v-if="isLoading"
            :color="loading_error ? 'danger' : 'primary'"
            :value="loading_percent"
            :buffer="buffer_percent"
        ></ion-progress-bar>
    </ion-header>
</template>

<script>
import { defineComponent } from "vue";
import {
    IonTitle,
    IonToolbar,
    IonHeader,
    IonMenuButton,
    IonButtons,
    IonProgressBar,
    IonSelect,
    IonSelectOption,
    isPlatform,
} from "@ionic/vue";
import ApiClient from "@/api/client";

/**
 * Common header for all the pages.
 *
 * Will show a progress bar to notify
 * the user about the data state.
 */
export default defineComponent({
    name: "CommonHeader",
    components: {
        IonTitle,
        IonToolbar,
        IonHeader,
        IonMenuButton,
        IonButtons,
        IonProgressBar,
        IonSelect,
        IonSelectOption,
    },
    data() {
        return {
            isLoading: false,
            loading_percent: 0,
            buffer_percent: 0,
            loading_error: null,
            base_url: process.env.BASE_URL,
        };
    },
    mounted() {
        this.emitter.on("app-state-updated", (newState) => {
            this.setState(newState);
        });

        this.setState(this.$getState());
    },
    methods: {
        setState(newState) {
            this.isLoading = Object.prototype.hasOwnProperty.call(
                newState,
                "isLoading"
            )
                ? newState.isLoading
                : this.isLoading;
            this.loading_percent = Object.prototype.hasOwnProperty.call(
                newState,
                "loading_percent"
            )
                ? newState.loading_percent / 100
                : this.loading_percent;
            this.buffer_percent = Object.prototype.hasOwnProperty.call(
                newState,
                "buffer_percent"
            )
                ? newState.buffer_percent / 100
                : this.buffer_percent;
            this.loading_error = Object.prototype.hasOwnProperty.call(
                newState,
                "loading_error"
            )
                ? newState.loading_error
                : this.loading_error;
        },
        async setLastLocale() {
            if (!this._client) {
                this._client = ApiClient;
            }
            const current = await this._client.getLastLocale();
            if (current != this.$root.$i18n.locale) {
                await this._client.setLastLocale(this.$root.$i18n.locale);
                // this.$router.go(this.$router.currentRoute);
                // if (await this.$router.isReady()) {
                // this.$router.$forceUpdate();
                // }
                if (isPlatform("ios")) {
                    window.location.reload();
                } else {
                    this.$router.go(this.$router.currentRoute);
                }
            }
        },
        goHome() {
            if (this.$isBrowser()) {
                this.$router.push({ path: "/pages/home" });
            }
        },
    },
});
</script>