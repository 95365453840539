import { createI18n } from "vue-i18n";
import _ca_ES from '@/i18n/ca_ES.json';
import _es_ES from '@/i18n/es_ES.json';
import _en_US from '@/i18n/en_US.json';
import _fr_FR from '@/i18n/fr_FR.json';

// Translations with adequate fallback.
const $getLocale = function() {
    if(!this || !this.$root.$i18n.locale) {
        let lang = navigator.languages[0].replace('-', '_');
        if(lang == 'ca' || lang == 'ca_ES') {
            lang = 'ca_ES';
        } else if(lang == 'gl' || lang == 'gl_GL') {
            lang = 'es_ES';
        } else if(lang == 'eu' || lang == 'eu_ES') {
            lang = 'es_ES';
        } else if(lang == 'es' || lang == 'es_ES') {
            lang = 'es_ES';
        } else if(lang == 'fr' || lang == 'fr_FR') {
            lang = 'fr_FR';
        } else/* if(lang == 'en')*/ {
            lang = 'en_US';
        }
        if(this && this.$root.$i18n) {
            this.$root.$i18n.locale = lang;
        }
        return lang;
    }
    return this.$root.$i18n.locale;
};
const i18n = createI18n({
    locale: $getLocale(),
    globalInjection: true,
    fallbackLocale: {
        'ca_ES': ['es_ES'],
        'gl_ES': ['es_ES'],
        'gl_GL': ['es_ES'],
        'eu_ES': ['es_ES'],
        default: ['en_US'],
    },
    messages: {
        ca_ES: _ca_ES,
        es_ES: _es_ES,
        en_US: _en_US,
        fr_FR: _fr_FR,
    }
});

export {i18n, $getLocale};