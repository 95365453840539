<template>
    <div v-if="isLoading">
        <LoadingScreen :isLoading="isLoading" />
    </div>
    <div
        class="info-tab info-tab-lattice"
        v-else-if="merge_info && selected_feature.type == featureType"
    >
        <!-- <ion-header>
            <ion-title>
                <div class="ion-text-wrap">
                    {{ getFeatureName(selected_feature) }}
                </div>
            </ion-title>
        </ion-header> -->
        <!-- <h3 class="info-title">{{ getFeatureName(selected_feature) }}</h3> -->
        <h3 class="info-title">{{ $t("map.sidebar.lattice.info.title") }}</h3>

        <!-- <div>
            <gcamins-categories-show :categories="merge_info.categories"></gcamins-categories-show>
        </div> -->

        <ion-button
            color="secondary"
            class="noprint print-view-hide"
            @click="applyFocus"
            :title="$t('utils_info.focus')"
        >
            <ion-icon slot="icon-only" :icon="icon_map"></ion-icon>
        </ion-button>
        <ion-button
            color="secondary"
            class="noprint print-view-hide"
            @click="applyPrint"
            :title="$t('utils_info.print')"
        >
            <ion-icon slot="icon-only" :icon="icon_print"></ion-icon>
        </ion-button>
        <ion-button
            color="secondary"
            class="noprint print-view-hide"
            @click="applyExport"
            :title="$t('utils_info.export')"
        >
            <ion-icon slot="icon-only" :icon="icon_export"></ion-icon>
        </ion-button>
        <ion-button
            color="secondary"
            class="noprint print-view-hide"
            @click="applyShare"
            :title="$t('utils_info.share')"
        >
            <ion-icon slot="icon-only" :icon="icon_share"></ion-icon>
        </ion-button>
        <ion-button
            color="secondary"
            class="noprint print-view-hide"
            @click="applyDirections"
            :title="$t('utils_info.directions')"
        >
            <ion-icon slot="icon-only" :icon="icon_directions"></ion-icon>
        </ion-button>
        <ion-button
            color="secondary"
            class="noprint print-view-hide"
            @click="applyOffline"
            :title="$t('utils_info.safe_offline')"
        >
            <ion-icon
                slot="icon-only"
                :icon="offline_id ? icon_unsave : icon_save"
            ></ion-icon>
        </ion-button>

        <div class="lattice_notice">
            {{ $t("map.sidebar.lattice.info.notice") }}
        </div>

        <!-- Keep it alive to avoid errors on destroy -->
        <keep-alive>
            <elevation
                v-if="merge_info && custom_polyline && leafletObject"
                :key="'arcline-info-elevation'"
                :polyline="custom_polyline"
                :map="leafletObject"
                :waypoints="merge_info.waypoints ? merge_info.waypoints : []"
                @polylineClick="polylineClick"
            ></elevation>
        </keep-alive>

        <div v-if="geo_info && Object.keys(geo_info).length > 0">
            <ion-list>
                <template v-for="(value, key) in geo_info" :key="key">
                    <ion-item>
                        <ion-label position="stacked">
                            {{ $t("map.info." + key + ".label") }}
                        </ion-label>
                        {{ value }}
                    </ion-item>
                </template>
            </ion-list>
        </div>

        <profile-fields
            v-if="
                merge_info && Object.keys(merge_info.profile_values).length > 0
            "
            :profile_fields="profile_fields"
            :profile_values="merge_info.profile_values"
        ></profile-fields>

        <div
            v-if="
                merge_info &&
                merge_info.extra_info &&
                Object.keys(merge_info.extra_info).length > 0
            "
        >
            <ion-list>
                <template
                    v-for="(item, key) in merge_info.extra_info"
                    :key="key"
                >
                    <ion-item>
                        <ion-label position="stacked">
                            {{ item.label }}
                        </ion-label>
                        {{ item.value }}
                    </ion-item>
                </template>
            </ion-list>
        </div>

        <export-select
            :active="export_active"
            resource="lattice_routing"
            :export_functions="export_functions"
            :resource_params="{
                points: selected_feature.info.points,
                points_filter: makePointsFilter(),
            }"
            @on-finish="onExportFinish"
        ></export-select>
    </div>
</template>

<script>
import GenericInfo from "./GenericInfo.vue";
import ApiClient from "@/api/client";
import ApiStorage from "@/api/storage";
import { isEmpty } from "lodash";

import GCaminsElevation from "@/components/GCaminsElevation.vue";
import ExportSelect from "@/components/ExportSelect.vue";
import { compressToEncodedURIComponent } from "lz-string";
import { IonList, IonItem, IonLabel } from "@ionic/vue";
import { cloudDownloadOutline, cloudDone } from "ionicons/icons";
import { Toast } from "@capacitor/toast";

export default {
    name: "ArclineInfo",
    extends: GenericInfo,
    components: {
        IonList,
        IonItem,
        IonLabel,
        elevation: GCaminsElevation,
        "export-select": ExportSelect,
    },
    props: {
        current_tab: String,
    },
    data() {
        return {
            resource: "arclines",
            featureType: "Lattice",
            model: "Modules\\Lattice\\Entities\\Arcline",
            isLoading: true,

            merge_info: null,
            geo_info: null,
            markers: [],
            offline_id: null,
            export_functions: [
                {
                    text: this.$t("map.exports.geojson"),
                },
                {
                    text: this.$t("map.exports.gpx"),
                },
                {
                    text: this.$t("map.exports.kml"),
                },
                {
                    text: this.$t("map.exports.shp"),
                },
            ],

            icon_save: cloudDownloadOutline,
            icon_unsave: cloudDone,
        };
    },
    watch: {
        selected_feature(val) {
            this.merge_info = null;
            if (val) {
                this.setPolyline(val);
            }
        },
        current_tab(val) {
            if (val == "sidebar_info") {
                this.setMarkers();
            } else if (val == "") {
                return;
            } else {
                this.removeMarkers();
            }
        },
    },
    methods: {
        load() {
            // Override GenericInfo, works different
            if (!this.start_icon) {
                this.start_icon = new window.L.Icon({
                    iconUrl:
                        process.env.BASE_URL +
                        "assets/modules/lattice/images/flag-start.png",
                    iconSize: [25, 25],
                    iconAnchor: [12, 12],
                });
                this.end_icon = new window.L.Icon({
                    iconUrl:
                        process.env.BASE_URL +
                        "assets/modules/lattice/images/flag-end.png",
                    iconSize: [25, 25],
                    iconAnchor: [12, 12],
                });
                this.intermediate_icon = new window.L.Icon({
                    iconUrl:
                        process.env.BASE_URL +
                        "assets/modules/lattice/images/flag-intermediate.png",
                    iconSize: [25, 25],
                    iconAnchor: [12, 12],
                });
            }
            return true;
        },
        getFeatureString() {
            let obj = Object.assign({}, this.selected_feature);
            obj.points_filter = this.makePointsFilter();
            return encodeURIComponent(
                compressToEncodedURIComponent(JSON.stringify(obj))
            );
        },
        async setPolyline(selected_feature) {
            this.isLoading = true;
            this.offline_id = null;
            if (selected_feature && selected_feature.type != "Lattice") {
                this.isLoading = false;
                return;
            }
            this.$emit(
                "header_change",
                this.$t("map.sidebar.lattice.info.header")
            );

            if ("offline_data" in selected_feature) {
                // It's loaded offline
                this.custom_polyline =
                    selected_feature.offline_data.custom_polyline;
                this.geo_info = selected_feature.offline_data.geo_info;
                this.geo_meta = selected_feature.offline_data.geo_meta;
                this.merge_info = selected_feature.offline_data.merge_info;
                this.offline_id = selected_feature.offline_data.offline_id;
                delete selected_feature.offline_data; // Important to keep clean for sharing URL
            } else {
                const route = await ApiClient.routingGetRoute({
                    points: this.selected_feature.info.points,
                }).then((response) => {
                    return response.data;
                });
                this.custom_polyline = {
                    style_color:
                        this.selected_feature.info.lattice_group
                            .selection_style_color,
                    style_width:
                        this.selected_feature.info.lattice_group
                            .selection_style_width,
                    style_opacity:
                        this.selected_feature.info.lattice_group
                            .selection_style_opacity,
                    name: this.$t("map.info.custom_route"),
                    polyline: route.geojson,
                };
                this.selected_feature.info.ids = route.ids;
                this.geo_info = route.geo_info;
                this.geo_meta = route.geo_meta;

                const points_filter = this.makePointsFilter();

                this.merge_info = await ApiClient.routingGetMergeInfo({
                    ids: this.selected_feature.info.ids,
                    geo_meta: this.geo_meta,
                    points_filter: points_filter,
                }).then((response) => {
                    if (response.data.extra_info) {
                        return response.data;
                    } else {
                        return null;
                    }
                });
                this.merge_info.waypoints = await this.prepareWaypoints(
                    this.merge_info.waypoints
                );
            }

            this.setMarkers();
            this.isLoading = false;
        },
        removeMarkers() {
            this.markers.forEach((m) => {
                m.remove();
            });
        },
        setMarkers() {
            if (
                !this.selected_feature ||
                this.selected_feature.type != this.featureType
            ) {
                return;
            }
            const endIndex = this.selected_feature.info.points.length - 1;
            this.selected_feature.info.points.forEach((latlng, index) => {
                let icon = this.intermediate_icon;
                if (index == 0) {
                    icon = this.start_icon;
                } else if (index == endIndex) {
                    icon = this.end_icon;
                }
                const marker = new window.L.Marker(latlng, {
                    icon: icon,
                });
                marker.addTo(this.leafletObject);
                this.markers.push(marker);
            });
        },
        applyDirections() {
            const coords =
                this.custom_polyline.polyline.coordinates[0][1] +
                "," +
                this.custom_polyline.polyline.coordinates[0][0];
            window.open(
                "https://www.google.com/maps/dir/?api=1&destination=" + coords,
                "_blank"
            );
        },
        applyOffline() {
            if (this.offline_id) {
                ApiClient.removeRouting(this.offline_id).then(() => {
                    this.offline_id = null;
                });
            } else {
                const feature_string = this.getFeatureString();
                const data = {
                    merge_info: this.merge_info,
                    geo_info: this.geo_info,
                    geo_meta: this.geo_meta,
                    custom_polyline: this.custom_polyline,
                };
                ApiClient.saveRouting(feature_string, data).then((result) => {
                    if (result && result.offline_id) {
                        this.offline_id = result.offline_id;
                        Toast.show({
                            text: this.$t("map.routing.offline.saved"),
                        });
                    }
                });
            }
        },
        makePointsFilter() {
            if ("points_filter" in this.selected_feature) {
                return this.selected_feature.points_filter;
            }
            if (!this.$parent || !this.$parent.filter_functions) {
                return {};
            }
            let filter_functions =
                this.$parent.filter_functions[
                    "Modules\\Points\\Entities\\Point"
                ];
            let filter_data = {};
            for (let fn of filter_functions) {
                if (fn.field) {
                    if (!isEmpty(fn.data)) {
                        filter_data[fn.field] = fn.data;
                    }
                } else {
                    // categories
                    let active = [];
                    for (let cat_id in fn.data) {
                        if (fn.data[cat_id]) {
                            active.push(cat_id);
                        }
                    }
                    filter_data["categories"] = active;
                }
            }
            return filter_data;
        },
        async prepareWaypoints(waypoints) {
            if (!this.$parent) {
                return waypoints;
            }
            let wpt = waypoints;
            if (
                this.$parent.point_list &&
                this.$parent.point_list.filtered_items.length
            ) {
                for (let index in waypoints) {
                    let point = wpt[index];
                    if (isEmpty(point.properties.name)) {
                        const pt = this.$parent.point_list.filtered_items.find(
                            (p) => {
                                return p.id == point.properties.id;
                            }
                        );
                        if (pt) {
                            wpt[index].properties.name =
                                this.getFeatureName(pt);
                        }
                    }
                }
            } else {
                for (let index in waypoints) {
                    let point = wpt[index];
                    if (isEmpty(point.properties.name)) {
                        const pt = await ApiStorage.select(
                            "points",
                            point.properties.id
                        );
                        if (pt) {
                            wpt[index].properties.name =
                                this.getFeatureName(pt);
                        }
                    }
                }
            }
            return wpt;
        },
        getFeatureName(info) {
            if (info.name[this.$getLocale()]) {
                return info.name[this.$getLocale()];
            }
            return info.name[Object.keys(info.name)[0]];
        },
        polylineClick() {
            this.$emit('featureListClick', this.selected_feature);
        },
        applyFocus() {
            const coords = this.custom_polyline.polyline.coordinates;
            let bounds = new window.L.LatLngBounds(
                window.L.latLng(coords[0][1], coords[0][0]),
                window.L.latLng(coords[0][1], coords[0][0]),
            );
            for(let c of coords) {
                bounds.extend(window.L.latLng(c[1], c[0]));
            }
            this.$parent.sidebar.close();
            this.leafletObject.fitBounds(bounds);
        }
    },
};
</script>

<style>
.lattice_notice {
    padding: 10px 5px;
    font-size: 16px;
}
</style>