<template>
  <ion-page>
    <!-- <ion-tabs> -->
      <ion-router-outlet></ion-router-outlet>
    <!-- </ion-tabs> -->
  </ion-page>
</template>

<script>
// import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
// import { IonTabs, IonPage, IonRouterOutlet } from '@ionic/vue';
import { IonPage, IonRouterOutlet } from '@ionic/vue';
import { ellipse, square, triangle } from 'ionicons/icons';

export default {
  name: 'RouterPage',
  // components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
//   components: { IonTabs, IonPage, IonRouterOutlet },
  components: { IonPage, IonRouterOutlet },
  setup() {
    return {
      ellipse, 
      square, 
      triangle,
    }
  }
}
</script>