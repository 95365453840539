<template>
    <div v-if="isLoading">
        <LoadingScreen :isLoading="isLoading" />
    </div>
    <div
        class="info-tab info-tab-point"
        v-else-if="feature && selected_feature.type == featureType"
    >
        <h3 class="info-title">{{ getFeatureName(feature) }}</h3>

        <div>
            <gcamins-categories-show
                :categories="feature.categories"
            ></gcamins-categories-show>
        </div>

        <div style="width: 100%">
            <ion-button
                color="secondary"
                class="noprint print-view-hide"
                @click="applyFocus"
                :title="$t('utils_info.focus')"
            >
                <ion-icon slot="icon-only" :icon="icon_map"></ion-icon>
            </ion-button>
            <ion-button
                color="secondary"
                class="noprint print-view-hide"
                @click="applyShare"
                :title="$t('utils_info.share')"
            >
                <ion-icon slot="icon-only" :icon="icon_share"></ion-icon>
                <!-- {{ this.$t('map.share') }} -->
            </ion-button>
            <ion-button
                color="secondary"
                class="noprint print-view-hide"
                @click="applyPrint"
                :disabled="!$checkConnection()"
                :title="$t('utils_info.print')"
            >
                <ion-icon slot="icon-only" :icon="icon_print"></ion-icon>
                <!-- {{ this.$t('map.print') }} -->
            </ion-button>
            <ion-button
                color="secondary"
                class="noprint print-view-hide"
                @click="applyExport"
                :disabled="!$checkConnection()"
                :title="$t('utils_info.export')"
            >
                <ion-icon slot="icon-only" :icon="icon_export"></ion-icon>
                <!-- {{ this.$t('map.export') }} -->
            </ion-button>
            <ion-button
                color="secondary"
                class="noprint print-view-hide"
                @click="setAsStart"
                :disabled="!$checkConnection()"
                :title="$t('utils_info.start_point')"
            >
                <ion-icon slot="icon-only" :icon="icon_flag"></ion-icon>
                <!-- {{ this.$t('map.print') }} -->
            </ion-button>
            <ion-button
                color="secondary"
                class="noprint print-view-hide"
                @click="setAsEnd"
                :disabled="!$checkConnection()"
                :title="$t('utils_info.end_point')"
            >
                <ion-icon slot="icon-only" :icon="icon_flag2"></ion-icon>
                <!-- {{ this.$t('map.print') }} -->
            </ion-button>
            <ion-button
                color="secondary"
                class="noprint print-view-hide"
                @click="applyDirections"
                :title="$t('utils_info.directions')"
            >
                <ion-icon slot="icon-only" :icon="icon_directions"></ion-icon>
                <!-- {{ this.$t('map.print') }} -->
            </ion-button>
            <ion-button
                v-if="canUploadImage()"
                color="secondary"
                class="noprint print-view-hide"
                @click="uploadImage"
                :disabled="!$checkConnection()"
                :title="$t('utils_info.upload_image')"
            >
                <ion-icon slot="icon-only" :icon="icon_image"></ion-icon>
                <!-- {{ this.$t('map.print') }} -->
            </ion-button>
        </div>

        <!-- <div v-if="$checkConnection() && feature.images && feature.images.length > 0"
            class="media">
            <img :src="feature.images[0].original_url">
        </div> -->

        <div>
            <gcamins-multimedia-info
                v-if="feature.images"
                :model_data="feature"
            >
            </gcamins-multimedia-info>
        </div>

        <div
            class="description"
            v-if="feature.description"
            v-html="feature.description[$getLocale()]"
        ></div>

        <profile-fields
            :profile_fields="profile_fields"
            :profile_values="feature.profile_values"
        ></profile-fields>

        <export-select
            :active="export_active"
            :resource="resource"
            :export_functions="export_functions"
            :resource_params="{ ids: [this.feature.id] }"
            @on-finish="onExportFinish"
        ></export-select>

        <upload-image
            :active="upload_active"
            :element_type="model"
            :feature="feature"
            @on-finish="onUploadFinish"
        ></upload-image>
    </div>
</template>

<script>
// Storage
import ApiStorage from "@/api/storage";

// Ionic components
import { IonButton, IonIcon } from "@ionic/vue";
import {
    print,
    downloadOutline,
    shareSocialOutline,
    flagOutline,
    flagSharp,
    cameraOutline,
    navigateCircleOutline,
    mapOutline,
} from "ionicons/icons";
import { Share } from "@capacitor/share";
import { Clipboard } from "@capacitor/clipboard";
import { Toast } from "@capacitor/toast";

// Other components
import LoadingScreen from "@/components/LoadingScreen.vue";
import GCaminsCategoriesShow from "@/components/GCaminsCategoriesShow.vue";
import GCaminsMultimediaInfo from "@/components/GCaminsMultimediaInfo.vue";
import ExportSelect from "@/components/ExportSelect.vue";
import UploadImage from "@/components/UploadImage.vue";
import GCaminsShowProfile from "@/components/GCaminsShowProfile.vue";
import { compressToEncodedURIComponent } from "lz-string";

export default {
    name: "GenericInfo",
    components: {
        IonButton,
        IonIcon,
        LoadingScreen,
        "gcamins-categories-show": GCaminsCategoriesShow,
        "gcamins-multimedia-info": GCaminsMultimediaInfo,
        "export-select": ExportSelect,
        "upload-image": UploadImage,
        "profile-fields": GCaminsShowProfile,
    },
    data() {
        return {
            base_url: process.env.VUE_APP_WEBAPP_URL,
            model: "",
            featureType: "",
            resource: "",
            isLoading: true,

            feature: null,
            export_active: false,
            export_functions: [
                {
                    text: this.$t("map.exports.geojson"),
                },
                {
                    text: this.$t("map.exports.gpx"),
                },
                {
                    text: this.$t("map.exports.kml"),
                },
                {
                    text: this.$t("map.exports.shp"),
                },
            ],
            export_modal_open: false,
            upload_active: false,

            profile_fields: [],
            girona_categories: [],

            icon_map: mapOutline,
            icon_print: print,
            icon_export: downloadOutline,
            icon_share: shareSocialOutline,
            icon_flag: flagOutline,
            icon_flag2: flagSharp,
            icon_image: cameraOutline,
            icon_directions: navigateCircleOutline,
        };
    },
    props: {
        selected_feature: null,
        leafletObject: null,
        prevent_focus: {
            type: [Boolean],
            custom: true,
            default: () => false,
        },
    },
    watch: {
        selected_feature() {
            this.feature = null;
            this.load();
        },
    },
    async mounted() {
        this.isLoading = true;
        this.profile_fields = await ApiStorage.getAll("profile_fields").then(
            (profile_fields) => {
                return profile_fields
                    .filter((f) => {
                        return f && f.type == this.model;
                    })
                    .sort((a, b) => {
                        return a.position > b.position;
                    });
            }
        );
        this.girona_categories = await ApiStorage.get(
            "config.girona_categories"
        ).then((result) => result.map((id) => Number(id)));

        this.isLoading = false;
    },
    methods: {
        getFeatureName(info) {
            if (info.name[this.$getLocale()]) {
                return info.name[this.$getLocale()];
            }
            return info.name[Object.keys(info.name)[0]];
        },
        async load() {
            this.isLoading = true;
            if (
                !this.selected_feature ||
                this.selected_feature.type != this.featureType
            ) {
                this.isLoading = false;
                return;
            }
            this.$emit("header_change", this.$t("map.info.header"));
            this.feature = await ApiStorage.select(
                this.resource,
                this.selected_feature.id
            ).then((result) => result);
            if (!this.feature) {
                this.isLoading = false;
                Toast.show({
                    text: this.$t("init.errors.unexpected_error"),
                });
                return;
            }
            this.feature.profile_values = await ApiStorage.select(
                "profile_values." + this.resource,
                this.selected_feature.id
            ).then((result) => result);
            this.isLoading = false;
        },
        applyPrint() {
            const feature_string = this.getFeatureString();
            const print_url =
                window.location.origin.trim("/") +
                "/print?selected_feature=" +
                feature_string;
            if (this.$isBrowser()) {
                window.open(print_url);
            } else {
                this.$router.push({
                    path: "/print",
                    query: { selected_feature: feature_string },
                });
            }
        },
        applyExport() {
            this.export_active = true;
        },
        async applyShare() {
            const feature_string = this.getFeatureString();
            let share_url =
                window.location.origin +
                this.$route.path +
                "?selected_feature=" +
                feature_string;
            if (!this.$isBrowser()) {
                share_url =
                    this.base_url +
                    this.$route.path +
                    "?selected_feature=" +
                    feature_string;
            }
            // if (navigator.share) {
            if (await Share.canShare()) {
                Share.share({
                    title: this.getFeatureName(
                        this.feature ? this.feature : this.selected_feature
                    ),
                    text: "",
                    url: share_url,
                    // dialogTitle: 'Share with buddies',
                }).catch(() => {
                    // canShare() check is useless sometimes, still can't share
                    Clipboard.write({
                        string: share_url,
                    }).then(() => {
                        Toast.show({
                            text: this.$t("general.share.copied"),
                        });
                    });
                });
            } else {
                Clipboard.write({
                    string: share_url,
                }).then(() => {
                    Toast.show({
                        text: this.$t("general.share.copied"),
                    });
                });
            }
        },
        getFeatureString() {
            let obj = Object.assign({}, this.selected_feature);
            delete obj.info;
            return encodeURIComponent(
                compressToEncodedURIComponent(JSON.stringify(obj))
            );
        },
        onExportFinish() {
            this.export_active = false;
        },
        uploadImage() {
            this.upload_active = true;
        },
        onUploadFinish() {
            this.upload_active = false;
        },
        canUploadImage() {
            for (let cat of this.feature.categories) {
                if (this.girona_categories.includes(cat.id)) {
                    return true;
                }
            }
            return false;
        },
        setAsStart() {
            // Only points for now
            this.$emit("set-as-start", this.feature.point.coordinates);
        },
        setAsEnd() {
            // Only points for now
            this.$emit("set-as-end", this.feature.point.coordinates);
        },
        /**
         * Implement in each extended class.
         */
        applyDirections() {
            // const coords = this.feature.point.coordinates[1]+','+this.feature.point.coordinates[0];
            // window.open('https://www.google.com/maps/dir/?api=1&destination='+coords, '_blank');
        },
        applyFocus() {},
    },
};
</script>

<style>
.description {
    padding: 5px 15px;
}
</style>