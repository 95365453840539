<template>
    <ion-item>
        <ion-label position="floating">{{ field.name[$getLocale()] }}</ion-label>
        <ion-select
            multiple
            :clear-input="true"
            v-model="content"
            @ion-input="handleInput">
            <template  v-for="item in field.options[$getLocale()]" :key="item">
                <ion-select-option :value="item">{{ item }}</ion-select-option>
            </template>
        </ion-select>
    </ion-item>
</template>

<script>
// Ionic components
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/vue';
import { isEqual } from 'lodash';

export default {
    name: 'FieldFilterSelect',
    components: {
        IonItem,
        IonLabel,
        IonSelect,
        IonSelectOption,
    },
    props:{
        field: Object,
        value: {
            type: Array,
            default: () => { return [] }
        },
        registerFilter: Function,
    },
    mounted() {
       if(this.registerFilter) this.registerFilter(this);
    },
    data() {
        return {
           content: this.value,
        }
    },
    methods: {
        handleInput() {
            this.$emit('input', this.content);
        },
        getFilterFunction() {
            if(isEqual(this.content, this.getInitialValue())) {
               return { field: this.field.slug, data: {}, run: function() { return true } };
            }
            return {
                data: this.content,
                field: this.field.slug,
                run: function(element) {
                    if(!element || !element.profile_field_values || !element.profile_field_values[this.field]) {
                        return false;
                    }
                    if(this.data.includes(element.profile_field_values[this.field])) {
                        return true;
                    }
                    return false;
                }
            };
        },
        clear() {
            this.content = this.getInitialValue();
            this.handleInput();
        },
        getInitialValue() {
            return [];
        },
    },
};
</script>

<style>
</style>
