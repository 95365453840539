
<script>
import { onMounted, ref, inject, nextTick } from "vue";
// import 'leaflet.vectorgrid/dist/Leaflet.VectorGrid.bundled'
import 'leaflet.vectorgrid/dist/Leaflet.VectorGrid';
import {
  remapEvents,
  propsBinder,
  WINDOW_OR_GLOBAL,
  GLOBAL_LEAFLET_OPT,
} from "@vue-leaflet/vue-leaflet/src/utils.js";
import { props as gridLayerProps, setup as gridLayerSetup } from "@vue-leaflet/vue-leaflet/src/functions/gridLayer";
import { render } from "@vue-leaflet/vue-leaflet/src/functions/layer";

/**
 * Hack L.VectoGrid.Protobuf to allow cancelling tile requests.
 */
// if(typeof AbortController != 'undefined') {
//     const original__getVectorTilePromise = window.L.VectorGrid.Protobuf.prototype._getVectorTilePromise;
//     const original__removeTile           = window.L.VectorGrid.Protobuf.prototype._removeTile;

//     window.L.VectorGrid.Protobuf = window.L.VectorGrid.Protobuf.extend({
//         _pendingTileController: {},
//         _getVectorTilePromise: function(coords, tileBounds) {
//             const controller = new AbortController();
//             const { signal } = controller;
//             const key = this._tileCoordsToKey(coords);

//             this.options.fetchOptions.signal = signal;
//             const promise = original__getVectorTilePromise.call(this, coords, tileBounds);

//             this._pendingTileController[key] = controller;
//             return promise.then(result => {
//                 if(key in this._pendingTileController) {
//                     delete this._pendingTileController[key];
//                 }
//                 return result;
//             });
//         },
//         _removeTile: function(key) {
//             original__removeTile.call(this, key);
//             // if(key in this._pendingTileController && !this._pendingTileController[key].signal.aborted) {
//                 try {
//                     key in this._pendingTileController && this._pendingTileController[key].abort();
//                 } catch(e){ /* ignore exception */ }

//                 delete this._pendingTileController[key];
//             // }
//         }
//     });
// }


export const props = {
     ...gridLayerProps,
    url: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: function () {
        return {}
      }
    }
};

/**
 * Adapter for L.VectorGrid.Protobuf that allows usage in Vue 3.
 */
export default {
    name: 'VueProtobufAdapter',
    props: props,
    setup (props, context) {
        const leafletRef = ref({});
        const ready = ref(false);

        const useGlobalLeaflet = inject(GLOBAL_LEAFLET_OPT);
        const addLayer = inject("addLayer");

        const { options, methods } = gridLayerSetup(props, leafletRef);

        onMounted(async () => {
            const { DomEvent } = useGlobalLeaflet
                ? WINDOW_OR_GLOBAL.L
                : await import("leaflet/dist/leaflet-src.esm");

            leafletRef.value = window.L.vectorGrid.protobuf(props.url, options);
            
            if(options.interactive) {
                const listeners = remapEvents(context.attrs);
                DomEvent.on(leafletRef.value, listeners);
            }
            
            propsBinder(methods, leafletRef.value, props);
            
            addLayer({
                ...props,
                ...methods,
                leafletObject: leafletRef.value,
            });

            ready.value = true;

            nextTick(() => context.emit("ready", leafletRef.value));
        });

        return { ready, leafletObject: leafletRef };
    },
    render() {
        return render(this.ready, this.$slots);
    },
};
</script>