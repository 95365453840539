import { createRouter, createWebHistory } from '@ionic/vue-router';
import RouterPage from '../views/RouterPage.vue'
import PrintPage from '../views/PrintPage.vue'
import EmbedPage from '../views/EmbedPage.vue'
import Map from '../views/Map.vue'

const routes = [
  {
    path: '/',
    redirect: '/pages/home'
  },
  {
    path: '/pages/',
    component: RouterPage,
    children: [
      {
        path: '',
        redirect: '/pages/home'
      },
      {
        path: 'home',
        component: () => import('@/views/Home.vue')
      },
      {
        name: 'map',
        path: 'map',
        component: Map,
        props: route => ({
            // feature_param: route.query.selected_feature,
            sidebar_tab: route.hash
        }),
      },
      {
        path: 'about/what_is_it',
        component: () => import('@/views/AboutWhatIsIt.vue')
      },
      {
        path: 'about/what_to_do',
        component: () => import('@/views/AboutWhatToDo.vue')
      },
      {
        path: 'about/who_we_are',
        component: () => import('@/views/AboutWhoWeAre.vue')
      },
      {
        path: 'about/tips',
        component: () => import('@/views/AboutTips.vue')
      },
      {
        path: 'about/signaling',
        component: () => import('@/views/AboutSignaling.vue')
      },
      {
        path: 'about/rules',
        component: () => import('@/views/AboutRules.vue')
      },
      {
        path: 'about/contact',
        component: () => import('@/views/AboutContact.vue')
      },
      {
        path: 'search',
        component: () => import('@/views/Search.vue')
      },
      {
        path: 'offline_mode',
        component: () => import('@/views/OfflineMode.vue'),
      },
    ]
  },
  {
    path: '/legal/',
    component: RouterPage,
    children: [
      {
        path: 'notes',
        component: () => import('@/views/LegalNotes.vue')
      },
      {
        path: 'privacy',
        component: () => import('@/views/LegalPrivacy.vue')
      },
      {
        path: 'cookies',
        component: () => import('@/views/LegalCookies.vue')
      },
      {
        path: 'credits',
        component: () => import('@/views/LegalCredits.vue')
      },
    ],
  },
  {
    path: '/print',
    component: PrintPage,
    props: route => ({ feature_param: route.query.selected_feature }),
  },
  {
    name: 'embed',
    path: '/embed',
    component: EmbedPage,
    props: route => ({ feature_param: route.query.selected_feature, map_status: route.query.map_status }),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
