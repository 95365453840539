<template>
    <div>
        <div v-if="$checkConnection() && model_data.images && model_data.images.length > 0"
            class="image-preview media">
            <img :edit_type="'images'" :src="model_data.images[0].original_url" @click.stop="list_type">
        </div>

        <div class="ion-text-center">
            <ion-buttons slot="end" class="multimedia-buttons" color="secondary">
                <template v-for="(params,type) in multimedia_types" v-bind:key="type">
                    <ion-button
                        v-if="type in model_data"
                        color="secondary"
                        @click.stop="list_type"
                        :title="params.name"
                        :edit_type="type"
                        style="min-width: 55px; flex: 1;">
                        <ion-icon slot="start" :icon="params.icon"  size="large"></ion-icon>
                        {{ model_data[type].length }}
                    </ion-button>
                </template>
            </ion-buttons>
        </div>
        <div ref="gallery"></div>
        <slot></slot>

        <ion-modal
            class="multimedia-modal"
            :is-open="modal_open"
            :can-dismiss="true"
            :presenting-element="$parent.$refs.ionRouterOutlet"
            @did-dismiss="closeModal"
            >
            <ion-header>
                <ion-toolbar>
                    <ion-title>{{ multimedia_types[edit_type].name }}</ion-title>
                    <ion-buttons slot="end">
                        <ion-button @click="closeModal"><ion-icon  :icon="icon_close"></ion-icon></ion-button>
                    </ion-buttons>
                </ion-toolbar>
            </ion-header>
            <ion-list v-if="modal_open">
                
                <template v-for="media in model_data[edit_type]" v-bind:key="media.id">
                    <ion-item text-wrap button @click="openMedia(media)">
                        <ion-icon :icon="multimedia_types[edit_type].icon" slot="start"></ion-icon>
                        <ion-label text-wrap>{{ getItemText(media.name) }}</ion-label>
                        <ion-icon :icon="icon_view" slot="end"></ion-icon>
                    </ion-item>
                </template>
            </ion-list>
        </ion-modal>
    </div>
</template>

<script>
// Ionic components
import { IonButtons, IonButton, IonIcon, IonModal, IonList, IonHeader, IonItem, IonLabel, IonToolbar, IonTitle } from '@ionic/vue';
import { imageOutline, documentTextOutline, logoYoutube, linkOutline, arrowForwardOutline, close } from 'ionicons/icons';

// Multimedia Gallery
import { Fancybox } from "@fancyapps/ui/dist/fancybox.umd.js";
import "@fancyapps/ui/dist/fancybox.css";

import { map } from 'lodash';

export default {
    name: 'GCaminsMultimediaInfo',
    components: {
        IonButtons,
        IonButton,
        IonIcon,
        IonModal,
        IonList,
        IonHeader,
        IonItem,
        IonLabel,
        IonToolbar,
        IonTitle,
    },
    data() {
        return {
            multimedia_types: {
                images: {
                    name: this.$t('multimedia.images.label'),
                    icon: imageOutline
                },
                documents: {
                    name: this.$t('multimedia.documents.label'),
                    icon: documentTextOutline
                },
                videos: {
                    name: this.$t('multimedia.videos.label'),
                    icon: logoYoutube
                },
                links: {
                    name: this.$t('multimedia.links.label'),
                    icon: linkOutline
                }
            },
            edit_type: 'images',

            modal_open: false,

            icon_view: arrowForwardOutline,
            icon_close: close,
        }
    },
    props: {
        model_data: {},
    },
    // mounted() {
    // },
    // computed: {
    // },
    // watch: {
    // },
    methods: {
        list_type(ev) {
            if(!this.$checkConnection()) {
                return false;
            }
            this.modal_active = true;
            this.edit_type = ev.target.getAttribute('edit_type');
            this.showMultimedia(ev);
        },
        showMultimedia() {
            let dynamic = [];
            switch(this.edit_type){
                case 'images':
                    dynamic = map(this.model_data[this.edit_type], (image) => {
                        return {
                            src: image.original_url,
                            thumb: image.thumb_url,
                            type: "image",
                            caption: image.name[this.$getLocale()]
                        };
                    });
                break;
                case 'videos':
                    dynamic = map(this.model_data[this.edit_type], (video) => {
                        return {
                            src: video.url,
                            thumb: video.thumb_url,
                            type: 'video',
                            caption: video.name[this.$getLocale()],
                        };
                    });
                break;
                case 'documents':
                    // dynamic = map(this.model_data[this.edit_type], (document) => {
                    //     return {
                    //         src: document.url,
                    //         type: "pdf",
                    //         caption: document.name[this.$getLocale()],
                    //         // frameHeight: 495,
                    //     };
                    // });
                    this.modal_open = true;
                return;
                case 'links':
                    // dynamic = map(this.model_data[this.edit_type], (link) => {
                    //     return {
                    //         src: link.url,
                    //         thumb: link.thumb_url,
                    //         type: 'iframe',
                    //         caption: link.name[this.$getLocale()]
                    //     };
                    // });
                    this.modal_open = true;
                return;
            }
            if(dynamic.length > 0) {
                this.fancybox = Fancybox.show(dynamic);
            } else {
                // TODO: Show a dialog if no media?
            }
        },
        getItemText(names) {
            if(!names[this.$getLocale()]) {
                return names[Object.keys(names)[0]] || '';
            }
            return names[this.$getLocale()];
        },
        openMedia(media) {
            window.open(media.url, '_blank');
        },
        closeModal() {
            this.modal_open = false;
        }
    }
}
</script>

<style>
.multimedia-modal {
    margin: auto;
}
ion-modal.multimedia-modal::part(content) {
    max-width: 400px;
}
ion-modal ion-label, ion-modal ion-title {
    color: black;
}
ion-modal .spinner-container {
    width: 100%;
    display: inline-block;
}
.image-preview img {
    cursor: pointer;
}
</style>