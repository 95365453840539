import { Filesystem, Directory } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import axios from 'axios'
/* @see https://github.com/diachedelic/capacitor-blob-writer/ */
import write_blob from "capacitor-blob-writer";

/**
 * Save and read files in the storage.
 * Check their information (size, etc.)
 * Create and remove directories (recursive too.)
 * 
 * As other solutions arised, this class is not used
 * as expected, yet it may be useful to keep it.
 */
export default {
    async writeFile(path, data) {
        if (data instanceof Blob) {
            // data = await this.blobToBase64(data);
            return write_blob({
                blob: data,
                path: 'inruting/' + path,
                directory: Directory.Data,
                // encoding: Encoding.UTF8,
                on_fallback(error) {
                    console.error(error);
                }
            });
        }
        return Filesystem.writeFile({
            path: 'inruting/' + path,
            data: data,
            directory: Directory.Data,
            // encoding: Encoding.UTF8,
        });
    },
    /**
     * 
     * @param {String} path 
     * @param {String} url 
     * @returns {Object}
     */
    async download(path, url) {
        axios
            .get(url, {
                // responseType: 'arraybuffer' // For Base64
                responseType: 'blob'
            })
            .then(async response => {
                // const base64 = await this.axiosToBase64(response);
                // return this.writeFile(path, base64);
                return this.writeFile(path, response.data)
            })
            .catch(ex => {
                console.error(ex);
                return false;
            });
    },
    async readFile(path) {
        const contents = await Filesystem.readFile({
            path: 'inruting/' + path,
            directory: Directory.Data,
            // encoding: Encoding.UTF8,
        });

        return contents;
    },
    async deleteFile(path) {
        return Filesystem.deleteFile({
            path: 'inruting/' + path,
            directory: Directory.Data,
        });
    },
    async deleteDir(path) {
        await Filesystem.rmdir({
            path: 'inruting/' + path,
            directory: Directory.Data,
            recursive: true,
        }).catch(() => true);
    },
    async readDir(path) {
        await Filesystem.readdir({
            path: 'inruting/' + path,
            directory: Directory.Data,
        });
    },
    async readDirRecursive(path) {
        let exists = false;
        try {
            exists = await Filesystem.stat({
                path: 'inruting/' + path,
                directory: Directory.Data,
            });
        } catch (e) {
            console.log('Dir does not exist yet: ' + path);
        }
        if (!exists) {
            return Promise.resolve([]);
        }
        let fullList = [];
        return Filesystem.readdir({
            path: 'inruting/' + path,
            directory: Directory.Data,
        }).then(async list => {
            for (let file of list.files) {
                if (!file.match(/\w+\.\w+$/)) {
                    // is dir
                    let tmpList = await this.readDirRecursive(path + '/' + file);
                    fullList = fullList.concat(tmpList);
                } else {
                    fullList.push(path+'/'+file);
                }
            }
            return fullList;
        }).catch(() => []);
    },
    async mkdir(path) {
        let exists = false;
        try {
            exists = await Filesystem.stat({
                path: 'inruting/' + path,
                directory: Directory.Data,
            });
        } catch (e) {
            console.log('Dir does not exist yet: ' + path);
        }
        if (exists) {
            return Promise.resolve();
        }
        return Filesystem.mkdir({
            path: 'inruting/' + path,
            recursive: true,
            directory: Directory.Data,
            // encoding: Encoding.UTF8,
        });
    },
    async stat(path) {
        return Filesystem.stat({
            path: 'inruting/' + path,
            directory: Directory.Data,
        });
    },
    // async getUri(path) {
    //     return Filesystem.getUri({
    //         path: 'inruting/' + path,
    //         directory: Directory.Data,
    //     });
    // },
    blobToBase64(blob) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    },
    axiosToBase64(response) {
        let filedata = window.btoa(
            new Uint8Array(response.data)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        return `data:${response.headers['content-type'].toLowerCase()};base64,${filedata}`;
    },
    convertFileSrc(uri) {
        return Capacitor.convertFileSrc(uri);
    },
    async localCategoryIcon(cat) {
        if (!this._cache_category_icons) {
            this._cache_category_icons = {};
        } else if (this._cache_category_icons[cat.id]) {
            return this._cache_category_icons[cat.id];
        }
        if (!cat.icon_url || cat.icon_url.length == 0) {
            return cat.icon_url;
        }
        const extension = cat.icon_url.match(/\/\w+\.(\w+)($|\?)/)[1];
        const image = await this.stat('multimedia/categories/' + cat.id + '.' + extension);
        let icon = '';
        if(image) {
            icon = this.convertFileSrc(image.uri);
        } else {
            icon = cat.icon_url;
        }
        return this._cache_category_icons[cat.id] = icon;
    }
}