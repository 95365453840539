<template>
  <ion-page>
    <common-header>{{ $t('map.title') }}</common-header>
    <ion-content :fullscreen="false" :scroll-y="false">
        <DataLoadingScreen :isLoading="isLoading" :updating="updating" :error="loading_error" :percent="loading_percent" />
        <div class="map_page_container" v-if="!isLoading">
            <keep-alive>
                <gcamins-map
                    :bounds="workbounds"
                    :sidebar_tab="selected_sidebar_tab"
                    :feature_param="selected_feature"
                    :start_location="start_location"
                    :ref="setMapRef"
                    :key="'map'"></gcamins-map>
            </keep-alive>
        </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from '@ionic/vue';
import CommonHeader from "@/components/CommonHeader.vue";
import GcaminsMap from "@/components/GcaminsMap.vue";
import DataLoadingScreen from "@/components/DataLoadingScreen.vue";
import ApiStorage from "@/api/storage";
import { decompressFromEncodedURIComponent } from 'lz-string'

/**
 * Map page will stay hidden until all the API
 * data has been loaded, at least partially.
 * 
 * App state is to be monitored to know when
 * the data is ready.
 */
export default  {
    name: 'Map',
    components: {
        IonContent,
        IonPage,
        CommonHeader,
        GcaminsMap,
        DataLoadingScreen,
    },
    props: {
        sidebar_tab: {
            type: String,
            required: false
        },
    },
    data() {
        return {
            isLoading: true,
            loading_error: null,
            loading_percent: 0,
            updating: null,
            workbounds: null,
            selected_feature: null,
            selected_sidebar_tab: null,
            start_location: null,
            map: null,
        }
    },
    watch: {
        /**
         * The selected_feature URL parameter has to be
         * processed and sent to the map.
         */
        $route(newRoute) {
            if(newRoute.name != 'map') {
                return;
            }
            if('selected_feature' in newRoute.query && newRoute.query.selected_feature) {
                this.selected_feature = JSON.parse(decompressFromEncodedURIComponent(decodeURIComponent(newRoute.query.selected_feature)));
            } else {
                this.selected_feature = null;
                if(newRoute.hash != '#'+this.selected_sidebar_tab) {
                    this.selected_sidebar_tab = newRoute.hash.replace('#', '');
                }
            }
            if('start_location' in newRoute.query && newRoute.query.start_location) {
                this.start_location = 1;
                this.$nextTick(() => {
                    this.start_location = null;
                });
            }
        },
    },
    async mounted() {
        this.isLoading = true;
        if(!this.workbounds) {
            this.workbounds = await ApiStorage.get('config.workbounds');
        }

        if('selected_feature' in this.$route.query && this.$route.query.selected_feature) {
            this.selected_feature = JSON.parse(decompressFromEncodedURIComponent(decodeURIComponent(this.$route.query.selected_feature)));
        } else {
            this.selected_feature = null;
        }

        if('start_location' in this.$route.query && this.$route.query.start_location) {
            this.start_location = 1;
        }

        if(this.sidebar_tab) {
            this.selected_sidebar_tab = this.sidebar_tab.replace('#', '');
        }

        this.isLoading = this.$getState('isLoadingPartial') === false ? false : true;
        this.loading_error = this.$getState('loading_error') || null;
        this.loading_percent = this.$getState('buffer_percent') || 0;
        this.updating = this.$getState('updating');

        this.emitter.on('app-state-updated', async (newState) => {
            if(!this.workbounds) {
                this.workbounds = await ApiStorage.get('config.workbounds');
            }
            this.isLoading = Object.prototype.hasOwnProperty.call(newState, 'isLoadingPartial') ? newState.isLoadingPartial : this.isLoading;
            this.loading_error = Object.prototype.hasOwnProperty.call(newState, 'loading_error') ? newState.loading_error : this.loading_error;
            this.loading_percent = Object.prototype.hasOwnProperty.call(newState, 'buffer_percent') ? newState.buffer_percent : this.loading_percent;
            this.updating = this.$getState('updating');
        });
    },
    methods: {
        setMapRef(el) {
            if(el) {
                this.map = el;
                this.forceMapResize(); // This worked
            }
        },
        forceMapResize() {
            if(this.map) {
                this.$nextTick(() => {
                    this.map.triggerResize();
                });
            }
        }
    }
}
</script>