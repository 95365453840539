<template>
    <img :src="current_icon" :title="getCategoryName()" />
</template>

<script>
// Ionic components
// import { IonChip, IonAvatar, IonLabel } from '@ionic/vue';
import ApiFilesystem from '@/api/filesystem';

export default {
    name: 'GCaminsCategoryImg',
    data() {
        return {
            base_url: process.env.BASE_URL,
            fallback_icon: process.env.BASE_URL+'assets/img/icon-fallback.png',
            current_icon: process.env.BASE_URL+'assets/img/icon-fallback.png',
        }
    },
    props: {
        category: {
            type: [Object,Number],
            custom: true,
        },
    },
    watch: {
        category() {
            this.setIcon();
        }
    },
    async mounted() {
        this.setIcon();
    },
    methods: {
        async setIcon() {
            if(!this.category) {
                this.current_icon = this.fallback_icon;
            } else if(this.$checkConnection() || this.$isBrowser()) {
                this.current_icon = this.category.icon_url;
            } else {
                this.current_icon = await ApiFilesystem.localCategoryIcon(this.category);
            }
        },
        getCategoryName() {
            if(!this.category) {
                return '';
            }
            if(!this.category.name[this.$getLocale()]) {
                return this.category.name[Object.keys(this.category.name)[0]];
            }
            return this.category.name[this.$getLocale()];
        },
    }
}
</script>