<template>
    <div class="info-tab info-tab-lattice" v-if="loaded && points">
        <h3 class="info-title">{{ $t('print.routing_points') }}</h3>
        <template v-for="feature in points" v-bind:key="feature.id">
            <div class="print-waypoint">
                <!-- <ion-header>
                    <ion-title>
                        <div class="ion-text-wrap">
                            {{ getFeatureText(feature.name) }}
                        </div>
                    </ion-title>
                </ion-header> -->
                <h3>{{ getFeatureName(feature) }}</h3>

                <div class="map_frame" style="height: 200px;">
                    <l-map
                        @ready="onMapInit"
                        :use-global-leaflet="true"
                        :zoom-animation="true"
                        :center="[feature.point.coordinates[1], feature.point.coordinates[0]]"
                        :zoom="15"
                        style="position: inherit; width: 100%; min-height: 150px; max-height: 200px;">
                        <l-control-scale position="bottomleft"></l-control-scale>
                        <l-tile-layer
                            v-for="tileProvider in baseLayers"
                            :key="tileProvider.url"
                            :name="tileProvider.name"
                            :visible="tileProvider.visible"
                            :url="tileProvider.url"
                            :attribution="tileProvider.attribution"
                            layer-type="base">
                        </l-tile-layer>
                        <l-marker
                            v-if="category_icons[feature.categories[0].id]"
                            :lat-lng="[feature.point.coordinates[1], feature.point.coordinates[0]]">
                            <l-icon
                                :icon-url="category_icons[feature.categories[0].id]"
                                :icon-size="[25, 25]"
                                :icon-anchor="[12.5, 12.5]" />
                        </l-marker>
                    </l-map>
                </div>

                <div>
                    <gcamins-categories-show :categories="feature.categories"></gcamins-categories-show>
                </div>

                <div v-if="feature.images && feature.images.length > 0"
                    class="media">
                    <img :src="feature.images[0].original_url">
                </div>
                
                <div class="description" v-if="feature.description" v-html="getFeatureText(feature.description)"></div>
            </div>
        </template>
    </div>
</template>

<script>
import ApiClient from '@/api/client';
import ApiStorage from "@/api/storage";
import ApiFilesystem from '@/api/filesystem';
import GCaminsCategoriesShow from '@/components/GCaminsCategoriesShow.vue'
import { isEmpty } from 'lodash';

export default {
    name: 'ArclineExtraInfo',
    components: {
        'gcamins-categories-show': GCaminsCategoriesShow,
    },
    props: {
        selected_feature: Object,
        workbounds: Object,
        baseLayers: Array,
    },
    data() {
        return {
            points: [],
            loaded: false,
            category_icons: {},
        }
    },
    async mounted() {
        const points_filter = this.makePointsFilter();
        const merge_info = await ApiClient.routingGetMergeInfo({
                ids: this.selected_feature.info.ids,
                geo_meta: {},
                points_filter: points_filter,
            })
            .then(response => {
                if(response.data) {
                    return response.data;
                } else {
                    return null;
                }
            });
        this.points = [];
        for(let point of merge_info.waypoints) {
            const pt = await ApiStorage.select('points', point.properties.id);
            if(pt) {
                this.points.push(pt);
            }
        }
        await this.getCategoryIcons();
        this.loaded = true;
        this.$emit('loaded', true);
    },
    methods: {
        getFeatureText(texts) {
            if (texts[this.$getLocale()]) {
                return texts[this.$getLocale()];
            }
            return texts[Object.keys(texts)[0]];
        },
        onMapInit(map_target) {
            map_target.invalidateSize(true);
        },
        async getCategoryIcons() {
            if(this.$checkConnection() || this.$isBrowser()) {
                for(let feature of this.points) {
                    if(!this.category_icons[feature.categories[0].id]) {
                        this.category_icons[feature.categories[0].id] = feature.categories[0].icon_url;
                    }
                }
            } else {
                for(let feature of this.points) {
                    if(!this.category_icons[feature.categories[0].id]) {
                        this.category_icons[feature.categories[0].id] = await ApiFilesystem.localCategoryIcon(feature.categories[0]);
                    }
                }
            }
        },
        getFeatureName(info) {
            if (info.name[this.$getLocale()]) {
                return info.name[this.$getLocale()];
            }
            return info.name[Object.keys(info.name)[0]];
        },
        makePointsFilter() {
            if('points_filter' in this.selected_feature) {
                return this.selected_feature.points_filter;
            }
            if(!this.$parent || !this.$parent.filter_functions) {
                return {};
            }
            let filter_functions = this.$parent.filter_functions['Modules\\Points\\Entities\\Point'];
            let filter_data = {};
            for(let fn of filter_functions) {
                if(fn.field) {
                    if(!isEmpty(fn.data)) {
                        filter_data[fn.field] = fn.data;
                    }
                } else {
                    // categories
                    let active = [];
                    for(let cat_id in fn.data) {
                        if(fn.data[cat_id]) {
                            active.push(cat_id);
                        }
                    }
                    filter_data['categories'] = active;
                }
            }
            return filter_data;
        },
        isLoaded() {
            return this.loaded;
        },
    }
};
</script>

<style>
div.print-waypoint {
    margin: 15px 0;
    padding: 0 25px;
}
</style>