<template>
    <div v-if="isLoading" class="generic-list">
        <LoadingScreen :isLoading="isLoading" />
        <ion-content :fullscreen="false" :scroll-y="false">
            <ion-item>
                <ion-label class="ion-text-center" text-wrap>
                    {{ $t('init.loading.map_tab') }}
                </ion-label>
            </ion-item>
        </ion-content>
    </div>
    <div v-else class="generic-list">
        <ion-item>
            {{ notice_text }}
        </ion-item>
        <keep-alive>
            <ion-item :key="'generic-list-0'">
                <ion-searchbar
                    animated
                    inputmode="search"
                    :disabled="filtering"
                    :value="featurefilter"
                    @ion-input="setFeatureFilter"
                    :placeholder="$t('map.filters.search.title')"
                    ></ion-searchbar>
                <ion-button color="secondary" slot="end" @click="filtering = !filtering">
                    <ion-icon slot="icon-only" :icon="filtering ? list_icon : filter_icon"></ion-icon>
                </ion-button>
            </ion-item>
        </keep-alive>
        <!-- keep alive is important to avoid losing the filters selection -->
        <keep-alive>
            <filters
                v-if="filtering"
                :key="'generic-filters'"
                @filter="applyFilters"
                :model="model"
                :categories="categories"
                :extra_filters="extra_filters"
                :profile_values="profile_field_values"
            ></filters>
            <div v-else class="list-container" :key="'generic-list-1'">
                <ion-list>
                    <template v-for="item in shown_items"  v-bind:key="item.id">
                        <!-- <ion-item button @click="featureSelected(item)" detail="false"
                            v-if="_simpleTextFilter(item.name) && _fieldsFilter(item)"> -->
                        <ion-item button @click="featureSelected(item)" detail="false">
                            <ion-avatar slot="start" class="ion-avatar-category-icon">
                                <category-img :category="item.categories[0]"></category-img>
                                <!-- <img :src="getCategoryIcon(item)" :title="getCategoryName(item)" /> -->
                            </ion-avatar>
                            <ion-label>{{ getItemName(item.name) }}</ion-label>
                        </ion-item>
                    </template>
                </ion-list>
                <div v-if="this.filtered_items.length > this.shown_items.length" class="ion-text-center">
                    <ion-button
                        color="secondary"
                        @click="setFilteredItems(this.shown_items.length+10)">
                        {{ $t('search.more_results') }}
                    </ion-button>
                </div>
            </div>
        </keep-alive>
    </div>
</template>

<script>
// Storage
import ApiStorage from "@/api/storage";

// Ionic components
import { IonList, IonItem, IonLabel, IonAvatar, IonButton, IonIcon, IonSearchbar, IonContent } from '@ionic/vue';
import { filterCircleSharp, listCircleSharp } from 'ionicons/icons';

// Other components
import { Toast } from '@capacitor/toast';
import LoadingScreen from '@/components/LoadingScreen.vue'
import GCaminsCategoryImg from '@/components/GCaminsCategoryImg.vue';

// Utils
import * as $ from "jquery";

// Filters
import Filters from '@/components/filters/Filters.vue';

export default {
    name: 'GenericList',
    components: {
        IonList,
        IonItem,
        IonLabel,
        IonAvatar,
        IonSearchbar,
        IonButton,
        IonIcon,
        IonContent,
        LoadingScreen,
        'filters': Filters,
        'category-img': GCaminsCategoryImg,
    },
    props: {
        all_categories: {
            type: Array,
            custom: true,
            default: () => { return []; },
        },
    },
    data() {
        return {
            resource: '',
            featureType: '',
            model: '',
            isLoading: true,
            items: [],
            filtered_items: [],
            shown_items: [],
            profile_field_values: {},
            categories: {},
            featurefilter: '',
            filtering: false,
            filter_functions: [],
            extra_filters: [],
            last_sort_location: null,

            filter_icon: filterCircleSharp,
            list_icon: listCircleSharp,
            // notice_text: this.$t('map.sidebar.points.notice'),
        }
    },
    computed: {
        notice_text() {
            return this.$t('map.sidebar.points.notice');
        }
    },
    async mounted() {
        this.categories = this.getModelCategories();
        this.profile_field_values = await this.getProfileValues();
        this.items = (await ApiStorage.getAll(this.resource)).filter(i => i!=null).sort(() => Math.random() - 0.5);

        // Initial filter by category
        this.filter_functions = [
            this._getCategoryFilterFunction(),
        ];

        this.setFilteredItems();

        this.isLoading = false;

        // Listen for location in order to sort lists
        this.$parent.map.on('locationfound', this._onLocationFound);
    },
    methods: {
        getModelCategories() {
            let result = {};
            this.all_categories.filter(c => {
                return c.type == this.model;
            })
            .forEach(c => {
                result[c.id] = c;
            });
            return result;
            // return ApiStorage.getAll('categories').then(categories => {
            //     let result = {};
            //     categories.filter(c => {
            //         return c.type == this.model;
            //     })
            //     .forEach(c => {
            //         result[c.id] = c;
            //     });
            //     return result;
            // });
        },
        getProfileValues() {
            return new Promise((resolve) => {
                return ApiStorage.getAll('profile_values.'+this.resource).then(result => {
                    let values = {};
                    for(let list of result.filter(e => e!=null)) {
                        values[list.id] = list;
                    }
                    return resolve(values);
                });
            });
        },
        getItemName(names) {
            if(!names[this.$getLocale()]) {
                return names[Object.keys(names)[0]];
            }
            return names[this.$getLocale()];
        },
        featureSelected(item) {
            this.$emit('featureListClick', {
                type: this.featureType,
                id: item.id,
                name: item.name,
                info: item,
            });
        },
        setFeatureFilter(event) {
            this.featurefilter = event.target.value;
            this.setFilteredItems();
        },
        _simpleTextFilter(name) {
            const text = this.getItemName(name);
            const filter_string = this.featurefilter.trim().toLowerCase();
            if(!filter_string || filter_string.length == 0) {
                return true;
            }
            return text.toLowerCase().indexOf(filter_string) > -1;
        },
        _fieldsFilter(item) {
            const filterable = {
                categories: item.categories.map(c => c.id),
                profile_field_values: this.profile_field_values[item.id]
            };
            for(let index in this.filter_functions) {
                const filter = this.filter_functions[ index ];
                if(!filter.run(filterable)) {
                    return false;
                }
            }
            return true;
        },
        applyFilters(functions) {
            this.filter_functions = functions;
            this.$emit('filter', {
                type: this.model,
                functions,
            });
            this.setFilteredItems();
            this.filtering = false;
        },
        setFilteredItems(amount) {
            amount = amount || 10;
            // Keep scroll position
            let scrollValue = 0;
            if($('.leaflet-sidebar-pane.active').parentNode) {
                scrollValue = $('.leaflet-sidebar-pane.active').parentNode.scrollTop;
            }

            this.sortByLocation();
            this.filtered_items = this.items.filter(item => {
                return this._simpleTextFilter(item.name) && this._fieldsFilter(item);
            });
            this.shown_items = this.filtered_items.slice(0, amount);

            // Set back scroll position.
            // This avoids having to scroll back down when a position
            // update triggers a list refresh.
            if($('.leaflet-sidebar-pane.active').parentNode) {
                $('.leaflet-sidebar-pane.active').parentNode.scrollTop = scrollValue;
            }
        },
        _getCategoryFilterFunction() {
            let selected_categories = {};
            for(let cat_id in this.categories) {
                selected_categories[cat_id] = this.categories[cat_id].is_visible[this.$getLocale()];
            }
            return {
                data: selected_categories,
                run: function(element) {
                    if (!('categories' in element)) {
                        return false;
                    }
                    for (var cat_id of element.categories) {
                        if(cat_id!=null && this.data[ cat_id ]) {
                            return true;
                        }
                    }
                    return false;
                }
            };
        },
        sortByLocation() {
            if(!this.$parent.last_location) {
                if(this.$parent.locatecontrol._active) {
                    Toast.show({
                        text: this.$t('map.location.pending'),
                    });
                }
                return;
            }
            const location = this.$parent.last_location;
            if(this.last_sort_location && this.last_sort_location.equals(location)) {
                return;
            }
            let d0 = null;
            let d1 = null;
            let dd = {};
            this.items.sort((a, b) => {
                if(a.point) {
                    d0 = a.id in dd ? dd[a.id] : window.L.CRS.Earth.distance(location, window.L.latLng([ a.point.coordinates[1], a.point.coordinates[0] ]));
                    d1 = b.id in dd ? dd[b.id] : window.L.CRS.Earth.distance(location, window.L.latLng([ b.point.coordinates[1], b.point.coordinates[0] ]));
                } else if(a.polyline) {
                    // Bad performance here, so:
                    // - Use only a third of the coordinates (divide by 3 without residue)
                    // - Store distance to reuse them in comparison (@var dd)
                    if(a.id in dd) {
                        d0 = dd[a.id];
                    } else {
                        d0 = Number.MAX_SAFE_INTEGER;
                        for(let subcoords of a.polyline.coordinates) {
                            for(let c = 0; c < subcoords.length; c++) {
                                if(!c || c % 5 === 0) {
                                    d0 = Math.min(d0,
                                        window.L.CRS.Earth.distance(location, window.L.latLng([ subcoords[c][1], subcoords[c][0] ]))
                                    );
                                }
                            }
                        }
                        dd[a.id] = d0;
                    }
                    if(b.id in dd) {
                        d1 = dd[b.id];
                    } else {
                        d1 = Number.MAX_SAFE_INTEGER;
                        for(let subcoords of b.polyline.coordinates) {
                            for(let c = 0; c < subcoords.length; c++) {
                                if(!c || c % 5 === 0) {
                                    d1 = Math.min(d1,
                                        window.L.CRS.Earth.distance(location, window.L.latLng([ subcoords[c][1], subcoords[c][0] ]))
                                    );
                                }
                            }
                        }
                        dd[b.id] = d1;
                    }
                }
                return d0 > d1 ? 1 : -1;
            });
            this.last_sort_location = location;
        },
        _onLocationFound(event) {
            if(this.last_sort_location && this.last_sort_location.equals(event.latlng)) {
                return;
            }
            this.setFilteredItems(this.shown_items.length);
        },
    }
}
</script>