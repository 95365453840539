<template>
  <div>
    <field-filter-string
      :ref="setRefSubcomponent"
      v-if="field.options[$getLocale()][1] == 'string'"
      :field="Object.assign(field, {function: 'string'})"
      :value="value"
      :register-filter="registerFilter"
      @input="handleInput"
      ></field-filter-string>
    <field-filter-number
      :ref="setRefSubcomponent"
      v-if="field.options[$getLocale()][1] == 'integer' || field.options[$getLocale()][1] == 'integer_minutes'"
      :field="Object.assign(field, {function: 'number'})"
      :value="value"
      :register-filter="registerFilter"
      @input="handleInput"
      ></field-filter-number>
  </div>
</template>

<script>
import FieldFilterString from '@/components/filters/FieldFilterString.vue';
import FieldFilterNumber from '@/components/filters/FieldFilterNumber.vue';

export default {
    name: 'FieldFilterScript',
    components: {
        'field-filter-string': FieldFilterString,
        'field-filter-number': FieldFilterNumber,
    },
    props:{
      field: Object,
      value: {
        type: String,
        default: () => { return null; }
      },
      registerFilter: Function,
    },
    mounted() {
      if(this.registerFilter) this.refSubcomponent[0].registerFilter(this.refSubcomponent[0]);
    },
    data() {
        return {
          refSubcomponent: [],
        }
    },
    methods: {
      handleInput(value) {
        this.$emit('input', value);
      },
      getFilterFunction() {
        return this.refSubcomponent[0].getFilterFunction();
      },
      clear() {
        this.refSubcomponent[0].clear();
      },
      getInitialValue() {
        return this.refSubcomponent[0].getInitialValue();
      },
      setRefSubcomponent(el) {
          if(el) {
              this.refSubcomponent.push(el);
          }
      },
    },
  };
</script>

<style>
</style>
