<script>
import GenericList from "./GenericList.vue";

export default {
    name: "PolylineList",
    extends: GenericList,
    props: {
        current_tab: String,
    },
    data() {
        let lang_extra_field = {};
        lang_extra_field[this.$getLocale()] = this.$t(
            "map.filters.duration.name"
        );
        return {
            resource: "polylines",
            featureType: "Polyline",
            model: "Modules\\Polylines\\Entities\\Polyline",
            extra_filters: [
                {
                    slug: "meta_duration_foot",
                    function: "duration",
                    name: lang_extra_field,
                },
            ],
            // notice_text: this.$t('map.sidebar.polylines.notice'),
        };
    },
    computed: {
        notice_text() {
            return this.$t("map.sidebar.polylines.notice");
        },
    },
    watch: {
        current_tab(val) {
            if (val == "sidebar_polylines") {
                // if('polylines_search' in this.$route.query) {
                // this.featurefilter = this.$route.query.polylines_search;
                // }
                this.$emit("vectorlayer", "polylines", true);
                this.$emit("vectorlayer", "start-end-markers", true);
            }
        },
        $route(newRoute) {
            if (
                newRoute.name != "map" ||
                this.current_tab != "sidebar_polylines"
            ) {
                return;
            }
            // if('polylines_search' in newRoute.query) {
            //     this.featurefilter = newRoute.query.polylines_search;
            // }
        },
    },
    methods: {
        _fieldsFilter(item) {
            const filterable = {
                categories: item.categories.map((c) => c.id),
                profile_field_values: this.profile_field_values[item.id],
                meta_duration_foot: item.meta_duration_foot,
            };
            for (let index in this.filter_functions) {
                const filter = this.filter_functions[index];
                if (!filter.run(filterable)) {
                    return false;
                }
            }
            return true;
        },
    },
};
</script>