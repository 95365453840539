<script>
import ApiFilesystem from '@/api/filesystem';
import GenericInfo from './GenericInfo.vue';

export default {
    name: 'PointInfo',
    extends: GenericInfo,
    watch: {
        feature(val) {
            if(val) {
                this.setFeature();
            }
        },
        selected_feature() {
            if(!this.selected_feature || this.selected_feature.type != this.featureType) {
                this.unsetFeature();
            }
        }
    },
    data() {
        return {
            resource: 'points',
            featureType: 'Point',
            model: 'Modules\\Points\\Entities\\Point',

            base_url: process.env.VUE_APP_WEBAPP_URL,
            icon_base_url: process.env.BASE_URL,

            marker: null,
            shadow: null,
        }
    },
    methods: {
        async setFeature() {
            this.unsetFeature();
            let size = [25, 25];
            let cat = this.feature.categories[0];

            let icon_url = '';
            if(!this.$checkConnection() && !this.$isBrowser()) {
                icon_url = await ApiFilesystem.localCategoryIcon(cat)
            } else {
                icon_url = cat && cat.icon_url ? cat.icon_url : this.icon_base_url+'assets/img/icon-fallback.png';
            }

            const icon = new window.L.Icon({
                iconUrl: icon_url,
                iconSize: size
            });
            const location = new window.L.LatLng(
                this.feature.point.coordinates[1],
                this.feature.point.coordinates[0]
            );
            this.marker = window.L.marker(location, {
                    icon: icon,
                    interactive: false
                });

            if(!this.prevent_focus &&
                (this.leafletObject.getZoom() <= 15
                || !this.leafletObject.getBounds().contains(location))
                ) {
                this.leafletObject.flyTo(location, 15, {animate: false, noMoveStart: true});
            }
            
            if(!this.shadow) {
                var div_circle = window.L.divIcon({
                    className: 'circle-marker-shadow',
                    iconSize: [30, 30],
                    iconAnchor: [15, 15],
                    zIndexOffset: 1000,
                    zIndex: 436,
                });
                this.shadow = window.L.marker(location, {
                    icon: div_circle,
                    zIndexOffset: -1,
                    zIndex: 435,
                    interactive: false
                } );
            } else {
                this.shadow.setLatLng(location);
            }
            this.shadow.setZIndexOffset(-1);
            this.shadow.addTo(this.leafletObject);

            this.marker.setZIndexOffset(1000);
            this.marker.addTo(this.leafletObject);

            this.marker.on('click', () => {
                this.$emit('featureListClick', this.selected_feature);
            });
        },
        unsetFeature() {
            if(this.marker) {
                this.marker.remove();
                this.marker = null;
            }
            if(this.shadow) {
                this.shadow.remove();
            }
        },
        applyDirections() {
            const coords = this.feature.point.coordinates[1]+','+this.feature.point.coordinates[0];
            window.open('https://www.google.com/maps/dir/?api=1&destination='+coords, '_blank');
        },
        applyFocus() {
            const center = this.marker.getLatLng();
            this.$parent.sidebar.close();
            this.leafletObject.panTo(center);
        },
    },
};
</script>

<style>
.leaflet-marker-icon {
    z-index: 1 !important;
}
.circle-marker-shadow {
    background: #E99B00; /* color of the circle */
    border-radius: 50%; /* make the div a circular shape */
    box-shadow: 4px 4px 3px grey; /* see http://www.w3schools.com/css/css3_shadows.asp */
    -moz-box-shadow: 4px 4px 3px grey;
    -webkit-box-shadow: 4px 4px 3px grey;
    z-index: 0 !important;
    width: 30px;
    height: 30px;
    filter: blur(2px);
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
}
</style>