import { Drivers, Storage } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { isEmpty, union } from 'lodash'
// import {stringifyAsync} from 'js-coroutines'

const driverOrder = [
    CordovaSQLiteDriver._driver,
    Drivers.IndexedDB,
    Drivers.LocalStorage, // TODO: do not use
];

/**
 * Database storage with fallback to multiple
 * underlying mechanisms.
 * 
 * It keeps an in-memory cache to avoid disk usage
 * and more inmediate response when possible.
 * 
 * All database operations are asyncronous.
 * 
 * This class was supposed to be simple but it is not.
 * Sadly, Chrome's IndexedDB implementation is garbage.
 * It doesn't allow simultaneous write/read, and the
 * write performance is absurdly slow.
 * Meanwhile, in Firefox we still need to keep locks
 * to avoid simultaneous operations. You would think
 * that parallel execution should be the best choice,
 * but blocks itself so it is not.
 * 
 * Ideally localStorage must not be used since the data
 * will most probably exceed the allowed size. The
 * fallback should be a file-based driver.
 * 
 * In the end, just keep everything in memory then
 * save the whole thing to the database in a single
 * key-value pair.
 */
export default {
    /** Status information */
    _state: {
        creating: false,
        // halt_write: 0,
        // read_lock: false,
        queue: {},
    },
    _storage: null,
    _cache_drive: {},
    _unsaved: {},
    /**
     * Start or find previously started storage.
     *
     * @param {Function} operation
     * @return {*} 
     */
    withStorage( operation ) {
        if(!this._storage) {
            this._state.creating = true;
            this._storage = new Storage({
                name: '__inruting_app',
                driverOrder: driverOrder,
            });
            return this._storage.create()/*.defineDriver(CordovaSQLiteDriver)*/.then(() => {
                this._storage.create();
                this._state.creating = false;
                return operation(this._storage);
            });
        } else if(this._state.creating) {
            // Wait a millisec, the DB is not yet ready
            return new Promise(resolve => setTimeout(resolve, 1)).then(() => {
                return this.withStorage(operation);
            });
        } else {
            return operation(this._storage);
        }
    },
    /**
     * Whas this key cached previously?
     *
     * @param {String} key
     * @return {Boolean} 
     */
    _isCachedKey(key) {
        return key in this._cache_drive;
    },
    /**
     * Retrieve a cached key.
     *
     * @param {String} key
     * @return {Promise} 
     */
    _getCachedKey(key) {
        return new Promise(resolve => { resolve(this._cache_drive[key]); });
    },
    /**
     * Set a key in cache and update the key index.
     *
     * @param {String} key
     * @param {*} value
     * @return {*} 
     */
    _setCachedKey(key, value) {
        if (value === null || isEmpty(value)) {
            return value;
        }
        if (this._cache_drive['_keys'] && !this._cache_drive['_keys'].includes(key)) {
            this._cache_drive['_keys'].push(key);
        }
        this._cache_drive[key] = value;
        return value;
    },
    /**
     * Merge new keys with existing.
     *
     * @param {String} key
     * @param {*} value
     * @return {*} 
     */
    _mergeCachedKey(key, value) {
        if (value === null || isEmpty(value)) {
            return value;
        }
        this._cache_drive[key] = union(this._cache_drive[key] || [], value);
        return value;
    },
    /**
     * Delete a value from storage.
     *
     * @param {String} key
     */
    _removeCachedKey(key) {
        delete this._cache_drive[key];
    },
    /**
     * Get a value by key. If not in cache
     * then find it in database.
     *
     * @param {String} key
     * @return {*}
     */
    get(key) {
        if (this._isCachedKey(key)) {
            return this._getCachedKey(key);
        }

        return this.getStored(key);
    },
    /**
     * Get a key from database while keeping
     * it on cache.
     *
     * @param {String} key
     * @return {Promise}
     */
    getStored(key) {
        return this.waitReadLock(() => {
            // this._state.read_lock = true;
            return this.withStorage(storage => {
                return storage.get(key).then(data => {
                    // this._state.read_lock = false;
                    return this._setCachedKey(key, data);
                });
            });
        });
    },
    /**
     * Set a key in cache, no database writting.
     *
     * @param {String} key
     * @param {*} data
     * @return {*}
     */
    set(key, data) {
        this._setCachedKey(key, data);
        // return this.withStorage(storage => {
        //     return this.waitHaltWrite(() => {
        //         return storage.set(key, data);
        //     });
        // });
        return data;
    },
    /**
     * Force saving a value to database and
     * to cache.
     *
     * @param {String} key
     * @param {*} data
     * @return {Promise}
     */
    store(key, data) {
        this._setCachedKey(key, data);
        return this.withStorage(storage => {
            return this.waitHaltWrite(() => {
                return storage.set(key, data);
            });
        });
    },
    /**
     * Save the whole cache object into the
     * database.
     *
     * @return {Promise}
     */
    async storeCacheDrive() {
        // let data = await stringifyAsync(this._cache_drive);
        let data = JSON.stringify(this._cache_drive);
        return this.withStorage(storage => {
            // return this.waitHaltWrite(() => {
                return storage.set('_cache_drive', data);
            // });
        });
    },
    /**
     * Retrieve the whole cache object from
     * the database.
     *
     * @return {Promise}
     */
    async restoreCacheDrive() {
        return this.waitReadLock(() => {
            return this.withStorage(storage => {
                return storage.get('_cache_drive').then(data => {
                    let drive;
                    try {
                        drive = JSON.parse(data)
                    } catch (e) {
                        drive = null;
                    }
                    return this._cache_drive = drive || {};
                });
            });
        });
    },
    /**
     * Set a key value in cache, and merge it.
     *
     * @param {String} table
     * @param {*} element
     * @return {*}
     */
    async insert(table, element) {
        const key = table + '.' + element.id;
        // Set cached keys too
        // if (this._isCachedKey('_keys.'+table)) {
            // let keys = await this._getCachedKey('_keys.' + table);
            // keys.push(element.id);
            // this._setCachedKey('_keys.'+table, keys);
            this._mergeCachedKey('_keys.'+table, [key]);
        // }
        return this.set(key, element);
    },
    /**
     * Same as insert right now.
     *
     * @param {String} batch_name
     * @param {String} table
     * @param {*} element
     * @return {*}
     */
    preInsert(batch_name, table, element) {
        const key = table + '.' + element.id;
        if (!(batch_name in this._unsaved)) {
            this._unsaved[batch_name] = {};
        }
        this._unsaved[batch_name][key] = { table, element };
        // this._mergeCachedKey('_keys.' + table, [element.id]);
        return this._setCachedKey(key, element);
        // return new Promise(resolve => {
        //     // return resolve(true);
        //     resolve(this._setCachedKey(key, element));
        // });
    },
    /**
     * Get the legacy _unsaved property.
     *
     * @return {Object}
     */
    getAllUnsaved() {
        return this._unsaved;
    },
    /**
     * Save unsaved batches. Legacy.
     *
     * @param {String} batch_name
     * @return {Boolean}
     */
    async insertUnsavedBatch(batch_name) {
        let mergeKeys = {};
        for (let key in this._unsaved[batch_name]) {
            await this.set(
                key,
                this._unsaved[batch_name][key].element
            );
            // Set cached keys too
            // if (this._isCachedKey('_keys.'+this._unsaved[batch_name][key].table)) {
            //     let keys = await this._getCachedKey('_keys.' + this._unsaved[batch_name][key].table);
            //     keys.push(this._unsaved[batch_name][key].element.id);
            //     this._setCachedKey('_keys.'+this._unsaved[batch_name][key].table, keys);
            // }
            if(!mergeKeys[this._unsaved[batch_name][key].table]) {
                mergeKeys[this._unsaved[batch_name][key].table] = [];
            }
            mergeKeys[this._unsaved[batch_name][key].table].push(this._unsaved[batch_name][key].element.id);
            // this._mergeCachedKey('_keys.' + this._unsaved[batch_name][key].table, [this._unsaved[batch_name][key].element.id]);
        }
        for(let table in mergeKeys) {
            this._mergeCachedKey('_keys.' + table, mergeKeys[table]);
        }
        delete this._unsaved[batch_name];
        return true;
    },
    /**
     * Legacy method to impose write locks.
     *
     * @param {Function} operation
     * @return {*} 
     */
    waitHaltWrite(operation) {
        // if (this._state.halt_write > 0) {
        //     // Stop writting for a millisec, we need to read
        //     const waitForWrite = resolve => {
        //         if (this._state.halt_write > 0) {
        //             setTimeout(() => {
        //                 waitForWrite(resolve);
        //             }, 1);
        //         } else {
        //             resolve(operation());
        //         }
        //     };
        //     return new Promise(waitForWrite).then(value => {
        //         return value;
        //     });
        // }
        return operation();
    },
    /**
     * Legacy method to impose read locks.
     *
     * @param {Function} operation
     * @return {*} 
     */
    waitReadLock(operation) {
        // if (this._state.read_lock) {
        //     // Stop reading until lock is released
        //     const waitForRead = resolve => {
        //         if (this._state.read_lock) {
        //             setTimeout(() => {
        //                 waitForRead(resolve);
        //             }, 1);
        //         } else {
        //             resolve(operation());
        //         }
        //     };
        //     return new Promise(waitForRead).then(value => {
        //         return value;
        //     });
        // }
        return operation();
    },
    /**
     * Get all keys associated to a table.
     *
     * @param {String} table
     * @return {Object} 
     */
    getKeys(table) {
        if (this._isCachedKey('_keys.'+table)) {
            return this._getCachedKey('_keys.'+table);
        }

        const keyParse = (keys) => {
            const cut = table.length + 1;
            let data = keys.filter(k => k.startsWith(table+'.'))
                    .map(k => {
                        const id = k.substr(cut)
                        return isNaN(Number(id)) ? id : Number(id);
                    });
            return this._setCachedKey('_keys.'+table, data);
        }

        if (this._isCachedKey('_keys')) {
            let parsed = keyParse(this._cache_drive['_keys']);
            if (parsed.length > 0) {
                return Promise.resolve(parsed);
            }
        }

        // this._state.halt_write++;
        return this.waitReadLock(() => {
            // this._state.read_lock = true;
            return this.withStorage(storage => {
                return storage.keys().then(rawKeys => {
                    // this._state.halt_write--;
                    // this._state.read_lock = false;

                    this._mergeCachedKey('_keys', rawKeys);

                    return keyParse(rawKeys);
                });
            });
        });
    },
    /**
     * Get a list of ids and their last updated
     * date from a table.
     *
     * @param {String} table
     * @return {Promise} 
     */
    getUpdatedAt(table) {
        return this.getAll(table).then(result => {
            let update = {};
            result.forEach(r => {
                if(r) {
                    update[Number(r.id)] = r.updated_at || '';
                }
            });
            return update;
        });
    },
    /**
     * Delete a key from a table. Both in cache
     * and in Storage.
     *
     * @param {String} table
     * @param {String} id
     * @return {Promise}
     */
    async delete(table, id) {
        const key = table + '.' + id;
        // Delete cache right away to avoid async problems
        this._removeCachedKey(key);
        // Delete from cached keys too
        if (this._isCachedKey('_keys.'+table)) {
            let keys = await this._getCachedKey('_keys.' + table);
            keys = keys.filter(keyval => {
                return keyval != id; 
            });
            this._setCachedKey('_keys.'+table, keys);
        }
        return this.withStorage(storage => {
            return storage.remove(key);
        });
    },
    /**
     * Get the value of a key in a table.
     *
     * @param {String} table
     * @param {String} id
     * @return {*} 
     */
    select(table, id) {
        const key = table+'.'+id;
        return this.get(key);
    },
    /**
     * Get all the keys associated to a table.
     *
     * @param {String} table
     * @return {Array}
     */
    getAll(table) {
        if (table in this._state.queue) {
            // If already querying return the same promise so it only resolves once.
            // It actually works.
            return this._state.queue[table];
        }
        // var startTime = performance.now();
        // console.log(`Call to getAll on ${table}`);
        return this._state.queue[table] = this.getKeys(table).then(async rawKeys => {
            const data = [];

            for(let key of rawKeys) {
                data.push(await this.select(table, key));
            }

            // var endTime = performance.now();
            // console.log(`Call to getAll on ${table} took ${endTime - startTime} milliseconds`);
            delete this._state.queue[table];
            // Avoid null values
            return data.filter(i => i!=null);
        });
    },
}