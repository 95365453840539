import { isPlatform } from '@ionic/vue';
import axios from 'axios'
/* Moment.js */
import * as moment from 'moment';
import ApiStorage from "@/api/storage";
import { Device } from '@capacitor/device';
import { isEqual } from 'lodash';
// import { HTTP } from '@ionic-native/http';
// import { File } from '@ionic-native/file';
// import { Http } from '@capacitor-community/http';
// import { Directory, Filesystem, Encoding } from '@capacitor/filesystem';
import { Browser } from '@capacitor/browser';

const API_LOGIN_URL     = process.env.VUE_APP_API_LOGIN_URL;
const BACKEND_LOGIN_URL = process.env.VUE_APP_BACKEND_LOGIN_URL;
const API_URL           = process.env.VUE_APP_API_URL;
const API_VERSION       = process.env.VUE_APP_API_VERSION;
const API_CLIENT_ID     = process.env.VUE_APP_API_CLIENT_ID;
const API_CLIENT_SECRET = process.env.VUE_APP_API_CLIENT_SECRET;
const API_SCOPES        = process.env.VUE_APP_API_SCOPES || "languages.read categories.read profile_fields.read profile_fields.values.read tilelayers.read incidents->incidents.read_visible lattice->arclines.read_visible points->points.read_visible polylines->polylines.read_visible girona->girona.upload_images";

/**
 * The client class communicates with the API
 * and does all de bussiness to ensure data
 * sync.
 * 
 * Uses ApiStorage to communicate with the
 * database.
 * 
 */
export default {
    /** Storage */
    storage: null,
    /** Locale for remote requests until changed */
    locale: 'en_US',
    /** Browser and mobile device may need different behaviours */
    _isBrowser: false,
    /** Build URLs from environment */
    _base_url: API_URL + API_VERSION,
    /** Keep track of progress */
    _counts: {
        currentListSize: 0,
        currentListCount: 0,
    },
    /** Once login is performed, keep the api token */
    _api_token: '',
    /** Dictionary of API endpoints */
    resources: {
        config: {
            endpoints: {
                'init': '/init',
            }
        },
        categories: {
            endpoints: {
                'id_list': '/categories/id_list',
                'list': '/categories/list',
            }
        },
        profile_fields: {
            endpoints: {
                'id_list': '/profile_fields/id_list',
                'list': '/profile_fields/list',
            }
        },
        tilelayers: {
            endpoints: {
                'id_list': '/tilelayers/id_list',
                'list': '/tilelayers/list',
            }
        },
        points: {
            endpoints: {
                'id_list': '/points/id_list',
                'list': '/points/list',
                'export_geojson': '/points/export/geojson',
                'export_gpx': '/points/export/gpx',
                'export_kml': '/points/export/kml',
                'export_shp': '/points/export/shp',
            }
        },
        polylines: {
            endpoints: {
                'id_list': '/polylines/id_list',
                'list': '/polylines/list',
                'export_geojson': '/polylines/export/geojson',
                'export_gpx': '/polylines/export/gpx',
                'export_kml': '/polylines/export/kml',
                'export_shp': '/polylines/export/shp',
            }
        },
        lattice_groups: {
            endpoints: {
                'id_list': '/lattice/lattice_groups/id_list',
                'list': '/lattice/lattice_groups/list',
            }
        },
        arclines: {
            endpoints: {
                'id_list': '/lattice/arclines/id_list',
                'list': '/lattice/arclines/list',
                'filter_id_list': '/lattice/arclines/filter_id_list',
            }
        },
        lattice_routing: {
            endpoints: {
                'get_nearest_point': '/lattice/lattice_routing/getNearestPoint',
                'get_route': '/lattice/lattice_routing/getRoute',
                'merge_info': '/lattice/arclines/mergeInfo',
                'export_geojson': '/lattice/lattice_routing/export/geojson',
                'export_gpx': '/lattice/lattice_routing/export/gpx',
                'export_kml': '/lattice/lattice_routing/export/kml',
                'export_shp': '/lattice/lattice_routing/export/shp',
            }
        },
        incidents: {
            endpoints: {
                'upload': '/incidents/storeExternal',
                'id_list': '/incidents/id_list',
                'list': '/incidents/list',
            }
        },
        girona: {
            endpoints: {
                'upload': '/girona/upload',
                'locations': '/girona/upload_locations',
            }
        },
    },
    /**
     * Define the device type (browser or not). Then restore
     * the cache_drive if available.
     * 
     * @param {Boolean} isBrowser
     * @returns {Object}
     */
    async init(isBrowser) {
        this._isBrowser = isBrowser;
        return await ApiStorage.restoreCacheDrive();
    },
    /**
     * Progress listener for counting all the
     * open processes and inform the user.
     */
    _progressListener: function () { },
    /**
     * Progress listener for counting the
     * finished processes.
     */
    _finishProgressListener: function () { },
    /**
     * Count on the current list.
     */
    _countProgress:  function () {
        this._counts.currentListCount++;
        this._progressListener(this._counts.currentListSize, this._counts.currentListCount);
    },
    /**
     * If a process ends early (for good reasons),
     * this method will return a valid result.
     * 
     * @returns {Promise<Boolean>}
     */
    _getFinishPromise: function () {
        return new Promise(resolve => {
            this._finishProgressListener();
            return resolve(true);
        });
    },
    /**
     * Setters for the listeners
     * @param {function} fn 
     */
    setProgressListener(fn) {
        this._progressListener = fn;
    },
    setFinishProgressListener(fn) {
        this._finishProgressListener = fn;
    },
    /**
     * Build API url around and enpoint.
     * @param {String} endpoint 
     * @returns {String}
     */
    _makeApiUrl(endpoint) {
        return this._base_url + endpoint + '?lang=' + this.locale;
    },
    /**
     * Basic petition to the API. Will POST
     * if params is set.
     * 
     * @param {String} endpoint 
     * @param {Object} params 
     * @returns {Promise}
     */
    async _fetch(endpoint, params) {
        if (!await this.executeLogin()) {
            return false;
        }
        const options = {
            headers: {
                Authorization: 'Bearer ' + this._api_token,
            }
        };
        let request;
        if(params) {
            request = axios.post(this._makeApiUrl(endpoint), params, options);
        } else {
            request = axios.get(this._makeApiUrl(endpoint), options);
        }
        return request.catch((error) => {
            console.error(error);
            return false;
        });
    },
    /**
     * Paginated petition to the API. Will POST
     * if params is set.
     * 
     * @param {String} endpoint 
     * @param {Object} params 
     * @returns {Promise}
     */
    async _fetchPaginated(resource_name, ids) {
        let page = 0;
        let limit = 1000;
        let pageIds = [];
        let queries = [];
        while (page * limit <= ids.length) {
            pageIds = ids.slice( (page*limit), (page+1)*limit );
            queries.push(this._fetch(this.resources[resource_name].endpoints.list, {
                ids: pageIds,
            }));
            page++;
        }
        return Promise.all(queries)
            .then(async (responses) => {
                let lastPromise;
                for (let r of responses) {
                    if (!r.data) {
                        throw new Error("Empty response: " + this.resources[resource_name].endpoints.list);
                    }
                    lastPromise = await this.preSaveResource(resource_name, r.data);
                }
                return lastPromise;
            });
    },
    /**
     * Mark a date when data was last
     * syncronized.
     * In unix timestamp microseconds.
     * 
     * @returns {Promise}
     */
    markSync() {
        return ApiStorage.store('last_sync', moment().unix()*1000);
    },
    /**
     * Get a date when data was last
     * syncronized.
     * In unix timestamp microseconds.
     * 
     * @returns {Promise<>}
     */
    lastSync() {
        return ApiStorage.getStored('last_sync');
    },
    /**
     * Login to the API
     * @returns {Promise}
     */
    executeLogin() {
        if(this._api_token) {
            return this._api_token;
        }
        if (!isPlatform('android') && !isPlatform('ios')) {
            return this._browserLogin();
        }
        return this._appLogin();
    },
    /**
     * In browser use the backend to
     * perform the login.
     * 
     * @returns {Promise}
     */
    _browserLogin() {
        return axios.get(BACKEND_LOGIN_URL)
        .then((response) => {
            return this._api_token = response.data.access_token;
        })
        .catch((error) => {
            console.error(error);
            return false;
        });
    },
    /**
     * In app login directly.
     * 
     * @returns {Promise}
     */
    _appLogin() {
        return axios.post(API_LOGIN_URL, {
            grant_type: "client_credentials",
            client_id: API_CLIENT_ID,
            client_secret: API_CLIENT_SECRET,
            scope: API_SCOPES
        })
        .then((response) => {
            return this._api_token = response.data.access_token;
        })
        .catch((error) => {
            console.error(error);
            return false;
        });
    },
    /**
     * Return last saved locale code.
     * 
     * @returns {Promise<String>}
     */
    getLastLocale() {
        return ApiStorage.getStored('_user.selected_locale');
    },
    /**
     * Save current locale.
     * 
     * @param {String} locale 
     * @returns {Promise<String>}
     */
    setLastLocale(locale) {
        return ApiStorage.store('_user.selected_locale', locale);
    },
    /**
     * Get config from the API and save the data
     *
     * @return {Promise}
     */
    fetchConfig() {
        return this._fetch(this.resources.config.endpoints.init)
        .then((response) => {
            return this.setConfig(response.data);
        });
    },
    /**
     * Save the data for config call.
     *
     * @param {Object} data
     * @return {Promise}
     */
    async setConfig(data) {
        for(let key in data) {
            await ApiStorage.store('config.'+key, data[key]);
        }
        return this._getFinishPromise();
    },
    /**
     * Get the list of languages from the API.
     *
     * @return {Promise}
     */
    fetchLanguages() {
        return this._fetch('/languages/list')
        .then((response) => {
            return this.setLanguages(response.data);
        });
    },
    /**
     * Save fetched languages
     *
     * @param {Array} data
     * @return {Promise}
     */
    async setLanguages(data) {
        if (!data) {
            // Probably connection failed.
            return this._getFinishPromise();
        }
        let savedKeys = await ApiStorage.getKeys('languages');
        for(let element of data) {
            if(!savedKeys.includes(element.id)) {
                await ApiStorage.insert('languages', element);
            }
        }
        return this._getFinishPromise();
    },
    /**
     * Get the list of languages from the API.
     *
     * @return {Promise}
     */
    getI18n() {
        return ApiStorage.get('config.i18n');
    },
    /**
     * Fetch a common resource and compare with local data.
     *
     * @param {String} resource_name
     * @return {Promise}
     */
    fetchResource(resource_name, dont_save) {
        return this._fetch(this.resources[resource_name].endpoints.id_list)
        .then((response) => {
            if(dont_save) {
                return response.data;
            }
            return this.compareResource(resource_name, response.data);
        });
    },
    /**
     * Get the list of updated_at values for a resource.
     * It will load the resources which is useful for a preload.
     *
     * @param {String} resource_name
     * @return {Promise}
     */
    getLastUpdatedResource(resource_name) {
        return ApiStorage.getUpdatedAt(resource_name);
    },
    /**
     * Compare lists of IDs and timestamps to see if the data
     * has been updated in the API.
     * 
     * If there are new IDs, it will fetch them.
     * If there are updated IDs, those will be fetched as well.
     * If there are missing IDs, those will be deleted.
     *
     * @param {String} resource_name
     * @param {Object[]} data
     * @return {Promise}
     */
    async compareResource(resource_name, data) {
        if (!data) {
            return false;
        }
        // Retrieve saved data
        let lastUpdate = await this.getLastUpdatedResource(resource_name);
        let savedKeys = Object.keys(lastUpdate);

        // Prepare updated information
        // let currentUpdate = {};
        let currentKeys = [];
        data.forEach(e => {
            // currentUpdate[e.id] = e.updated_at;
            currentKeys.push(e.id);
        });

        // Set up IDs to add, update or remove
        // let newIDs = [];
        let oldIDs = [];
        let deletes = [];
        let newIDs = currentKeys;

        // for(let id of currentKeys) {
        //     if(
        //         !savedKeys.includes(String(id))
        //         || currentUpdate[id] != lastUpdate[id]
        //     ) {
        //         newIDs.push(id);
        //         // Delete from profile_values for refreshing
        //         deletes.push(ApiStorage.delete('profile_values.'+resource_name, id));
        //     }
        // }
        for(let id of savedKeys) {
            if(!currentKeys.includes(Number(id))) {
                oldIDs.push(id);
            }
        }
        if(oldIDs.length > 0) {
            for (let id of oldIDs) {
                // Delete element from storage
                deletes.push(ApiStorage.delete(resource_name, id));
                // Delete from profile_values if any
                deletes.push(ApiStorage.delete('profile_values.'+resource_name, id));
            }
        }
        if (newIDs.length == 0) {
            return this._getFinishPromise();
        }/* else if (newIDs.length > 50) {
            newIDs = null;
        }*/

        // if (newIDs.length > 1000) {
        //     return this._fetchPaginated(resource_name, newIDs);
        // }
        // return Promise.all(deletes).then(() => {
        //     return this._fetch(this.resources[resource_name].endpoints.list, {
        //         ids: newIDs,
        //     })
        //     .then((response) => {
        //         if(!response.data) {
        //             throw new Error("Empty response: " + this.resources[resource_name].endpoints.list);
        //         }
        //         return this.preSaveResource(resource_name, response.data);
        //     });
        // })
        return Promise.all(deletes).then(() => {
            if (newIDs.length > 1000) {
                return this._fetchPaginated(resource_name, newIDs);
            }
            return this._fetch(this.resources[resource_name].endpoints.list, {
                ids: newIDs,
            })
            .then((response) => {
                if(!response.data) {
                    throw new Error("Empty response: " + this.resources[resource_name].endpoints.list);
                }
                return this.preSaveResource(resource_name, response.data);
            });
        });
    },
    /**
     * Prepare the saving of the fetched resources.
     * I hate the loops and promises here but at least
     * it doesn't hang the UI on high data load.
     *
     * @param {String} resource_name
     * @param {Object[]} data
     * @return {Boolean}
     */
    async preSaveResource(resource_name, data) {
        // this._counts.currentListSize += data.length;
        let limit = 100;
        let page = 0;
        await new Promise(resolve => {
            const process = async () => {
                let chunk = data.slice(page * limit, (page + 1) * limit);
                if (chunk.length == 0) {
                    resolve();
                    return;
                }
                for (let element of chunk) {
                    ApiStorage.preInsert(resource_name, resource_name, element);
                    // await new Promise(resolve => {
                    //     ApiStorage.preInsert(resource_name, resource_name, element);
                    //     resolve(true);
                    // });
                    // this._countProgress();
                }
                page++;
                setTimeout(() => process(), 0);
            };
            process();
        });
        return true;
    },
    /**
     * Process all the preInsert calls.
     * Write cache_drive to storage.
     *
     * @return {Boolean} 
     */
    async processUnsaved() {
        const unsaved = ApiStorage.getAllUnsaved();
        for (let batch_name in unsaved) {
            await ApiStorage.insertUnsavedBatch(batch_name);
            this._finishProgressListener();
        }
        // if(!this._isBrowser) {
            ApiStorage.storeCacheDrive();
        // }
        return true;
    },
    /**
     * Special fetch for vector layers as this call
     * differs a bit. Won't compare. Does some manual
     * data management.
     *
     * @return {Promise} 
     */
    async fetchVectorLayerList() {
        const knownLayers = [
            'Point',
            'Cluster',
            'Polyline',
            'StartEndMarkers',
            'Incident',
            // 'Operation',
            'Lattice',
        ];

        // Preload first
        const layers = await ApiStorage.getAll('vector_layer_list');

        // Using points as it is a very basic module
        return this._fetch('/points/vector_layer_list', {
            type: knownLayers,
        })
        .then((response) => {
            // Manual comparison as vector layers have string IDs
            // and no updated_at property.
            // We will compare the whole object.
            let keys = [];
            let toSave = [];
            if (layers.length == 0) {
                toSave = response.data;
                keys = response.data.map(l => l.id);
            } else {
                for(let layer of response.data) {
                    keys.push(layer.id);
                    let found = false;
                    for (let savedLayer of layers) {
                        if (layer.id == savedLayer.id) {
                            found = true;
                            if (!isEqual(layer, savedLayer)) {
                                // Found, but it is different
                                toSave.push(layer);
                            }
                            break;
                        }
                    }
                    if (!found) {
                        // Not found, must be new
                        toSave.push(layer);
                    }
                }
            }

            // Force key cache for performance on first load.
            if (toSave.length > 0) {
                return this.preSaveResource('vector_layer_list', toSave);
            }

            return this._getFinishPromise();
        });
    },
    /**
     * Special fetch for profile values. This call is
     * different and requires a custom comparison
     * process. This call may take longer than others
     * so it is paginated.
     *
     * @param {Object} resource
     * @return {Promise<Boolean>} 
     */
    async fetchProfileValues(resource) {
        // getAll() will preload the values into the _cache_drive
        // as does getUpdatedAt() on resource fetch.
        const keyHolders = await ApiStorage.getAll('profile_values.' + resource.id_source);
        const keys = keyHolders.filter(v => v?true:false).map(v => Number(v.id));
        return ApiStorage.getKeys(resource.id_source).then(ids => {
            // Check only for udpates
            let needsUpdate = [];
            if(ids.length) {
                for(let id of ids) {
                    if(!keys.includes(id)) {
                        needsUpdate.push(id);
                    }
                }
            }

            if (needsUpdate.length == 0) {
                return this._getFinishPromise();
            }

            // return this._fetch('/profile_fields/typeValues', {
            //     element_ids: needsUpdate,
            //     element_type: resource.model,
            // })
            return this._fetchPaginatedProfileFields('/profile_fields/typeValues', {
                element_ids: needsUpdate,
                element_type: resource.model,
            }, resource.id_source);
            // .then(async response => {
            //     let saves = [];
            //     try {
            //         this._counts.currentListSize += Object.keys(response.data.values).length;
            //         for (let id in response.data.values) {
            //             const data = response.data.values[id];
            //             data.id = id; // add ID, it comes as key
            //             saves.push(data);
            //         }
            //     } catch (e) {
            //         // It seems to fail sometimes in some iOS devices for some unknown reason.
            //         // Just do nothing and let the user keep going.
            //         saves = [];
            //     }
            //     return this.preSaveResource('profile_values.' + resource.id_source, saves);
            // });
        });
    },
    /**
     * Paginate the profile fields request.
     *
     * @param {String} endpoint
     * @param {Object} data
     * @param {String} id_source
     * @return {Promise} 
     */
    async _fetchPaginatedProfileFields(endpoint, data, id_source) {
        let page = 0;
        let limit = 3000;
        let pageIds = [];
        let queries = [];
        let saves = [];
        while (page * limit <= data.element_ids.length) {
            pageIds = data.element_ids.slice( (page*limit), (page+1)*limit );
            queries.push(this._fetch(endpoint, {
                ids: pageIds,
                element_type: data.element_type
            }));
            page++;
        }
        for (let query of queries) {
            await query.then(response => {
                try {
                    // this._counts.currentListSize += Object.keys(response.data.values).length;
                    for (let id in response.data.values) {
                        const data = response.data.values[id];
                        data.id = id; // add ID, it comes as key
                        saves.push(data);
                        // response.data.values[id].id = id;
                    }
                } catch (e) {
                    // It seems to fail sometimes in some iOS devices for some unknown reason.
                    // Just do nothing and let the user keep going.
                    // saves = [];
                }
                // return this.preSaveResource('profile_values.' + id_source, saves);
                // return this.preSaveResource('profile_values.' + id_source, Object.values(response.data.values));
            });
        }
        return await this.preSaveResource('profile_values.' + id_source, saves);
        // return true;
    },
    /**
     * Routing call for the nearest point to a given
     * location.
     *
     * @param {Object} data
     * @return {Promise} 
     */
    routingGetNearestPoint(data) {
        return this._fetch(this.resources.lattice_routing.endpoints.get_nearest_point, data);
    },
    /**
     * Main routing call, will create a way along the
     * provided points.
     *
     * @param {Object} data
     * @return {Promise} 
     */
    routingGetRoute(data) {
        return this._fetch(this.resources.lattice_routing.endpoints.get_route, data);
    },
    /**
     * Once a route has been created, fetch all related
     * data.
     *
     * @param {Object} data
     * @return {Promise} 
     */
    routingGetMergeInfo(data) {
        return this._fetch(this.resources.lattice_routing.endpoints.merge_info, data);
    },
    /**
     * Export call with file download.
     *
     * @param {String} resource
     * @param {String} format
     * @param {String} lang
     * @param {Object} resource_params
     * @param {String} filename
     * @return {Promise<Boolean>} 
     */
    async export(resource, format, lang, resource_params, filename) {
        const route = this._makeApiUrl(this.resources[resource].endpoints['export_'+format]);
        const params = Object.assign({ language_code: lang }, resource_params);
        if (!await this.executeLogin()) {
            return false;
        }
        
        if (!isPlatform('android') && !isPlatform('ios')) {
            return axios.post(route, params, {
                    responseType: 'blob', // important
                    headers: {
                        Authorization: 'Bearer ' + this._api_token,
                    }
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    return true;
                }, (error) => {
                    console.error(error);
                    return false;
                });
        } else {
            let backend_route = route;
            backend_route += '&language_code=' + params['language_code'];
            if ('ids' in params) {
                backend_route += '&ids[]=' + params['ids'][0];
            } else if ('points' in params) {
                for (let ptidx in params['points']) {
                    backend_route += '&points[' + ptidx + '][lat]=' + params['points'][ptidx]['lat'];
                    backend_route += '&points[' + ptidx + '][lng]=' + params['points'][ptidx]['lng'];
                }
            }
            Browser.open({ url: backend_route });
        }
    },
    /**
     * Upload incident with image using
     * multipart/form-data.
     *
     * @param {Object} formdata
     * @return {Promise<Object>} 
     */
    async reportIncident(formdata) {
        const route = this._makeApiUrl(this.resources['incidents'].endpoints.upload);
        if (!await this.executeLogin()) {
            return false;
        }
        return axios.post(route, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + this._api_token,
                }
            }).then((response) => {
                if (response.data && response.data.success) {
                    return {
                        status: 'success',
                    }
                }
                return {
                    status: 'error',
                    message: response.data,
                };
            }, (error) => {
                console.error(error);
                if (error.response && error.response.data && error.response.data.errors) {
                    console.error(error.response.data);
                    error = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0];
                }
                return {
                    status: 'error',
                    message: error,
                };
            });
    },
    /**
     * Upload image with image using
     * multipart/form-data.
     *
     * @param {Object} formdata
     * @return {Promise<Object>} 
     */
    async uploadImage(formdata) {
        const route = this._makeApiUrl(this.resources['girona'].endpoints.upload);
        if (!await this.executeLogin()) {
            return false;
        }
        return axios.post(route, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + this._api_token,
                }
            }).then((response) => {
                if (response.data && response.data.success) {
                    return {
                        status: 'success',
                    }
                }
                return {
                    status: 'error',
                    message: response.data,
                };
            }, (error) => {
                console.error(error);
                if (error.response && error.response.data && error.response.data.errors) {
                    console.error(error.response.data);
                    error = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0];
                }
                return {
                    status: 'error',
                    message: error,
                };
            });
    },
    /**
     * Save a created custom route to the database.
     * Return the assigned ID (a date string).
     *
     * @param {String} feature_string
     * @param {Object} data
     * @return {Promise<Object>} 
     */
    async saveRouting(feature_string, data) {
        let offline_id = moment().format();
        const object = {
            id: offline_id,
            feature_string,
            data: Object.assign(data, {offline_id}),
        };
        return ApiStorage.store('_user.routing.' + offline_id, JSON.stringify(object)).then(() => {
            ApiStorage._removeCachedKey('_keys._user.routing'); // Update cached keys
            return { offline_id };
        });
    },
    /**
     * Remove a custom route from the database.
     *
     * @param {String} id
     * @return {Promise} 
     */
    removeRouting(id) {
        return ApiStorage.delete('_user.routing', id);
    },
    /**
     * Get all saved custom routes.
     *
     * @return {Promise<Array>}
     */
    async getSavedRouting() {
        return (await ApiStorage.getAll('_user.routing'))
            .filter(r => !!r)
            .map(r => {
                return JSON.parse(r);
            });
    },
    /**
     * Filter elements remotely.
     *
     * @return {Promise<Array>}
     */
    async filterIDList(resource_name, filterObj) {
        const route = this._makeApiUrl(this.resources[resource_name].endpoints.filter_id_list);
        const params = { filters: filterObj };

        return axios.post(route, params, {
                // 'Content-Type': 'application/json',
                headers: {
                    Authorization: 'Bearer ' + this._api_token,
                }
            })
            .then((response) => {
                if ('exception' in response.data) {
                    return {
                        status: 'error',
                        message: response.message,
                    };
                }
                return {
                    status: 'success',
                    data: response.data
                };
            })
            .catch((error) => {
                return {
                    status: 'error',
                    message: error,
                };
            });
    },
    /**
     * Store a GPS user location in the database.
     * Will only perform the action given some await
     * time.
     * 
     * Will try to upload it too, @see uploadUserLocations()
     *
     * @param {Object} latlng
     */
    async storeUserLocation(latlng) {
        if (!(await this.getTrackingConsent())) {
            // No consent
            return true;
        }

        const now = moment();
        // Register every 2 minutes aprox.
        if (!this._last_location_time
            || now.diff(this._last_location_time, 'minutes') > 1) {
            const data = JSON.stringify({
                datetime: now.format(),
                lat: latlng.lat,
                lng: latlng.lng,
            });
            ApiStorage.store('_user.locations.'+now.format(), data).then(() => {
                ApiStorage._removeCachedKey('_keys._user.locations'); // Update cached keys
            });
            this._last_location_time = now;
            this.uploadUserLocations();
        }
    },
    /**
     * Upload user locations to the API, only
     * once every some time.
     * 
     * If it fails nothing bad happens, locations
     * will still be stored and the action will be
     * retried again in the future.
     * 
     * If it succeeds all the locations will be
     * removed from the database.
     *
     * @return {Promise} 
     */
    async uploadUserLocations() {
        if (!(await this.getTrackingConsent())) {
            // No consent
            return true;
        }

        const now = moment();
        // Upload every 6 minutes aprox
        if (this._last_location_upload_time
            && now.diff(this._last_location_upload_time, 'minutes') < 5) {
            return true;
        } else {
            this._last_location_upload_time = now;
        }
        const device_id = (await Device.getId()).uuid;
        const locations = (await ApiStorage.getAll('_user.locations')).map(loc => {
            return JSON.parse(loc);
        });
        if (!locations || locations.length == 0) {
            return true;
        }
        const route = this._makeApiUrl(this.resources['girona'].endpoints.locations);
        if (!await this.executeLogin()) {
            return;
        }
        return axios.post(route, { device_id, locations }, {
                headers: {
                    Authorization: 'Bearer ' + this._api_token,
                }
            }).then(async (response) => {
                if (response.data && response.data.success) {
                    for (let loc of locations) {
                        // Remove sent locations
                        ApiStorage.delete('_user.locations.'+loc.datetime);
                    }
                    return {
                        status: 'success',
                    }
                }
                return {
                    status: 'error',
                    message: response.data,
                };
            });
    },
    /**
     * Get boolean tracking consent.
     *
     * @return {Promise}
     */
    getTrackingConsent() {
        return ApiStorage.get('config.tracking_consent');
    },
    /**
     * Set boolean tracking consent.
     *
     * @return {Promise}
     */
    setTrackingConsent(value) {
        return ApiStorage.store('config.tracking_consent', value);
    },
    /**
     * Get all from an element list.
     *
     * @return {Promise}
     */
    getAll(table, online, dont_save) {
        if(online) {
            return this.fetchResource(table, dont_save);
        } else {
            return ApiStorage.getAll(table);
        }
    },
}