<template>
    <!-- TODO: Set min-max range -->
    <ion-item text-wrap>
        <ion-modal
            class="calendar-modal"
            :can-dismiss="true"
            :presenting-element="$parent.$refs.ionRouterOutlet"
            :show-backdrop="true"
            trigger="open-modal-min">
            <ion-header>
                <ion-toolbar>
                    <ion-title>{{ $t('map.filters.date.min_date') }}</ion-title>
                </ion-toolbar>
            </ion-header>
            <ion-content class="ion-text-center">
                <ion-datetime
                    style="margin: auto;"
                    presentation="date"
                    :show-clear-button="true"
                    :show-default-buttons="true"
                    v-model="content[0]"
                    @ion-change="handleInput"
                    min="1900"
                    max="2100"
                    ></ion-datetime>
            </ion-content>
        </ion-modal>
        <ion-modal
            class="calendar-modal"
            :can-dismiss="true"
            :presenting-element="$parent.$refs.ionRouterOutlet"
            :show-backdrop="true"
            trigger="open-modal-max">
            <ion-header>
                <ion-toolbar>
                    <ion-title>{{ $t('map.filters.date.max_date') }}</ion-title>
                </ion-toolbar>
            </ion-header>
            <ion-content class="ion-text-center">
                <ion-datetime
                    style="margin: auto;"
                    presentation="date"
                    :show-clear-button="true"
                    :show-default-buttons="true"
                    v-model="content[1]"
                    @ion-change="handleInput"
                    min="1900"
                    max="2100"
                    ></ion-datetime>
            </ion-content>
        </ion-modal>
        <ion-list style="width: 100%;">
            <ion-item text-wrap>
                <ion-label>{{ field.name[$getLocale()] }}</ion-label>
            </ion-item>
            <ion-item text-wrap>
                <ion-row style="width: 100%;">
                    <ion-col size-xs="5">
                        <ion-label position="stacked">{{ $t('map.filters.date.min_date') }}</ion-label>
                        <ion-button id="open-modal-min">{{ human_start || $t('map.filters.date.min_date') }}</ion-button>
                    </ion-col>
                    <ion-col size-xs="5">
                        <ion-label position="stacked">{{ $t('map.filters.date.max_date') }}</ion-label>
                        <ion-button id="open-modal-max">{{ human_end || $t('map.filters.date.max_date') }}</ion-button>
                    </ion-col>
                    <ion-col size-xs="2">
                        <ion-button @click="clear()">
                            <ion-icon slot="icon-only" :icon="icon_close"></ion-icon>
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-item>
        </ion-list>
    </ion-item>
</template>

<script>
// Ionic and other components
import { IonItem, IonCol, IonRow,  IonLabel, IonDatetime, IonButton, IonIcon, IonModal, IonContent, IonList, IonTitle, IonHeader, IonToolbar } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';
import { isEqual } from 'lodash';
import * as moment from 'moment';

export default {
    name: 'FieldFilterDate',
    components: {
        IonItem,
        IonCol,
        IonRow,
        IonDatetime,
        IonLabel,
        IonButton,
        IonIcon,
        IonModal,
        IonContent,
        IonList,
        IonTitle,
        IonHeader,
        IonToolbar,
    },
    props:{
        field: Object,
        value: { // TODO: set min-max or initial value
            type: Array,
            default: () => { return ['', '']; },
        },
        registerFilter: Function,
    },
    data() {
        return {
            content: this.value,
            human_start: null,
            human_end: null,
            icon_close: closeOutline,
        }
    },
    mounted() {
        if(this.registerFilter) this.registerFilter(this);
    },
    methods: {
        handleInput() {
            this.human_start = this.content[0]!='' ? moment(this.content[0]).format('L') : this.human_start;
            this.human_end = this.content[1]!='' ? moment(this.content[1]).format('L') : this.human_end;
            this.$emit('input', this.content);
        },
        getFilterFunction() {
            if(isEqual(this.content, this.getInitialValue())) {
                return { field: this.field.slug, data: {}, run: function() { return true } };
            }
            return {
                data: this.content,
                field: this.field.slug,
                run: function(element) {
                    if(!element || !element.profile_field_values || !element.profile_field_values[this.field]) {
                        return false;
                    }
                    if (moment(element.profile_field_values[this.field]) >= moment(this.data[0])
                        && moment(element.profile_field_values[this.field]) <= moment(this.data[1])) {
                        return true;
                    }
                    return false;
                }
            };
        },
        clear() {
            this.content = this.getInitialValue();
            this.handleInput();
            this.human_start = null;
            this.human_end = null;
        },
        getInitialValue() {
                return ['', ''];
        },
    },
};
</script>

<style>
.calendar-modal {
    margin: auto;
}
</style>
