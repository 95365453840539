<template>
    <span>
        <template v-for="layer in vector_layer_list">
            <v-protobuf
                v-if="active[layer.id]"
                :key="layer.id"
                :ref="setRefLayer"
                :url="layer.url"
                :pane="layer.options.pane"
                :options="layer.options"
                :min-zoom="layer.options.minZoom"
                :max-zoom="layer.options.maxZoom"
                :tile-size="layer.options.tileSize"
                ></v-protobuf>
        </template>
    </span>
</template>

<script>
// Storage
import ApiStorage from "@/api/storage";
import ApiFilesystem from "@/api/filesystem";

export default {
    name: 'ArclineLayer',
    data() {
        return {
            vector_layer_list: [],
            vector_styles: {},
            features: [],
            profile_field_values: {},
            lattice_groups: [],
            layerRefs: [],
        }
    },
    props: {
        active: Object,
        categories_list: {
            type: [Object],
            custom: true,
            default: () => {}
        },
        mapObject: {
            type: [Object],
            custom: true,
            default: () => {}
        },
        filter_functions: {
            type: [Array],
            custom: true,
            default: () => []
        },
    },
    async mounted() {
        this.lattice_groups = await ApiStorage.getAll('lattice_groups');

        const layers = await ApiStorage.getAll('vector_layer_list').then(layers => {
                return layers.filter(l => {
                    return l.type == 'Lattice';
                })
            });
        await this.setVectorLayerList(layers);

        this._loadProfileFieldValues();
    },
    watch: {
        filter_functions: {
            handler: function() {
                this.applyFilters();
            },
        }
    },
    methods: {
        async _loadProfileFieldValues() {
            this.profile_field_values = await ApiStorage.getAll('profile_values.arclines').then(result => {
                let values = {};
                for(let list of result) {
                    values[list.id] = list;
                }
                return values;
            });
        },
        getLayerList() {
            return this.vector_layer_list;
        },
        async setVectorLayerList(list) {
            for(var info of list) {
                this.vector_layer_list.push(await this.makeVectorLayer(info));
            }
            this.$emit('vector_layers_loaded', this.getLayerList());
        },
        async makeVectorLayer(info){
            const default_options = {
                id: info.id,
                rendererFactory: window.L.canvas.tile,
                // attribution: "Attribution",
                // subdomains: info.subdomains,	// 01234 for openmaptiles, abcd for mapbox
                interactive: false,	// Make sure that this VectorGrid fires mouse/pointer events
                maxZoom: 21,
                indexMaxZoom: 5,       // max zoom in the initial tile index
                zIndex: 400,
                pane: 'vector-overlays',
                name: info.name,
                _type: info.type,
                locale: () => {
                    return this.$getLocale()
                },
                updateWhenZooming: false,
                keepBuffer: 10,
            };

            if(!this.$checkConnection() && !this.$isBrowser()) {
                /**
                 * Offline tiles if saved previouly.
                 * @see @/views/OfflineMode.vue
                 */
                const firstTile = await ApiFilesystem.stat('cartography/'+info.id+'/0/0/0.mvt');
                if(firstTile) {
                    info.url = ApiFilesystem.convertFileSrc(firstTile.uri.replace('0/0/0', '{z}/{x}/{y}'));
                }
                info.options.maxZoom = 15;
            // Force HTTPs on app to avoid CORS problems
            } else if(!this.$isBrowser() && !info.url.startsWith('https://')) {
                info.url = 'https:'+info.url;
            }

            let options = Object.assign(default_options, info.options);
            
            options.getFeatureId = () => {
                return info.id;
            };

            options.vectorTileLayerStyles = {
                lattice: this.get_vector_lattice_style(info.id),
            };
            options.filter = (properties) => {
                const filterable = {
                    id: properties.id,
                    categories: JSON.parse(properties.categories),
                    // profile_field_values: this.profile_field_values[properties.id] // remote
                };
                for(let filter of this.filter_functions) {
                    if(!filter.run(filterable)) {
                        return false;
                    }
                }
                return true;
            }

            const layer = {
                id: info.id,
                name: info.name,
                active: info.active,
                type: info.type,
                url: info.url,
                options: options
            };

            return layer;
        },
        get_vector_lattice_style (name) {
            let group = this.lattice_groups.find((g) => { return Object.values(g.slug).includes(name); });
            if(!group && this.lattice_groups.length > 0) {
                group = this.lattice_groups[0];
            } else if(!group) {
                throw new Error('Lattice is not available due to data mismatch.');
            }
            return {
                color: group.style_color,
                width: group.style_width,
                opacity: parseFloat(group.style_opacity),
            };
        },
        applyFilters() {
            if(!this.layerRefs) {
                // Still not initialized
                return;
            }
            for(let layer of this.layerRefs) {
                layer.leafletObject.redraw();
            }
        },
        setRefLayer(el) {
            if(el) {
                this.layerRefs.push(el);
            }
        },
    },
    beforeUpdate() {
        this.layerRefs = [];
    },
}
</script>