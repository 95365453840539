<script>
import GenericList from './GenericList.vue';

export default {
    name: 'PointList',
    extends: GenericList,
    data() {
        return {
            resource: 'points',
            featureType: 'Point',
            model: 'Modules\\Points\\Entities\\Point',
        }
    },
};
</script>
