<script>
import FieldFilterNumber from './FieldFilterNumber.vue';
import { isEqual } from 'lodash';

export default {
    name: 'FieldFilterDuration',
    extends: FieldFilterNumber,
    methods: {
      getFilterFunction() {
        if(isEqual(this.content, this.getInitialValue())) {
            return { field: this.field.slug, data: {}, run: function() { return true } };
        }
        return {
          data: this.content,
          field: this.field.slug,
          run: function(element) {
              if(!element) {
                  return false;
              }
              const val = parseFloat(element.meta_duration_foot);
              if (val > this.data[0]
                  && val < this.data[1]) {
                  return true;
              }
              return false;
          }
        };
      },
    },
};
</script>