<template>
    <ion-modal
        class="tracking-modal"
        :is-open="tracking_modal_open"
        :show-backdrop="true"
        :can-dismiss="!isLoading"
        :backdrop-dismiss="!isLoading"
        :presenting-element="$parent.$refs.ionRouterOutlet"
        @did-dismiss="cancelTracking">
        <ion-header>
            <ion-toolbar>
                <ion-title>{{ $t('map.tracking.prompt_title') }}</ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="cancelTracking" :title="$t('map.tracking.cancel')"><ion-icon  :icon="icon_close"></ion-icon></ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <div v-if="isLoading">
            <ion-label class="ion-text-center spinner-container" text-wrap>
                <ion-spinner name="crescent"></ion-spinner>
            </ion-label>
        </div>
        <div v-else-if="finished">
            <ion-title>{{ $t('map.tracking.thanks.title') }}</ion-title>
            <ion-item>
                {{ $t('map.tracking.thanks.description') }}
            </ion-item>
            <ion-item>
                <ion-button @click="successTracking" color="success">
                    <ion-icon slot="start" :icon="icon_check"></ion-icon>
                    {{ $t('map.tracking.thanks.button') }}
                </ion-button>
            </ion-item>
        </div>
        <div v-else>
            <ion-list>
                <ion-item class="text-wrap" style="max-height: 250px; overflow: auto;">
                    {{ $t('map.tracking.terms.description') }}
                </ion-item>
                <ion-item slot="header" class="text-wrap">
                    {{ $t('map.tracking.terms.label') }}
                    <ion-checkbox
                        color="primary"
                        slot="end"
                        v-model="formdata.terms"
                        @click.stop=""
                        ></ion-checkbox>
                </ion-item>
            </ion-list>

            <div class="ion-text-center">
                <ion-button @click="acceptTracking" color="success">
                    <ion-icon slot="start" :icon="icon_check"></ion-icon>
                    {{ $t('map.tracking.apply') }}
                </ion-button>
            </div>
        </div>
    </ion-modal>
</template>

<script>
// Storage
import ApiClient from "@/api/client";

// Ionic components
import { IonItem, IonButton, IonButtons, IonIcon, IonHeader, IonTitle, IonLabel, IonModal, IonToolbar, IonList, IonCheckbox, IonSpinner } from '@ionic/vue';
import { close, checkmarkOutline } from 'ionicons/icons';

export default {
    name: 'TrackingConsent',
    components: {
        IonItem,
        IonButton,
        IonButtons,
        IonIcon,
        IonHeader,
        IonTitle,
        IonLabel,
        IonModal,
        IonToolbar,
        IonList,
        IonCheckbox,
        IonSpinner,
    },
    data() {
        return {
            isLoading: true,
            formdata: {
                terms: false,
                name: null,
            },
            tracking_modal_open: false,
            finished: false,

            icon_close: close,
            icon_check: checkmarkOutline,
        }
    },
    props: {
        active: Boolean,
        map: Object,
    },
    // watch: {
        // active(val) {
        //     if(val) {
        //         this.openTracking();
        //     } else {
        //         this.cancelTracking();
        //     }
        // }
    // },
    mounted() {
        this.map.on('locateactivate', this._onLocationActive);
    },
    methods: {
        async _onLocationActive() {
            const value = await ApiClient.getTrackingConsent();
            if(!value) {
                this.openTracking();
            }
        },
        openTracking() {
            this.finished = false;
            this.formdata = {
                terms: false,
                name: null,
            };
            this.tracking_modal_open = true;
            this.isLoading = false;
        },
        async acceptTracking() {
            if(!this.formdata.terms) {
                this.successTracking();
                return;
            }
            this.isLoading = true;
            await ApiClient.setTrackingConsent(this.formdata.terms);
            this.isLoading = false;
            this.successTracking();
        },
        cancelTracking() {
            if(this.isLoading) {
                return;
            }
            this.finished = false;
            this.tracking_modal_open = false;
            this.formdata = {
                terms: false,
                name: null,
            };
            this.$emit('onFinish', false);
        },
        finishTracking() {
            this.formdata = {
                terms: false,
                name: null,
            };
            this.finished = true;
        },
        successTracking() {
            this.$nextTick(() => {
                this.tracking_modal_open = false;
                this.$emit('onFinish', true);
            });
        },
    },
}
</script>

<style>
.tracking-modal {
    margin: auto;
}
ion-modal.tracking-modal::part(content) {
    max-width: 400px;
}
ion-modal ion-label, ion-modal ion-title {
    color: black;
}
ion-modal .spinner-container {
    width: 100%;
    display: inline-block;
}
</style>