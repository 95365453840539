<template>
    <ion-item>
        <ion-label position="stacked">{{ field.name[$getLocale()] }}</ion-label>
        <tags-input
            :ref="refisterFieldRef"
            class="native-input sc-ion-input-md tags-input"
            element-id="tags"
            :value="fieldTags"
            @tags-updated="handleInput"
            placeholder=""
            :existing-tags="existing_tags"
            :typeahead="true"
            :add-tags-on-comma="true"
            :add-tags-on-blur="false"
            :only-existing-tags="true"
            :delete-on-backspace="true"></tags-input>
        <ion-input style="display: none;"></ion-input> <!-- Hack for correct style -->
    </ion-item>
</template>

<script>
// Ionic components
import { IonItem, IonInput, IonLabel } from '@ionic/vue';
import { isEqual, isArray, clone, intersection } from 'lodash';

// Tags input
import VoerroTagsInput from '@voerro/vue-tagsinput';
import '@voerro/vue-tagsinput/dist/style.css';

export default {
    name: 'FieldFilterTags',
    components: {
        IonItem,
        IonLabel,
        IonInput,
        'tags-input': VoerroTagsInput,
    },
    props:{
        field: Object,
        value: {
            type: Array,
            default: () => { return [] }
        },
        registerFilter: Function,
        profile_values: {
            type: [Object],
            default: () => {},
        },
    },
    data() {
        return {
            content: this.value ?? [],
            existing_tags: [
                // { key: '', value: '' },
            ],
            fieldRef: null,
            fieldTags: [],
        }
    },
    mounted() {
        if(this.registerFilter) this.registerFilter(this);
        if(this.value.length > 0) {
            this.fieldTags = this.value.filter(t => t!=null).map(tag => { return {key: tag, value: tag}; });
        }
        let existing_tags = [];
        for(let values of Object.values(this.profile_values)) {
            if(!values[this.field.slug]) {
                continue;
            }
            let tags = [];
            try {
                tags = JSON.parse(values[this.field.slug]);
            } catch(e) {
                // ignore exception, value is wrong
            }
            for(let tag of tags) {
                if(!existing_tags.includes(tag) && tag != null) {
                    existing_tags.push(tag);
                }
            }
        }
        this.existing_tags = existing_tags.map(t => { return {key: t, value: t} });
    },
    methods: {
        handleInput() {
            this.content = this.fieldRef.tags.map(tag => tag.value);
            this.$emit('input', this.content);
            this.$emit('update:value', this.content);
        },
        getFilterFunction() {
            if(isEqual(this.content, this.getInitialValue())) {
                return { field: this.field.slug, data: {}, run: function() { return true } };
            }
            return {
                data: this.content,
                field: this.field.slug,
                run: function(element) {
                    if(!element || !element.profile_field_values || !element.profile_field_values[this.field]) {
                        return false;
                    }
                    let values = clone(element.profile_field_values[this.field]);
                    try {
                        if(!isArray(values)) {
                            values = JSON.parse(element.profile_field_values[this.field]);
                        }
                    } catch(e) {
                        // Ignore exception, value is not parseable
                    }
                    if(intersection(values, this.data).length > 0) {
                        return true;
                    }
                    return false;
                }
            };
        },
        clear() {
            this.content = this.getInitialValue();
            this.fieldRef.clearTags();
            this.handleInput();
        },
        getInitialValue() {
            return [];
        },
        refisterFieldRef(el) {
            if(el) {
                this.fieldRef = el;
            }
        },
    },
};
</script>

<style>
.tags-input.tags-input-root {
    padding-left: 0;
}
.tags-input .tags-input-wrapper-default {
    margin-top: 10px;
    padding: 5px;
    background: transparent;
    border: 0;
    border-radius: 0;
    /* border-color: #dbdbdb; */
}
</style>