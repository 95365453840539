<template>
    <div>
        <l-tile-layer
            v-if="tilelayer.type == 'url'"
            :name="getTextInfo(tilelayer.name)"
            :url="tilelayer.url"
            :opacity="parseFloat(tilelayer.opacity)"
            :attribution="attribution"
            :visible="visible"
            :z-index="5"
            :options="{
                minZoom: tilelayer.min_z,
                maxZoom: tilelayer.max_z,
                bounds: tilelayer.extent,
            }"
            layer-type="overlay"
        >
        </l-tile-layer>
        <l-wms-tile-layer
            v-if="tilelayer.type == 'wms' && tilelayer.url != ''"
            :name="getTextInfo(tilelayer.name)"
            :base-url="tilelayer.url"
            :layers="tilelayer.layer_list"
            :transparent="true"
            format="image/png"
            :opacity="parseFloat(tilelayer.opacity)"
            :attribution="attribution"
            :visible="visible"
            :z-index="5"
            :options="{
                minZoom: tilelayer.min_z,
                maxZoom: tilelayer.max_z,
                bounds: tilelayer.extent,
            }"
            layer-type="overlay"
        >
        </l-wms-tile-layer>
    </div>
</template>

<script>
export default {
    name: "GCaminsTileLayer",
    props: {
        tilelayer: {
            type: [Object, Array],
            custom: true,
            default: () => {
                return { type: "url" };
            },
        },
        visible: {
            type: [Boolean],
            custom: true,
            default: () => true,
        },
    },
    data() {
        return {
            // Avoid a bug in this leaflet implementation (when locale changes)
            attribution: this.getTextInfo(this.tilelayer.attribution),
        };
    },
    methods: {
        getTextInfo(info) {
            if (info[this.$getLocale()]) {
                return info[this.$getLocale()];
            }
            return info[Object.keys(info)[0]];
        },
        getAttribution() {
            return this.getTextInfo(this.tilelayer.attribution);
        },
    },
};
</script>