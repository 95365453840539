<template>
    <span>
        <template v-for="layer in vector_layer_list">
            <v-protobuf
                v-if="active[layer.id]"
                :key="layer.id"
                :ref="setRefLayer"
                :url="layer.url"
                :pane="layer.options.pane"
                :options="layer.options"
                :min-zoom="layer.options.minZoom"
                :max-zoom="layer.options.maxZoom"
                :tile-size="layer.options.tileSize"
                ></v-protobuf>
        </template>
    </span>
</template>

<script>
// Storage
import ApiStorage from "@/api/storage";
import ApiFilesystem from "@/api/filesystem";

// import PointLayer from './PointLayer.vue';

export default {
    name: 'IncidentLayer',
    // extends: PointLayer,
    data() {
        return {
            vector_layer_list: [],
            vector_icons: {0: new window.L.Icon({
                iconSize: [25, 25],
                iconUrl: process.env.BASE_URL+'assets/img/icon-fallback.png',
            })},
            features: [],
            profile_field_values: {},
            layerRefs: [],
            incidents_layer_id: null,
            category_icons: {},
        }
    },
    props: {
        active: Object,
        categories_list: {
            type: [Object],
            custom: true,
            default: () => {}
        },
        mapObject: {
            type: [Object],
            custom: true,
            default: () => {}
        },
        filter_functions: {
            type: [Array],
            custom: true,
            default: () => []
        },
    },
    async mounted() {
        this.mapObject.on('layeradd', (e) => {
            if(e.layer instanceof window.L.VectorGrid
                && (
                    e.layer.options.id == this.incidents_layer_id
                    )
                ) {
                e.layer.on('click', this.layerClick);
                e.layer.on('mouseover', this.layerMouseover);
                e.layer.on('mouseout', this.layerMouseout);
            }
        });
        
        const layers = [
            await ApiStorage.get('vector_layer_list.incidents'),
        ];
        await this.setVectorLayerList(layers);
        
        this.features = await ApiStorage.getAll('incidents').then(list => {
            return list.map(element => {
                return {
                    id: element.id,
                    name: element.name,
                }
            });
        });

        await this._loadProfileFieldValues();
        await this.getCategoryIcons();
    },
    watch: {
        filter_functions: {
            handler: function() {
                this.applyFilters();
            },
        }
    },
    methods: {
        async getCategoryIcons() {
            if(this.$checkConnection() || this.$isBrowser()) {
                for(let cat of Object.values(this.categories_list)) {
                    if(!this.category_icons[cat.id]) {
                        this.category_icons[cat.id] = cat.icon_url;
                    }
                }
            } else {
                for(let cat of Object.values(this.categories_list)) {
                    if(!this.category_icons[cat.id]) {
                        this.category_icons[cat.id] = await ApiFilesystem.localCategoryIcon(cat);
                    }
                }
            }
        },
        async _loadProfileFieldValues() {
            this.profile_field_values = await ApiStorage.getAll('profile_values.incidents').then(result => {
                let values = {};
                for(let list of result) {
                    values[list.id] = list;
                }
                return values;
            });
        },
        getLayerList() {
            return this.vector_layer_list;
        },
        async setVectorLayerList(list) {
            for(var info of list) {
                this.vector_layer_list.push(await this.makeVectorLayer(info));
            }
            this.$emit('vector_layers_loaded', this.getLayerList());
        },
        layerClick(e) {
            if(e.target.getDataLayerNames().includes('incidents')) {
                this.$emit('featureClick', {
                    type: 'Incident',
                    id: e.layer.properties.id,
                    name: '',
                    info: e.layer.properties,
                });
            }
        },
        layerMouseover(e) {
            if(!e.target.getDataLayerNames().includes('incidents')) {
                return;
            }
            if(!this.tooltip){
                this.tooltip = new window.L.Tooltip({
                    permanent: false,
                    offset: window.L.point(10,0),
                });
            }

            let ids;
            // if(e.layer.properties.clst_id in this.cluster_style_counts) {
            //     ids = this.cluster_style_counts[e.layer.properties.clst_id].ids;
            // } else if (e.layer.properties.ids) {
            //     ids = JSON.parse(e.layer.properties.ids);
            // } else {
                ids = [e.layer.properties.id];
            // }

            let content = "";
            for(let i in ids) {
                let id = ids[i];
                let feature = this.features.find((i) => i.id == id);
                if(!feature) {
                    continue;
                }
                if(this.$getLocale() in feature.name) {
                    content += feature.name[this.$getLocale()]/* + '<br />'*/;
                }
            }

            this.tooltip
                .setContent(content)
                .setLatLng(e.latlng)
                .addTo(this.mapObject);
            // this.$emit('layer_mouseover', {
            //     event: e,
            //     type: 'Incident',
            //     ids: ids,
            //     properties: e.layer.properties,
            // });
        },
        layerMouseout() {
            if(this.tooltip){
                this.tooltip.removeFrom(this.mapObject)
            }
            // This emit gave some recursion problems
            // TODO: Debug
            // this.$emit('layer_mouseout', {
            //     event: e,
            //     type: 'Point',
            // });
        },
        async makeVectorLayer(info){
            const default_options = {
                id: info.id,
                rendererFactory: window.L.canvas.tile,
                // attribution: "Attribution",
                // subdomains: info.subdomains,	// 01234 for openmaptiles, abcd for mapbox
                interactive: true,	// Make sure that this VectorGrid fires mouse/pointer events
                maxZoom: 21,
                indexMaxZoom: 5,       // max zoom in the initial tile index
                zIndex: 500,
                pane: 'vector-overlays',
                name: info.name,
                _type: info.type,
                locale: () => {
                    return this.$getLocale()
                },
                updateWhenZooming: false,
                keepBuffer: 10,
            };

            if(!this.$checkConnection() && !this.$isBrowser()) {
                /**
                 * Offline tiles if saved previouly.
                 * @see @/views/OfflineMode.vue
                 */
                const firstTile = await ApiFilesystem.stat('cartography/'+info.id+'/0/0/0.mvt');
                if(firstTile) {
                    info.url = ApiFilesystem.convertFileSrc(firstTile.uri.replace('0/0/0', '{z}/{x}/{y}'));
                }
                info.options.maxZoom = 15;
            // Force HTTPs on app to avoid CORS problems
            } else if(!this.$isBrowser() && !info.url.startsWith('https://')) {
                info.url = 'https:'+info.url;
            }

            let options = Object.assign(default_options, info.options);
            
            options.getFeatureId = (f) => {
                return f.properties.id;
            };

            if(info.type == 'Incident') {
                // Save ID for toggling
                this.incidents_layer_id = info.id;

                options.vectorTileLayerStyles = {
                    incidents: this.vector_incidents_style
                };
                options.filter = (properties) => {
                    const filterable = {
                        categories: [ properties.category_id ],
                        profile_field_values: this.profile_field_values[properties.id] // TODO: Obtain values
                    };
                    for(let filter of this.filter_functions) {
                        if(!filter.run(filterable)) {
                            return false;
                        }
                    }
                    return true;
                }
            }

            const layer = {
                id: info.id,
                name: info.name,
                active: info.active,
                type: info.type,
                url: info.url,
                options: options
            };

            return layer;
        },
        vector_incidents_style (properties) {
            let icon;
            if(properties.category_id) {
                if (!this.vector_icons[ properties.category_id ]
                    && this.category_icons[ properties.category_id ]) {
                    let size = [25, 25];
                    this.vector_icons[ properties.category_id ] = new window.L.Icon({
                        iconUrl: this.category_icons[ properties.category_id ],
                        iconSize: size
                    });
                }
                icon = this.vector_icons[ properties.category_id ];
            } else {
                icon = this.vector_icons[ 0 ];
            }

            return {icon: icon};
        },
        applyFilters() {
            if(!this.layerRefs) {
                // Still not initialized
                return;
            }
            for(let layer of this.layerRefs) {
                layer.leafletObject.redraw();
            }
        },
        setRefLayer(el) {
            if(el) {
                this.layerRefs.push(el);
            }
        },
    },
    beforeUpdate() {
        this.layerRefs = [];
    },
}
</script>