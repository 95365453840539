<template>
    <ion-content :class="{ loader_content: true, fadeout: !isLoading }" :fullscreen="false" :scroll-y="false">
        <ion-item v-if="error" color="danger">
            <ion-icon slot="end" :icon="alertCircle"></ion-icon>
            <ion-label text-wrap>
                {{ error }}
            </ion-label>
        </ion-item>
        <ion-item v-else>
            <ion-label class="ion-text-center" text-wrap>
                <ion-spinner name="crescent"></ion-spinner>
            </ion-label>
        </ion-item>
    </ion-content>
</template>

<script>
import { IonContent, IonItem, IonSpinner, IonLabel, IonIcon } from '@ionic/vue';
import { alertCircle } from 'ionicons/icons';
export default {
    name: "LoadingScreen",
    data() {
        return {
            alertCircle,
        }
    },
    props: {
        isLoading: Boolean,
        percent: Number,
        error: String,
    },
    components: {
        IonItem,
        IonContent,
        IonSpinner,
        IonLabel,
        IonIcon,
    },
};
</script>

<style>
.loader_content {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
}

.fadeout {
  animation: fadeout .5s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
