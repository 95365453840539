<template>
  <ion-page>
    <ion-header>
        <ion-progress-bar v-if="isLoading" :color="loading_error ? 'danger' : 'primary'" :value="loading_percent" :buffer="buffer_percent"></ion-progress-bar>
    </ion-header>
    <ion-content :fullscreen="true" :scroll-y="false">
        <!-- <LoadingScreen :isLoading="isLoading" :error="loading_error" /> -->
        <DataLoadingScreen :isLoading="isLoadingPartial" :updating="updating" :error="loading_error" :percent="buffer_percent * 100" />
        <div class="map_page_container" v-if="!isLoadingPartial">
            <keep-alive>
                <gcamins-map
                    :bounds="workbounds"
                    :sidebar_tab="selected_sidebar_tab"
                    :feature_param="selected_feature"
                    :map_status="map_status"
                    :ref="setMapRef"
                    :key="'map'"></gcamins-map>
            </keep-alive>
        </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonProgressBar, IonHeader } from '@ionic/vue';
import GcaminsMap from "@/components/GcaminsMap.vue";
// import LoadingScreen from "@/components/LoadingScreen.vue";
import DataLoadingScreen from "@/components/DataLoadingScreen.vue";
import ApiStorage from "@/api/storage";
import { decompressFromEncodedURIComponent } from 'lz-string'

/**
 * Map page will stay hidden until all the API
 * data has been loaded, at least partially.
 * 
 * App state is to be monitored to know when
 * the data is ready.
 */
export default  {
    name: 'EmbedPage',
    components: {
        IonContent,
        IonPage,
        GcaminsMap,
        // LoadingScreen,
        DataLoadingScreen,
        IonProgressBar,
        IonHeader,
    },
    props: {
        sidebar_tab: {
            type: String,
            required: false
        },
    },
    data() {
        return {
            isLoading: true,
            isLoadingPartial: true,
            loading_error: null,
            loading_percent: 0,
            buffer_percent: 0,
            updating: null,
            workbounds: null,
            selected_feature: null,
            map_status: null,
            selected_sidebar_tab: null,
            map: null,
        }
    },
    watch: {
        /**
         * The selected_feature URL parameter has to be
         * processed and sent to the map.
         */
        $route(newRoute) {
            if(newRoute.name != 'embed') {
                return;
            }
            if('map_status' in newRoute.query && newRoute.query.map_status) {
                this.map_status = JSON.parse(decompressFromEncodedURIComponent(decodeURIComponent(newRoute.query.map_status)));
            } else {
                this.map_status = null;
            }
            if('selected_feature' in newRoute.query && newRoute.query.selected_feature) {
                this.selected_feature = JSON.parse(decompressFromEncodedURIComponent(decodeURIComponent(newRoute.query.selected_feature)));
            } else {
                this.selected_feature = null;
                if(newRoute.hash != '#'+this.selected_sidebar_tab) {
                    this.selected_sidebar_tab = newRoute.hash.replace('#', '');
                }
            }
        },
    },
    async mounted() {
        this.isLoading = true;
        if(!this.workbounds) {
            this.workbounds = await ApiStorage.get('config.workbounds');
        }

        if('map_status' in this.$route.query && this.$route.query.map_status) {
            this.map_status = JSON.parse(decompressFromEncodedURIComponent(decodeURIComponent(this.$route.query.map_status)));
        } else {
            this.map_status = null;
        }
        if('selected_feature' in this.$route.query && this.$route.query.selected_feature) {
            this.selected_feature = JSON.parse(decompressFromEncodedURIComponent(decodeURIComponent(this.$route.query.selected_feature)));
        } else {
            this.selected_feature = null;
        }

        if(this.sidebar_tab) {
            this.selected_sidebar_tab = this.sidebar_tab.replace('#', '');
        }

        this.isLoading = this.$getState('isLoading') === false ? false : true;
        this.isLoadingPartial = this.$getState('isLoadingPartial') === false ? false : true;
        this.loading_error = this.$getState('loading_error') || null;
        this.loading_percent = this.$getState('loading_percent') || null;
        this.buffer_percent = this.$getState('buffer_percent') || null;
        this.updating = this.$getState('updating') || null;

        this.emitter.on('app-state-updated', async (newState) => {
            if(!this.workbounds) {
                this.workbounds = await ApiStorage.get('config.workbounds');
            }
            this.isLoading = Object.prototype.hasOwnProperty.call(newState, 'isLoading') ? newState.isLoading : this.isLoading;
            this.isLoadingPartial = Object.prototype.hasOwnProperty.call(newState, 'isLoadingPartial') ? newState.isLoadingPartial : this.isLoadingPartial;
            this.loading_error = Object.prototype.hasOwnProperty.call(newState, 'loading_error') ? newState.loading_error : this.loading_error;
            this.loading_percent = Object.prototype.hasOwnProperty.call(newState, 'loading_percent') ? (newState.loading_percent / 100) : this.loading_percent;
            this.buffer_percent = Object.prototype.hasOwnProperty.call(newState, 'buffer_percent') ? (newState.buffer_percent / 100) : this.buffer_percent;
            this.updating = Object.prototype.hasOwnProperty.call(newState, 'updating') ? newState.updating : this.updating;
        });
    },
    methods: {
        setMapRef(el) {
            if(el) {
                this.map = el;
                this.forceMapResize(); // This worked
            }
        },
        forceMapResize() {
            if(this.map) {
                this.$nextTick(() => {
                    this.map.triggerResize();
                });
            }
        }
    }
}
</script>