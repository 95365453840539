<template>
    <div v-if="isLoading" class="embed-container">
        <LoadingScreen :isLoading="isLoading" />
    </div>
    <div v-else class="embed-container">
        <ion-list>
            <ion-list-header>{{ $t('map.embed.header') }}</ion-list-header>
            <ion-item>
                <ion-button
                    color="secondary"
                    slot="start"
                    :title="$t('map.embed.copy')"
                    @click="applyCopyCode">
                    <ion-icon slot="icon-only" :icon="icon_copy"></ion-icon>
                </ion-button>
                <ion-input
                    v-model="code"
                    readonly="true"></ion-input>
            </ion-item>
            <ion-item>
                <ion-checkbox
                    color="secondary"
                    slot="start"
                    v-model="options.selection"
                    @ion-change="makeUrl()"
                    ></ion-checkbox>
                    {{ $t('map.embed.options.selection') }}
            </ion-item>
            <ion-item>
                <ion-checkbox
                    color="secondary"
                    slot="start"
                    v-model="options.layers"
                    @ion-change="makeUrl()"
                    ></ion-checkbox>
                    {{ $t('map.embed.options.layers') }}
            </ion-item>
            <ion-item>
                <ion-checkbox
                    color="secondary"
                    slot="start"
                    v-model="options.url_only"
                    @ion-change="makeUrl()"
                    ></ion-checkbox>
                    {{ $t('map.embed.options.url_only') }}
            </ion-item>
        </ion-list>
    </div>
</template>

<script>
import { IonButton, IonIcon, IonItem, IonList, IonListHeader, IonInput, IonCheckbox } from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { Clipboard } from '@capacitor/clipboard';
import { Toast } from '@capacitor/toast';

// Other components
import LoadingScreen from '@/components/LoadingScreen.vue'
import { compressToEncodedURIComponent } from 'lz-string'

export default {
    name: 'IncidentsList',
    components: {
        LoadingScreen,
        IonButton,
        IonIcon,
        IonItem,
        IonList,
        IonListHeader,
        IonInput,
        IonCheckbox,
    },
    props: {
        leafletObject: Object,
        selected_feature: Object,
        current_tab: String,
        selected_baselayer: Number,
        selected_tilelayers: Object,
        selected_vectorlayers: Object,
    },
    data() {
        return {
            base_url: process.env.BASE_URL,
            url: '',
            code: '',
            isLoading: true,
            options: {
                selection: true,
                layers: true,
                url_only: false,
            },

            icon_copy: copyOutline,
        }
    },
    watch: {
        current_tab() {
            this.makeUrl();
        },
        selected_feature() {
            this.makeUrl();
        }
    },
    async mounted() {
        this.makeUrl();
        this.isLoading = false;
    },
    methods: {
        makeUrl() {
            const feature_string = this.getFeatureString();
            const map_status_string = this.getMapStatusString();
            let url = window.location.origin+'/embed?';
            if(this.options.selection) {
                url += 'selected_feature='+feature_string;
            }
            url += '&map_status='+map_status_string;
            this.url = url;
            if(this.options.url_only) {
                this.code = this.url;
            } else {
                this.code = '<iframe width="100%" height="400px" src="'+url+'"></iframe>';
            }
        },
        applyCopyCode() {
            this.makeUrl();
            Clipboard.write({
                string: this.code
            }).then(() => {
                Toast.show({
                    text: this.$t('general.share.copied'),
                });
            });
        },
        getFeatureString() {
            let obj = Object.assign({}, this.selected_feature);
            delete obj.info;
            return encodeURIComponent(compressToEncodedURIComponent(JSON.stringify(obj)));
        },
        getMapStatusString() {
            if(!this.leafletObject) {
                return '';
            }
            let obj = {
                zoom: this.leafletObject.getZoom(),
                center: this.leafletObject.getCenter(),
            };
            if(this.options.layers) {
                obj.selected_baselayer = this.selected_baselayer;
                obj.selected_tilelayers = this.selected_tilelayers;
                obj.selected_vectorlayers = this.selected_vectorlayers;
            }
            return encodeURIComponent(compressToEncodedURIComponent(JSON.stringify(obj)));
        }
    }
};
</script>

<style>
.embed-container {
    flex: 99;
}
</style>