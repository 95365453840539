<template>
    <ion-item>
        <ion-label position="floating">{{ field.name[$getLocale()] }}</ion-label>
        <ion-input
            :clear-input="true"
            v-model="content"
            @ion-input="handleInput"></ion-input>
    </ion-item>
</template>

<script>
// Ionic components
import { IonItem, IonInput, IonLabel } from '@ionic/vue';
import { isEqual } from 'lodash';

export default {
    name: 'FieldFilterString',
    components: {
        IonItem,
        IonInput,
        IonLabel,
    },
    props:{
        field: Object,
        value: {
            type: String,
            default: () => { return '' }
        },
        registerFilter: Function,
    },
    mounted() {
        if(this.registerFilter) this.registerFilter(this);
    },
    data() {
        return {
          content: this.value ?? '',
        }
    },
    methods: {
        handleInput() {
            this.$emit('input', this.content);
        },
        getFilterFunction() {
            if(isEqual(this.content, this.getInitialValue())) {
                return { field: this.field.slug, data: {}, run: function() { return true } };
            }
            return {
                data: this.content,
                field: this.field.slug,
                run: function(element) {
                    if(!element || !element.profile_field_values || !element.profile_field_values[this.field]) {
                        return false;
                    }
                    if(element.profile_field_values[this.field].indexOf(this.data) > -1) {
                        return true;
                    }
                    return false;
                }
            };
        },
        clear() {
            this.content = this.getInitialValue();
            this.handleInput();
        },
        getInitialValue() {
            return '';
        },
    },
};
</script>