<template>
    <div>
        <template v-for="cat in categories" :key="cat.id">
            <ion-chip>
                <ion-avatar class="ion-avatar-category-icon">
                    <category-img :category="cat"></category-img>
                </ion-avatar>
                <ion-label>{{ getItemName(cat.name) }}</ion-label>
            </ion-chip>
        </template>
    </div>
</template>

<script>
// Ionic components
import { IonChip, IonAvatar, IonLabel } from '@ionic/vue';
// import ApiFilesystem from '@/api/filesystem';
import GCaminsCategoryImg from '@/components/GCaminsCategoryImg.vue';

export default {
    name: 'GCaminsCategoriesShow',
    components: {
        IonChip,
        IonAvatar,
        IonLabel,
        'category-img': GCaminsCategoryImg,
    },
    data() {
        return {
        }
    },
    props: {
        categories: {
            type: [Array],
            custom: true,
            default: () => [],
        },
    },
    methods: {
        // async getCategoryIcon(category) {
        //     return this.$checkConnection() ? category.icon_url : await ApiFilesystem.localCategoryIcon(category);
        // },
        getItemName(names) {
            if(!names[this.$getLocale()]) {
                return names[Object.keys(names)[0]];
            }
            return names[this.$getLocale()];
        },
    }
}
</script>