<template>
    <span>
        <template v-for="layer in vector_layer_list">
            <v-protobuf
                v-if="active[layer.id]"
                :key="layer.id"
                :ref="setRefLayer"
                :url="layer.url"
                :pane="layer.options.pane"
                :options="layer.options"
                :min-zoom="layer.options.minZoom"
                :max-zoom="layer.options.maxZoom"
                :tile-size="layer.options.tileSize"
                ></v-protobuf>
        </template>
    </span>
</template>

<script>
// Storage
import ApiStorage from "@/api/storage";
import ApiFilesystem from "@/api/filesystem";

export default {
    name: 'PolylineLayer',
    data() {
        return {
            vector_layer_list: [],
            vector_styles: {},
            features: [],
            profile_field_values: {},
            durations: {},
            layerRefs: [],
        }
    },
    props: {
        active: Object,
        categories_list: {
            type: [Object],
            custom: true,
            default: () => {}
        },
        mapObject: {
            type: [Object],
            custom: true,
            default: () => {}
        },
        filter_functions: {
            type: [Array],
            custom: true,
            default: () => []
        },
    },
    async mounted() {
        this.mapObject.on('layeradd', (e) => {
            if(e.layer instanceof window.L.VectorGrid
                && (
                    e.layer.options.id == 'polylines' ||
                    e.layer.options.id == 'start-end-markers'
                    )
                ) {
                e.layer.on('click', this.layerClick);
                e.layer.on('mouseover', this.layerMouseover);
                e.layer.on('mouseout', this.layerMouseout);
            }
        });
        
        const layers = [
            await ApiStorage.get('vector_layer_list.polylines'),
            await ApiStorage.get('vector_layer_list.start-end-markers'),
        ];
        await this.setVectorLayerList(layers);
        
        this.features = await ApiStorage.getAll('polylines').then(list => {
            return list.map(element => {
                return {
                    id: element.id,
                    name: element.name,
                    meta_duration_foot: element.meta_duration_foot,
                }
            });
        });

        this._loadDurations();
        this._loadProfileFieldValues();
    },
    watch: {
        filter_functions: {
            handler: function() {
                this.applyFilters();
            },
        }
    },
    methods: {
        _loadDurations() {
            for(let feature of this.features) {
                this.durations[feature.id] = feature.meta_duration_foot;
            }
        },
        async _loadProfileFieldValues() {
            this.profile_field_values = await ApiStorage.getAll('profile_values.polylines').then(result => {
                let values = {};
                for(let list of result) {
                    values[list.id] = list;
                }
                return values;
            });
        },
        getLayerList() {
            return this.vector_layer_list;
        },
        async setVectorLayerList(list) {
            for(var info of list) {
                this.vector_layer_list.push(await this.makeVectorLayer(info));
            }
            this.$emit('vector_layers_loaded', this.getLayerList());
        },
        layerClick(e) {
            if (e.target.getDataLayerNames().includes('polylines') ||
                e.target.getDataLayerNames().includes('start_end_markers')) {
                this.$emit('featureClick', {
                    type: 'Polyline',
                    id: e.layer.properties.id,
                    name: '',
                    info: e.layer.properties,
                });
            }
        },
        layerMouseover(e) {
            if(!e.target.getDataLayerNames().includes('polylines')
                && !e.target.getDataLayerNames().includes('start_end_markers')) {
                return;
            }
            if(!this.tooltip){
                this.tooltip = new window.L.Tooltip({
                    permanent: false,
                    offset: window.L.point(10,0),
                });
            }

            let feature = this.features.find((i) => i.id == e.layer.properties.id);
            if(!feature) {
                return;
            }
            let content = '';
            if(this.$getLocale() in feature.name) {
                content += feature.name[this.$getLocale()] + '<br />';
            } else {
                content += feature.name[Object.keys(feature.name)[0]] + '<br />';
            }

            this.tooltip
                .setContent(content)
                .setLatLng(e.latlng)
                .addTo(this.mapObject);
            // This emit gave some recursion problems
            // TODO: Debug
            // this.$emit('layer_mouseover', {
            //     event: e,
            //     type: 'Polyline',
            //     ids: [e.layer.properties.id],
            //     properties: e.layer.properties,
            // });
        },
        layerMouseout(/*e*/) {
            if(this.tooltip){
                this.tooltip.removeFrom(this.mapObject)
            }
            // this.$emit('layer_mouseout', {
            //     event: e,
            //     type: 'Polyline',
            // });
        },
        async makeVectorLayer(info){
            const default_options = {
                id: info.id,
                // rendererFactory: window.L.canvas.tile,
                rendererFactory: function (tileCoord, tileSize, opts) {
                    opts.tolerance = 5;
                    return window.L.canvas.tile(tileCoord, tileSize, opts);
                },
                // attribution: "Attribution",
                // subdomains: info.subdomains,	// 01234 for openmaptiles, abcd for mapbox
                interactive: true,	// Make sure that this VectorGrid fires mouse/pointer events
                maxZoom: 21,
                indexMaxZoom: 5,       // max zoom in the initial tile index
                zIndex: 450,
                pane: 'vector-overlays',
                name: info.name,
                _type: info.type,
                locale: () => {
                    return this.$getLocale()
                },
                updateWhenZooming: false,
                keepBuffer: 10,
            };
            
            if(!this.$checkConnection() && !this.$isBrowser()) {
                /**
                 * Offline tiles if saved previouly.
                 * @see @/views/OfflineMode.vue
                 */
                const firstTile = await ApiFilesystem.stat('cartography/'+info.id+'/0/0/0.mvt');
                if(firstTile) {
                    info.url = ApiFilesystem.convertFileSrc(firstTile.uri.replace('0/0/0', '{z}/{x}/{y}'));
                }
                info.options.maxZoom = 15;
            // Force HTTPs on app to avoid CORS problems
            } else if(!this.$isBrowser() && !info.url.startsWith('https://')) {
                info.url = 'https:'+info.url;
            }

            let options = Object.assign(default_options, info.options);
            
            options.getFeatureId = (f) => {
                return f.properties.id;
            };
            if (info.type == 'Polyline' || info.type == 'StartEndMarkers') {
                options.vectorTileLayerStyles = {
                    polylines: this.vector_polylines_style,
                    start_end_markers: this.vector_start_end_markers_style
                };
                options.filter = (properties) => {
                    const filterable = {
                        categories: JSON.parse(properties.categories),
                        profile_field_values: this.profile_field_values[properties.id],
                        meta_duration_foot: this.durations[properties.id],
                    };
                    for(let filter of this.filter_functions) {
                        if(!filter.run(filterable)) {
                            return false;
                        }
                    }
                    return true;
                }
            }

            const layer = {
                id: info.id,
                name: info.name,
                // active: info.active,
                active: info.type == 'Polyline' ? true : false,
                type: info.type,
                url: info.url,
                options: options
            };

            return layer;
        },
        vector_polylines_style (properties) {
            if (!(properties.style in this.vector_styles)) {
                this.vector_styles[ properties.id ] = JSON.parse(properties.style);
            }
            return this.vector_styles[ properties.id ];
        },
        vector_start_end_markers_style (properties) {
            if(!this.start_icon) {
                this.start_icon = new window.L.Icon({
                    iconUrl: process.env.BASE_URL+'assets/modules/polylines/images/flag-start.png',
                    iconSize: [19, 25],
                    iconAnchor: [0, 25]

                });
                this.end_icon = new window.L.Icon({
                    iconUrl: process.env.BASE_URL+'assets/modules/polylines/images/flag-end.png',
                    iconSize: [19, 25],
                    iconAnchor: [0, 25]
                });
            }

            let start = JSON.parse(properties.style).start;

            return {icon: start ? this.start_icon : this.end_icon};
        },
        applyFilters() {
            if(!this.layerRefs) {
                // Still not initialized
                return;
            }
            for(let layer of this.layerRefs) {
                layer.leafletObject.redraw();
            }
        },
        setRefLayer(el) {
            if(el) {
                this.layerRefs.push(el);
            }
        },
    },
    beforeUpdate() {
        this.layerRefs = [];
    },
}
</script>