<template>
    <div class="layer-select-container">
        <!-- keep alive is important to avoid losing the filters selection -->
        <keep-alive>
            <ion-list :key="'layers-select-list-0'">
                <ion-radio-group :value="selected_baselayer" @ion-change="setBaseLayer">
                    <ion-list-header>
                        <ion-label>{{ $t('map.sidebar.layers.baselayers') }}</ion-label>
                    </ion-list-header>

                    <template v-for="(tileProvider, index) in baseLayers"  v-bind:key="tileProvider.id">
                        <ion-item>
                            <ion-label>{{ tileProvider.name }}</ion-label>
                            <ion-radio
                            color="secondary"
                            slot="end"
                            :value="index"></ion-radio>
                        </ion-item>
                    </template>
                </ion-radio-group>
            </ion-list>
        </keep-alive>

        <keep-alive>
            <ion-list v-if="tilelayers.length > 0" :key="'layers-select-list-1'">
                <ion-list-header>
                    <ion-label>{{ $t('map.sidebar.layers.tilelayers') }}</ion-label>
                </ion-list-header>
                <template v-for="layer in tilelayers"  v-bind:key="layer.id">
                    <ion-item slot="header">
                        <ion-label>{{ getItemName(layer.name) }}</ion-label>
                        <ion-checkbox
                            color="secondary"
                            slot="end"
                            :checked="selected_tilelayers[layer.id]"
                            :id="layer.id"
                            @ion-change="tilelayersSelected(layer.id, $event.target.checked)"
                            ></ion-checkbox>
                    </ion-item>
                </template>
            </ion-list>
        </keep-alive>

        <keep-alive>
            <ion-list v-if="vector_layers.length > 0" :key="'layers-select-list-2'">
                <ion-list-header>
                    <ion-label>{{ $t('map.sidebar.layers.vectorlayers') }}</ion-label>
                </ion-list-header>
                <template v-for="layer in vector_layers"  v-bind:key="layer.id">
                    <ion-item slot="header">
                        <ion-label>{{ layer.name }}</ion-label>
                        <ion-checkbox
                            color="secondary"
                            slot="end"
                            :checked="selected_vectorlayers[layer.id]"
                            :id="layer.id"
                            @ion-change="vectorlayersSelected(layer.id, $event.target.checked)"
                            ></ion-checkbox>
                    </ion-item>
                </template>
            </ion-list>
        </keep-alive>
    </div>
</template>

<script>
import { IonList, IonItem, IonRadioGroup, IonRadio, IonCheckbox, IonListHeader, IonLabel } from '@ionic/vue';

export default {
    name: 'LayersSelect',
    components: {
        IonList,
        IonItem,
        IonRadioGroup,
        IonRadio,
        IonCheckbox,
        IonListHeader,
        IonLabel,
    },
    props: {
        selected_baselayer: Number,
        leafletObject: Object,
        baseLayers: Array,
        tilelayers: Array,
        vector_layers: Array,
        selected_tilelayers: Object,
        selected_vectorlayers: Object,
    },
    methods: {
        getItemName(names) {
            if (names[this.$getLocale()]) {
                return names[this.$getLocale()];
            }
            return names[Object.keys(names)[0]];
        },
        setBaseLayer(event) {
            this.$emit('base_layer', event.detail.value);
        },
        tilelayersSelected(id, checked) {
            this.$emit('tilelayer', id, checked);
        },
        vectorlayersSelected(id, checked) {
            this.$emit('vectorlayer', id, checked);
        },
    }
};
</script>

<style>
.layer-select-container {
    flex: 99;
}
</style>